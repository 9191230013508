import React, { useState, useEffect } from "react";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebarlink";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import validator from "validator";

function Home() {
  useEffect(() => {
    getTfastatus();
  }, [])
  const navigate = useNavigate()
  const initialFormValue = {
    oldpassword: "",
    password: "",
    confirmPassword: "",
  };

  const [formValue, setFormValue] = useState(initialFormValue);
  const [tfaUrl, settfaUrl] = useState("")
  const [userMail, setuserMail] = useState("")
  const [tfaCode, settfaCode] = useState("")
  const [tfaStatus, settfaStatus] = useState("")
  const [ValidationErrors, setValidationErrors] = useState({})
  const [oldpassvalidate, setoldpassvalidate] = useState(false);
  const [passwordValidate, setpasswordValidate] = useState(false);
  const [confirmPasswordValidate, setconfirmPasswordValidate] = useState(false);
  const [passconfNotmatch, setpassconfNotmatch] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [passhide1, setpasshide1] = useState(false);
  const [inputtype1, setinputtype1] = useState("password");
  const [passhide2, setpasshide2] = useState(false);
  const [inputtype2, setinputtype2] = useState("password");
  const [passhide3, setpasshide3] = useState(false);
  const [inputtype3, setinputtype3] = useState("password");


  const { oldpassword, password, confirmPassword } = formValue;

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    // validate(formData);
  };
  const validate = async (values) => {
    const errors = {};

    if (!values.oldpassword) {
      errors.oldpassword = "Old Password is a required field";
      setoldpassvalidate(true);
    } else if (
      validator.isStrongPassword(formValue.oldpassword, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setoldpassvalidate(false);
    } else {
      errors.oldpassword = "Enter strong    Old Password";
      setoldpassvalidate(true);
    }

    if (!values.password) {
      errors.password = "Password is a required field";
      setpasswordValidate(true);
    } else if (
      validator.isStrongPassword(formValue.password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setpasswordValidate(false);
    } else {
      errors.password = "Enter strong     New password";
      setpasswordValidate(true);
    }

    if (values.password == values.oldpassword) {
      errors.password = "Already you have this password";
      setpasswordValidate(true);
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = "Confirm password is a required field";
      setconfirmPasswordValidate(true);
    }

    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Password and confirm password does not match";
      setpassconfNotmatch(true);
    } else {
      setpassconfNotmatch(false);
    }

    setvalidationnErr(errors);
    return errors;
  };

  const formSubmit = async (payload) => {
    validate(formValue);
    if (
      formValue.oldpassword != "" &&formValue.oldpassword != formValue.password&&
      formValue.password != "" &&formValue.password ==  formValue.confirmPassword &&
      formValue.confirmPassword != "" &&
      passconfNotmatch == false
    ) {
      var obj = {
        oldPass: formValue.oldpassword,
        password: formValue.password,
        cpass: formValue.confirmPassword,
      };

      var data = {
        apiUrl: apiService.changePassword,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      console.log(resp.email);
      localStorage.setItem("useremail", resp.email);
      setbuttonLoader(false);
      if (resp.status) {
        toast.success(resp.Message);
        localStorage.clear();
        navigate("/");
        window.location.reload(true);
      } else {
        toast.error(resp.Message);
      }
    } else {
      console.log("all field requird");
    }
  };

  const oldPassword = (data1) => {
    if (data1 == "hide") {
      setpasshide1(true)
      setinputtype1("text");
    } else {
      setpasshide1(false)
      setinputtype1("password");
    }
  }

  const newPassword = (data2) => {
    if (data2 == "hide") {
      setpasshide2(true)
      setinputtype2("text");
    } else {
      setpasshide2(false)
      setinputtype2("password");
    }
  }

  const conPassword = (data3) => {
    if (data3 == "hide") {
      setpasshide3(true)
      setinputtype3("text");
    } else {
      setpasshide3(false)
      setinputtype3("password");
    }
  }

  const getTfastatus = async () => {
    var data = {
      apiUrl: apiService.getTfaDetials,
    };

    var resp = await getMethod(data);
    console.log(resp, "=-=-=-resp")
    settfaUrl(resp.data.tfa_url)
    setuserMail(resp.data.email)
    settfaStatus(resp.data.tfastatus)
    localStorage.setItem("tfa_status", resp.data.tfastatus);
  }

  const handleSubmit = async () => {
    setValidationErrors({});
    if (!tfaCode || tfaCode === "") {
      setValidationErrors({
        tfaCode: "2FA code is required",
      });
    } else {
      var data = {
        apiUrl: apiService.changeTfaStatus,
        payload: {
          userToken: tfaCode,
          tfaStatus: tfaStatus,
        },
      };
      var resp = await postMethod(data);
      toast.success(resp.Message);
      settfaCode("");
      getTfastatus();
    }
  }
  return (
    <div className="header ">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <Sidebar />
          </div>
          <div className="css-1h690ep">
            <div className="section_bg_ash">
              <div className="section_selec_ash">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12">
                      <h2 className="heading_titlr">Security</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="inner_conteten">
                <div className="order_tabs">
                  <ul class="nav nav-tabs">
                    <li class="active">
                      <a data-toggle="tab" href="#home" className="active">
                        2FA
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#menu1">
                        Change Password
                      </a>
                    </li>
                  </ul>
                  <div class="tab-content ">
                    <div id="home" class="tab-pane fade in active show">
                      <div className="contant_secion_balance mt-4">
                        <h3 className="heading_sub_section">
                          2FA <span className="inner-red">Disabled</span>
                        </h3>

                        <p className="mt-0">
                          If you want to turn off 2FA, input your account
                          password and the six-digit code provided by the Google
                          <br />
                          Authenticator app below, then click "Disable 2FA".
                          <br />
                        </p>
                      </div>
                      <div className="contant_secion_balance mt-4">
                        <h3 className="heading_sub_section">Enable 2FA</h3>

                        <p className="mt-0">
                          Enter the six-digit code provided by the Google
                          Authenticator app to Disable the 2FA verification
                        </p>
                      </div>
                      <div className="contant_secion_balance mt-4">
                        <h3 className="heading_sub_section">
                          Registered Email
                        </h3>
                        <div className="email_2fa">
                          <i class="ri-mail-fill"></i>{userMail}
                        </div>
                        <div className="row">
                          <div className="col-lg-4">
                            <h3 className="input_labele">2FA Code</h3>
                            <input
                              type="number"
                              placeholder="Enter 2FA Code"
                              className="pan_number"
                              value={tfaCode}
                              onChange={(e) => settfaCode(e.target.value)}
                            />
                            {ValidationErrors.tfaCode && (
                              <p className="text-danger">
                                {" "}
                                {ValidationErrors.tfaCode}{" "}
                              </p>
                            )}
                            {tfaStatus == 0 ?
                              <div className="qr_code">
                                <img src={tfaUrl} />
                              </div>
                              : <div className="qr_code">
                              </div>}
                            <div className="market_tabel d-flex justify-content-center mt-0">
                              <Button className="m-0"
                                onClick={handleSubmit}>
                                {tfaStatus === 0 ?
                                  "Enable 2FA" : "Disable 2FA"}{" "}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="menu1" class="tab-pane fade">
                      <div className="contant_secion_balance mt-4">
                        <div className="row justify-content-center mt-3">
                          <div className="col-lg-4">
                            <div className="row ">
                              <div className="col-lg-12">
                                <h3 className="input_labele">Old password</h3>
                                <div className="with-eye">
                                  <input
                                    type={inputtype1}
                                    name="oldpassword"
                                    required=""
                                    className="pan_number"
                                    id="exampleInputPassword1"
                                    placeholder="Old password"
                                    value={oldpassword}
                                    onChange={handleChange}
                                  />
                                  <span>
                                    {passhide1 == false ?
                                      <i
                                        className="bi bi-eye-slash-fill"
                                        onClick={() => oldPassword("hide")}
                                      ></i>
                                      :
                                      <i
                                        className="bi bi-eye-fill"
                                        onClick={() => oldPassword("show")}
                                      ></i>
                                    }
                                  </span>
                                </div>
                                <div>
                                  {oldpassvalidate == true ? (
                                    <p className="text-danger">
                                      {" "}
                                      {validationnErr.oldpassword}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <h3 className="input_labele">New password</h3>
                                <div className="with-eye">
                                  <input
                                    type={inputtype2}
                                    name="password"
                                    required=""
                                    id="exampleInputPassword1"
                                    placeholder="New password"
                                    className="pan_number"
                                    value={password}
                                    onChange={handleChange}
                                  />
                                  <span>
                                    {passhide2 == false ?
                                      <i
                                        className="bi bi-eye-slash-fill"
                                        onClick={() => newPassword("hide")}
                                      ></i>
                                      :
                                      <i
                                        className="bi bi-eye-fill"
                                        onClick={() => newPassword("show")}
                                      ></i>
                                    }
                                  </span>
                                </div>
                                <div>
                                  {passwordValidate == true ? (
                                    <p className="text-danger">
                                      {" "}
                                      {validationnErr.password}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <h3 className="input_labele">
                                  Confirm password
                                </h3>
                                <div className="with-eye">
                                  <input
                                    type={inputtype3}
                                    required=""
                                    id="exampleInputPassword1"
                                    placeholder="confirm password"
                                    className="pan_number"
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    onChange={handleChange}
                                  />
                                  <span>
                                    {passhide3 == false ?
                                      <i
                                        className="bi bi-eye-slash-fill"
                                        onClick={() => conPassword("hide")}
                                      ></i>
                                      :
                                      <i
                                        className="bi bi-eye-fill"
                                        onClick={() => conPassword("show")}
                                      ></i>
                                    }
                                  </span>
                                </div>
                                <div>
                                  {confirmPasswordValidate == true ? (
                                    <p className="text-danger">
                                      {" "}
                                      {
                                        validationnErr.confirmPassword
                                      }{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-12">
                                {buttonLoader == false ?
                                  <Button className="changepassword" onClick={formSubmit}>
                                    Change Password
                                  </Button>
                                  :
                                  <Button className="changepassword" >
                                    Loading...
                                  </Button>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
