import React, {useEffect} from "react";
import useState from "react-usestateref";
import Header from "./Header";
import {Button} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Footer from "./Footer";
import apiService from "../core/service/detail";
import {postMethod, getMethod} from "../core/service/common.api";
// import Bgvedio from "../Image/globe-fiat-block.mp4";
import BannerUI from "./Banner";
import {Link, useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import {toast} from "react-toastify";

function Home() {
  const navigate = useNavigate();
  const [loginCheck, setloginCheck] = useState(false);
  const [CurrencyDetails, setCurrencyDetails] = useState([]);
  const [currencylistData, setcurrencylistData] = useState([]);
  const [metatitle, setmetatitle, metatitleref] = useState(
    "Adverb - International Exchange & Trading"
  );
  const [metadesc, setmetadesc, metadescref] = useState(
    "Swiftly facilitating global trade through secure and efficient international exchange and trading solutions Adverb Trading."
  );
  const initialFormValue = {
    email: "",
  };
  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [emailValidate, setemailValidate, emailValidateref] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);

  const {email} = formValue;

  const handleChange = async (e) => {
    e.preventDefault();
    const {name, value} = e.target;
    let formData = {...formValue, ...{[name]: value}};
    setFormValue(formData);
    validate(formData);
  };

  const validate = async (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Please enter email";
      setemailValidate(true);
      //toast.error("Please enter email");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
      setemailValidate(true);
      //toast.error("Invalid email address");
    } else {
      setemailValidate(false);
    }

    setvalidationnErr(errors);
    return errors;
  };

  const notify_submit = async () => {
    // return
    validate(formValue);
    if (formValue.email != "") {
      if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formValue.email)) {
        var data = {
          apiUrl: apiService.subscriber,
          payload: formValue,
        };
        setbuttonLoader(true);
        var resp = await postMethod(data);
        setbuttonLoader(false);
        if (resp.status) {
          toast.success(resp.Message);
          formValue.email = "";
        } else {
          toast.error(resp.Message);
        }
      } else {
        toast.error("Please enter valid email");
      }
    } else {
      toast.error("Please enter email");
    }
  };

  useEffect(() => {
    let userToken = localStorage.getItem("user_token");
    if (userToken) {
      setloginCheck(true);
    } else {
      setloginCheck(false);
    }
    getStatkingDetails(0);
    gethomeCurrency();
  }, [0]);

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  var settings_2 = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const redirect_url = async (url) => {
    console.log("redirect url===", url);
    navigate(url);
  };

  const redirect_stake = async () => {
    if (loginCheck == false) {
      navigate("/login");
    } else {
      navigate("/staking");
    }
  };

  const getStatkingDetails = async () => {
    try {
      var data = {
        apiUrl: apiService.get_Staking_Details,
      };
      var resp = await getMethod(data);
      setCurrencyDetails(resp.Message);
    } catch {}
  };

  const gethomeCurrency = async () => {
    var data = {
      apiUrl: apiService.homeCurrency,
    };
    var resp = await getMethod(data);
    if (resp.status) {
      setcurrencylistData(resp.Message);
    } else {
    }
  };

  return (
    <>
      <Helmet>
        <title>{metatitleref.current}</title>
        <meta name="title" content={metatitleref.current} />
        <meta name="description" content={metadescref.current} />
      </Helmet>
      <div>
        <Header />
        <main>
          <section className="section_one">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 d-flex">
                  <div className="contsnt">
                    <h1>
                      Buy, trade, and hold 350+ <br />
                      cryptocurrencies on Adverb
                    </h1>
                    <Link to="" className="text-decoreatioen">
                      <p>
                        <img
                          src={require("../Image/gift.png")}
                          className="img-fluid"
                        />{" "}
                        Trade Bitcoin for free{" "}
                        <i class="bi bi-chevron-right arrow-class"></i>
                      </p>
                    </Link>

                    <div className="button_section_width">
                      <Button
                        className="primery_color"
                        onClick={() => redirect_url("/trade/ADVB_USDT")}
                      >
                        Get Start Now
                      </Button>
                      {loginCheck == false ? (
                        <div className="row">
                          <div className="col-lg-6">
                            <Button
                              className="primery_color secodery-color"
                              onClick={() => redirect_url("/login")}
                            >
                              Login
                            </Button>
                          </div>
                          <div className="col-lg-6">
                            <Button
                              className="primery_color secodery-color"
                              onClick={() => redirect_url("/register")}
                            >
                              Register
                            </Button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 d-flex align-items-center">
                  <div className="banner_img contsnt header-image">
                    <img
                      src={require("../Image/bitcoin-p2p/cuate.png")}
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="content_botton_banner">
                    <h2>$38 billion</h2>
                    <p>24h trading volume on Adverb exchange</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="content_botton_banner">
                    <h2>350+</h2>
                    <p>Cryptocurrencies listed</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="content_botton_banner">
                    <h2>120 million</h2>
                    <p>Registered users</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="content_botton_banner">
                    <h2>&lt;0.10%</h2>
                    <p>Lowest transaction fees</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="two_section">
            <div className="inner">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <Slider {...settings_2}>
                      <div className="scroll_image">
                        <img
                          src={require("../Image/banner_scroll.png")}
                          className="img-fluid"
                        />
                      </div>
                      <div className="scroll_image">
                        <img
                          src={require("../Image/banner_scroll1.jpeg")}
                          className="img-fluid"
                        />
                      </div>
                      <div className="scroll_image">
                        <img
                          src={require("../Image/banner_scroll.png")}
                          className="img-fluid"
                        />
                      </div>
                      <div className="scroll_image">
                        <img
                          src={require("../Image/banner_scroll1.jpeg")}
                          className="img-fluid"
                        />
                      </div>
                      <div className="scroll_image">
                        <img
                          src={require("../Image/banner_scroll.png")}
                          className="img-fluid"
                        />
                      </div>
                      <div className="scroll_image">
                        <img
                          src={require("../Image/banner_scroll1.jpeg")}
                          className="img-fluid"
                        />
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section_commen">
            <div className="container">
              <div className="row">
                <div className="col-lg-7">
                  <h2 className="heading_h2">Popular cryptocurrencies</h2>
                </div>
                <div className="col-lg-5 d-flex justify-content-end align-items-center">
                  <Link to="" className="text-decoreatioen text-right">
                    <p className="hrf_text">
                      Trade Bitcoin for free <i class="bi bi-chevron-right"></i>
                    </p>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <div className="market_tabel w-100">
                    <div className="market_section">
                      <div className="table-responsive mt-5">
                        <table class="table">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Last Price</th>
                              <th>24h Change</th>
                              <th className="text-right">24h Volume</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currencylistData &&
                              currencylistData.map((obj, i) => {
                                return (
                                  <tr
                                    onClick={() =>
                                      redirect_url(
                                        "/trade/" + obj.currencySymbol + "_USDT"
                                      )
                                    }
                                    className="cursor_pointer"
                                  >
                                    <td>
                                      <div className="coin_section">
                                        <img
                                          src={obj.Currency_image}
                                          className="blackshow"
                                        />
                                        <span>{obj.currencyName}</span>{" "}
                                        <small>{obj.currencySymbol}</small>
                                      </div>
                                    </td>
                                    <td>
                                      <p>${obj.estimatedValueInUSDT}</p>
                                    </td>
                                    {obj.coin_change < 0 ? (
                                      <td>
                                        <p className="text-red">
                                          {obj.coin_change}%
                                        </p>
                                      </td>
                                    ) : (
                                      <td>
                                        <p className="green_text">
                                          +{obj.coin_change}%
                                        </p>
                                      </td>
                                    )}

                                    <td>
                                      <p className="text-right">
                                        {obj.coin_volume}
                                      </p>
                                    </td>
                                  </tr>
                                );
                              })}
                            {/* <tr>
                            <td>
                              <div className="coin_section">
                                <img
                                  src={require("../Image/ETH.png")}
                                  className="blackshow"
                                />
                                <span>Ethereum</span> <small>ETH</small>
                              </div>
                            </td>
                            <td>
                              <p>$43,975.72</p>
                            </td>
                            <td>
                              <p className="green_text">+0.60%</p>
                            </td>
                            <td>
                              <p className="text-right">$43,318.64</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="coin_section">
                                <img
                                  src={require("../Image/AVE.png")}
                                  className="blackshow"
                                />
                                <span>Avalanche</span> <small>AVE</small>
                              </div>
                            </td>
                            <td>
                              <p>$43,975.72</p>
                            </td>
                            <td>
                              <p className="green_text">+0.60%</p>
                            </td>
                            <td>
                              <p className="text-right">$43,318.64</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="coin_section">
                                <img
                                  src={require("../Image/BTC.png")}
                                  className="blackshow"
                                />
                                <span>Bitcoin</span> <small>BTC</small>
                              </div>
                            </td>
                            <td>
                              <p>$43,975.72</p>
                            </td>
                            <td>
                              <p className="green_text">+0.60%</p>
                            </td>
                            <td>
                              <p className="text-right">$43,318.64</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="coin_section">
                                <img
                                  src={require("../Image/LITE.png")}
                                  className="blackshow"
                                />
                                <span>Litecoin</span> <small>LTC</small>
                              </div>
                            </td>
                            <td>
                              <p>$43,975.72</p>
                            </td>
                            <td>
                              <p className="green_text">+0.60%</p>
                            </td>
                            <td>
                              <p className="text-right">$43,318.64</p>
                            </td>
                          </tr> */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                {loginCheck == false ? (
                  <div className="col-lg-12">
                    <div className="tabel_bottm_">
                      <h3>Sign up now to build your own portfolio for free!</h3>
                      <Button onClick={() => redirect_url("/register")}>
                        Get Started
                      </Button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </section>
          <section className="section_commen">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <h2 className="heading_h2">Build your crypto portfolio</h2>
                  <p className="heading_p">
                    Start your first trade with these easy steps.
                  </p>
                  <div className="options">
                    <div className="row ">
                      <div className="col-lg-2 col-md-2 image-section">
                        <img src={require("../Image/Frame.png")} className="" />
                      </div>
                      <div className="col-lg-10">
                        <div className="heading_sub">
                          <h3>Verify your identity</h3>
                          <p>
                            Complete the identity verification process to secure
                            your account and transactions.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-lg-2 col-md-2 kyc-image">
                        <img src={require("../Image/kyc.png")} className="" />
                      </div>
                      <div className="col-lg-10">
                        <div className="heading_sub">
                          <h3>Fund your account</h3>
                          <p>
                            Add funds to your crypto account to start trading
                            crypto. You can add funds with a variety of payment
                            methods.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-lg-2 col-md-2 start-image">
                        <img
                          src={require("../Image/starttrade.png")}
                          className=""
                        />
                      </div>
                      <div className="col-lg-10">
                        <div className="heading_sub">
                          <h3 className="function-start">Start trading</h3>
                          <p>
                            You're good to go! Buy/sell crypto, set up recurring
                            buys for your investments, and discover what Adverb
                            has to offer.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 d-flex align-items-center">
                  <div>
                    <div className="lapimage">
                      <img
                        src={require("../Image/bitcoin-p2p/mockuppc.png")}
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section_commen">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h2 className="heading_h2">
                    Earn daily rewards on your idle tokens
                  </h2>
                  <p className="heading_p">
                    Simple & Secure. Search popular coins and start earning.
                  </p>
                </div>
                {CurrencyDetails &&
                  CurrencyDetails.map((item, i) => {
                    return (
                      <div
                        className="col-lg-3"
                        onClick={() => redirect_stake()}
                      >
                        <div className="opton_price">
                          <small>APR</small>
                          <h3>
                            {item.APRinterest == null ? "0" : item.APRinterest}{" "}
                            %{" "}
                          </h3>
                          <div className="card_footer">
                            <div className="image_dd">
                              <img
                                src={item.currencyImage}
                                className="blackshow"
                              />{" "}
                              {item.currencySymbol}
                            </div>
                            {loginCheck == false ? (
                              <Link to="/login" className="arrow_fun">
                                <i class="bi bi-chevron-right"></i>
                              </Link>
                            ) : (
                              <Link to="/staking" className="arrow_fun">
                                <i class="bi bi-chevron-right"></i>
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}

                <div className="col-lg-12">
                  <div className="tabel_bottm_">
                    {loginCheck == false ? (
                      <Link to="/login">
                        <Button>Start to Earn</Button>
                      </Link>
                    ) : (
                      <Link to="/staking">
                        <Button>Start to Earn</Button>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section_commen">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <h2 className="heading_h2">
                    Trade on the go. Anywhere, anytime.
                  </h2>
                  <p className="heading_p">
                    Stay in the know with our app and desktop client.
                  </p>
                </div>
                <div className="col-lg-4 d-flex justify-content-end align-items-center">
                  <Link to="" className="text-decoreatioen text-right">
                    <p className="hrf_text">
                      More download options <i class="bi bi-chevron-right"></i>
                    </p>
                  </Link>
                </div>
                <div className="col-lg-6 mt-4">
                  <img
                    src={require("../Image/bitcoin-p2p/androind.png")}
                    className="phone_imge image_section_app"
                  />
                </div>
                <div className="col-lg-6 mt-4 d-flex align-items-center">
                  <div className="app_download_szecio w-100">
                    {/* <div className="card_qr">
                    <div className="row">
                      <div className="col-lg-3">
                        <img
                          src={require("../Image/Vector.png")}
                          className=""
                        />
                      </div>
                      <div className="col-lg-9">
                        <div className="contene">
                          <span>Scan to Download</span>
                          <p>IOS & Android</p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                    {/* <div className="app_secion_">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="contant_app_sec">
                          <img
                            src={require("../Image/Icon-window.png")}
                            className=""
                          />
                          <h4>Windows</h4>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="contant_app_sec">
                          <img
                            src={require("../Image/Icon-mac.png")}
                            className=""
                          />
                          <h4>Mac OS</h4>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="contant_app_sec">
                          <img
                            src={require("../Image/icon-gplay.png")}
                            className="widyth_50 clod_icon"
                          />
                          <h4>Google Play</h4>
                        </div>
                      </div>
                    </div>
                  </div> */}
                    <div className="commingsoon_new">
                      {/* <p>coming soon</p> */}
                      <small>coming soon</small>
                      <h3>
                        Get Notified <br />
                        When We Launch
                      </h3>
                      <div className="supscription">
                        <input
                          type="email"
                          placeholder="Enter your Email"
                          name="email"
                          value={email}
                          onChange={handleChange}
                        />
                        {/* <div>
      {emailValidate == true ? (
        <p className="text-danger">
          {" "}
          {validationnErr.email}{" "}
        </p>
      ) : (
        ""
      )}
    </div> */}
                        {buttonLoader == false ? (
                          <Button onClick={notify_submit}>Notify Me</Button>
                        ) : (
                          <Button>Loading...</Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section_commen">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h2 className="heading_h2 need-text">Need help?</h2>
                </div>
                <div className="col-lg-4">
                  <div className="options">
                    <div className="row ">
                      <div className="col-lg-2 col-md-2 support-services">
                        <img
                          src={require("../Image/support.png")}
                          className=""
                        />
                      </div>
                      <div className="col-lg-10">
                        <div className="heading_sub">
                          <h3>24/7 Chat Support</h3>
                          <p>
                            Get 24/7 chat support with our friendly customer
                            service agents at your service.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="options">
                    <div className="row ">
                      <div className="col-lg-2 col-md-2 chat-services">
                        <img src={require("../Image/chat.png")} className="" />
                      </div>
                      <div className="col-lg-10">
                        <div className="heading_sub subclass">
                          <h3>FAQs</h3>
                          <p>
                            View FAQs for detailed instructions on specific
                            features.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="options">
                    <div className="row ">
                      <div className="col-lg-2 col-md-2 blog-section">
                        <img src={require("../Image/blog.png")} className="" />
                      </div>
                      <div className="col-lg-10">
                        <div className="heading_sub headingclass">
                          <h3>Blog</h3>

                          <p>
                            Stay up to date with the latest stories and
                            commentary.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section_commen swcirrrr">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="contant_last">
                    <h2>Start earning today</h2>
                    {loginCheck == false ? (
                      <Button
                        className="file_submint"
                        onClick={() => redirect_url("/register")}
                      >
                        Sign Up Now
                      </Button>
                    ) : (
                      <Button
                        className="file_submint"
                        onClick={() => redirect_url("/staking")}
                      >
                        Stake Now
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <div></div>
        <Footer />
      </div>
    </>
  );
}

export default Home;
