import React, {useEffect} from "react";
import useState from "react-usestateref";
import Header from "./Header";
import {Button} from "@mui/material";
import Footer from "./Footer";
import {socket} from "../core/context/socket";
import isEmpty from "../core/lib/isEmpty";
import {env} from "../core/service/envconfig";
import {widget} from "../core/lib/chart/charting_library/charting_library.min";
import apiService from "../core/service/detail";
import {getMethod, postMethod} from "../core/service/common.api";
import {checkAuth, getAuthToken} from "../core/lib/localStorage";
import {Link, useNavigate, useLocation} from "react-router-dom";
import {toast} from "react-toastify";
import Moment from "moment";
import Pagination from "react-js-pagination";
import {Helmet} from "react-helmet";

function Home() {
  const [isToggled, setIsToggled] = useState(false);
  const [loaderSite, setloaderSite] = useState(false);
  const [theme, setTheme] = useState("Dark");
  const [pair, setPair] = useState("ADVB_USDT");
  const [pairlist, setpairlist] = useState([]);
  const [searchInputlist, setsearchInputlist, searchInputlistref] = useState(
    []
  );
  const [fromCurrency, setFromCurrenncy] = useState("");
  const [toCurrency, setToCurrenncy] = useState("");
  const [checkAuth, setcheckAuth] = useState(false);
  const [chartPair, setchartPair] = useState("ADVB_USDT");
  const [currentPair, setcurrentPair] = useState("");
  const navigate = useNavigate();
  const [pairDetails, setpairDetails] = useState("");
  const [marketTrade, setmarketTrade] = useState([]);
  const [orderbookask, setorderbookask] = useState([]);
  const [orderbookbid, setorderbookbid] = useState([]);
  const [pairTickerDetails, setpairTickerDetails] = useState("");
  const [currencyImage, setcurrencyImage] = useState("");

  const [marketPrice, setmarketPrice, marketPriceref] = useState(0);
  const [currentType, setcurrentType] = useState("buy");
  const [
    curnt_Ordertype_tab,
    setcurnt_Ordertype_tab,
    curnt_Ordertype_tabref,
  ] = useState("Limit");
  const initialFormValue = {
    price: "",
    amount: "",
    total: "",
    stop_price: "",
    sellprice: "",
    sellamount: "",
    selltotal: "",
    sellstop_price: "",
  };
  const [formValue, setFormValue] = useState(initialFormValue);
  const {
    price,
    amount,
    total,
    stop_price,
    sellprice,
    sellamount,
    selltotal,
    sellstop_price,
  } = formValue;
  const [pairLoader, setPairLoader] = useState(false);
  const [frombalance, setFromBalance] = useState(0);
  const [tobalance, settobalance] = useState(0);
  const [perpage, setperpage] = useState(5);
  const [activeOrderDatas, setActiveOders] = useState([]);
  const [totalactive, settotalactive] = useState(0);
  const [tradeHistoryData, settradeHistory] = useState([]);
  const [totalHist, settotalHist] = useState(0);
  const [totalcan, settotalcan] = useState(0);
  const [cancelOrders, setcancelOrders] = useState([]);
  const [allpairslist, setallpairslist, allpairslistref] = useState([]);
  const [searchpair, setsearchpair] = useState(null);
  const [orderloader, setorderloader, orderloaderref] = useState(false);
  const [sellorderloader, setsellorderloader, sellorderloaderref] = useState(
    false
  );

  const [currentPage, setCurrentPage] = useState(1);

  const [currentPagecan, setCurrentPagecan] = useState(1);
  const [currentPageHis, setcurrentPageHis] = useState(1);

  const recordPerPage = 5;
  const pageRange = 5;
  const recordPerPagecan = 5;
  const pageRangecan = 5;

  const recordPerPageHist = 5;
  const pageRangeHis = 5;
  const [btntrade1, setbtntrade1] = useState("btntrade1");
  const [btntrade2, setbtntrade2] = useState("btntrade2");
  const [btntrade3, setbtntrade3] = useState("btntrade3");
  const [btntrade4, setbtntrade4] = useState("btntrade4");

  const [sellbtntrade1, setsellbtntrade1] = useState("sellbtntrade1");
  const [sellbtntrade2, setsellbtntrade2] = useState("sellbtntrade2");
  const [sellbtntrade3, setsellbtntrade3] = useState("sellbtntrade3");
  const [sellbtntrade4, setsellbtntrade4] = useState("sellbtntrade4");

  const [buyPrice, setBuyPrice] = useState(0);
  const [buyAmount, setBuyAmount] = useState(0);
  const [buyTotal, setBuyTotal] = useState(0);
  const [sellPrice, setSellPrice] = useState(0);
  const [sellAmount, setSellAmount] = useState(0);
  const [sellTotal, setSellTotal] = useState(0);
  const [selectorder, setSelectOrder] = useState(false);

  const [usdtpairlist, setusdtpairlist, usdtpairlistref] = useState([]);
  const [gainpairlist, setgainpairlist, gainpairlistref] = useState([]);
  const [losepairlist, setlosepairlist, losepairlistref] = useState([]);
  const [hotpairlist, sethotpairlist, hotpairlistref] = useState([]);
  const [metadesc, setmetadesc, metadescref] = useState("");
  const [metatitle, setmetatitle, metatitleref] = useState("");
  const [loader, Setloader, loaderref] = useState(false);

  function toggleClass(id) {
    const element = document.getElementById(id);
    element.classList.toggle("active");
    setIsToggled(!isToggled);
  }
  function removeClass(id) {
    const element = document.getElementById(id);
    element.classList.remove("active");
    setIsToggled(false);
  }

  useEffect(async () => {
    // setloaderSite(true)
    async function check() {
      let mecheck = await getAuthToken();
      // let tokenVerify = await localStorage.getItem("user_token");
      var callapi = false;
      if (mecheck != "" && mecheck != undefined && mecheck != null) {
        await setcheckAuth(true);
        callapi = true;
      } else {
        await setcheckAuth(false);
        callapi = false;
      }
      var urls = await window.location.href;
      var fetchPair = await urls.split("trade/")[1];
      console.log("fetchPair===", fetchPair);
      // socket.connect();
      await setchartPair(fetchPair);
      await setPair(fetchPair);
      buildchart("White", fetchPair);

      if (fetchPair) {
        await setcurrentPair(fetchPair);
        var fromcurr = fetchPair.split("_")[0];
        var toCurr = fetchPair.split("_")[1];
        setFromCurrenncy(fromcurr);
        setToCurrenncy(toCurr);
        await getCurrpairDatas(fetchPair);
        await getMarketTrades(fetchPair);
        await selectPair_start(fetchPair);
        await fetchTickerPrice(fetchPair);
        if (callapi == true) {
          await getUserbalance(fetchPair);
          await getActiveOrders(1, fetchPair);
          await tradeHistory(1);
          getCancelOrders(1);
        } else {
        }
      } else {
        navigate("/");
      }
    }
    await check();
    await socketstart();
    //setloaderSite(false)
    
  }, [socket]);
  const activePageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // call API to get data based on pageNumber
    getActiveOrders(pageNumber, "");
  };

  const cancelPageChange = (pageNumber) => {
    setCurrentPagecan(pageNumber); // call API to get data based on pageNumber
    getCancelOrders(pageNumber);
  };

  const orderhistoryactive = (pageNumber) => {
    setcurrentPageHis(pageNumber); // call API to get data based on pageNumber
    tradeHistory(pageNumber);
  };
  const socketstart = async () => {
    selectPairbyCurrency("USDT");
    PairsbyCurrency("USDT");

    // await socket.emit("GetTickerPrice", "getall");

    await socket.off("OrderBook");
    await socket.off("TickerPrice");
    await socket.off("TradeHistory");
    await socket.on("OrderBook", async (response) => {
      var data = await response.data;
      if (data != null && data != undefined && data != "") {
        if (data.symbol) {
          setorderbookask([]);
          setorderbookbid([]);
          var orderbookbid = [];
          var orderbookask = [];
          orderbookbid = data["bids"].length == 0 ? [] : data["bids"];
          orderbookask = data["asks"].length == 0 ? [] : data["asks"];
          var askcumtotal = 0;
          for (let index = 0; index < orderbookask.length; index++) {
            var element = orderbookask[index];
            var multiply = element[0] * element[1];
            askcumtotal = parseFloat(askcumtotal) + parseFloat(multiply);
            orderbookask[index]["percent"] = (multiply / askcumtotal) * 100;
          }
          var bidcumtotal = 0;
          for (let index = 0; index < orderbookbid.length; index++) {
            var element = orderbookbid[index];
            var multiply = element[0] * element[1];
            bidcumtotal = parseFloat(bidcumtotal) + parseFloat(multiply);
            orderbookbid[index]["percent"] = (multiply / bidcumtotal) * 100;
          }
          // setorderbookLoader(false);
          await setorderbookask(
            orderbookask.sort(function (a, b) {
              return b[0] - a[0];
            })
          );
          // setorderbookLoaderbit(false);
          await setorderbookbid(
            orderbookbid.sort(function (a, b) {
              return b[0] - a[0];
            })
          );
        }
      }
    });
    socket.on("TradeHistory", async (response) => {
      var tickerdetail = response.data;
      if (tickerdetail !== null) {
        setmarketTrade(tickerdetail);
      }
    });
    // socket.on("TickerPrice", async (response) => {
    //   if (response.data) {
    //     var tickerdetail = response.data;
    //     setpairTickerDetails(tickerdetail);
    //     setmarketPrice(
    //       tickerdetail.lastprice.lastprice
    //         ? tickerdetail.lastprice.lastprice
    //         : 0.0
    //     );
    //     if (curnt_Ordertype_tab == "Stop") {
    //       formValue.price = "";
    //       formValue.stop_price = +marketPrice ? +marketPrice : 0.0;
    //       //formValue.stop_price = "";
    //       formValue.sellprice = "";
    //       formValue.sellstop_price = +marketPrice ? +marketPrice : 0.0;
    //     } else {
    //       formValue.price = +marketPrice ? +marketPrice : 0.0;

    //       formValue.sellprice = +marketPrice ? +marketPrice : 0.0;
    //     }
    //   }
    // });
    socket.on("TickerPrice", async (response) => {
      if (response.data) {
        var tickerdetail = response.data;
        //console.log("tickerdetail====",tickerdetail);
        setpairTickerDetails(tickerdetail);
        setmarketPrice(
          tickerdetail.lastprice.lastprice
            ? tickerdetail.lastprice.lastprice
            : 0.0
        );
        if (curnt_Ordertype_tabref.current == "Stop") {
          if (formValue.price <= 0) {
            formValue.price = "";
            formValue.sellprice = "";
            formValue.stop_price = +marketPriceref.current
              ? +marketPriceref.current
              : 0.0;
          }
          //formValue.stop_price = "";
        } else if (curnt_Ordertype_tabref.current == "Market") {
          formValue.price = +marketPriceref.current
            ? +marketPriceref.current
            : 0.0;
          formValue.sellprice = +marketPriceref.current
            ? +marketPriceref.current
            : 0.0;
        } else {
          formValue.price = +marketPriceref.current
            ? +marketPriceref.current
            : 0.0;
          formValue.sellprice = +marketPriceref.current
            ? +marketPriceref.current
            : 0.0;
        }
      }
    });

    let token_socket = localStorage.getItem("socket_token");
    if (token_socket) {
      let socketToken = token_socket.split("_")[0];
      socket.on("userDetails" + socketToken, async (response) => {
        console.log("socket user details====", response);
        if (currentPair !== "") {
          console.log("call 1");
          console.log("call currentPair", currentPair);
          getActiveOrders(1, currentPair);
          getUserbalance(currentPair);
          tradeHistory(1);
          getCancelOrders(1);
        } else {
          console.log("call 2");
          var urls = window.location.href;
          var fetchPair = urls.split("trade/")[1];
          console.log("call fetchPair", fetchPair);
          if (fetchPair) {
            setcurrentPair(fetchPair);
            getActiveOrders(1, fetchPair);
            getUserbalance(fetchPair);
            tradeHistory(1);
            getCancelOrders(1);
          }
        }
      });
      socket.on("cancelOrder" + socketToken, async (response) => {
        //toast.success("Your order cancelled by admin, Please try again later");
        if (currentPair !== "") {
          console.log("call 1");
          console.log("call currentPair", currentPair);
          getActiveOrders(1, currentPair);
          getUserbalance(currentPair);
          tradeHistory(1);
          getCancelOrders(1);
        } else {
          console.log("call 2");
          var urls = window.location.href;
          var fetchPair = urls.split("trade/")[1];
          console.log("call fetchPair", fetchPair);
          if (fetchPair) {
            setcurrentPair(fetchPair);
            getActiveOrders(1, fetchPair);
            getUserbalance(fetchPair);
            tradeHistory(1);
            getCancelOrders(1);
          }
        }
      });

      socket.on("botOrders", async (response) => {
        // console.log("botorder call")
        // var urls = window.location.href;
        // var fetchPair = urls.split("trade/")[1];
        // var symbols = fetchPair.split("_")[0]+fetchPair.split("_")[1];
        // await socket.emit("GetOrderBook", {symbol: symbols.toLowerCase()});
        // await socketstart();
       
        // getMarketTrades(fetchPair);
        // fetchTickerPrice(fetchPair);
      });

      socket.on("botCheck", async (response) => {
        console.log("botcheck call")
        var urls = window.location.href;
        var fetchPair = urls.split("trade/")[1];
        getMarketTrades(fetchPair);
        fetchTickerPrice(fetchPair);
        var symbols = fetchPair.split("_")[0]+fetchPair.split("_")[1];
        await socket.emit("GetOrderBook", {symbol: symbols.toLowerCase()});
      });

    }
  };
  useEffect(() => {
    fetchTheme();
    return () => {
      socket.disconnect();
    };
  }, []);
  const fetchTheme = () => {
    var theme = localStorage.getItem("theme");
    if (theme != undefined) {
      if (theme == "light") {
        setTheme("White");
      } else {
        setTheme("Dark");
      }
    } else {
      localStorage.setItem("theme", "dark");
      setTheme("Dark");
    }
    if (!isEmpty(pair)) {
      let symbol = pair;
      let themeValue = "Dark";
      if (theme == "light") {
        themeValue = "White";
      } else if (theme == "dark") {
        themeValue = "Dark";
      }
      buildchart("White", symbol);
    }
  };
  const getCurrpairDatas = async (pair) => {
    var data = {
      apiUrl: apiService.getCurrpairData,
      payload: {pair: pair},
    };
    var fetchticker = await postMethod(data);
    //Setloader(true);
    if (fetchticker) {
      setpairDetails(fetchticker.data);
      setmetadesc(
        "Adverb Ex Explore a secure and user-friendly trading platform for " +
          fetchticker.data?.from_symbol +
          "/" +
          fetchticker.data?.to_symbol +
          " pairs. Enjoy hassle-free transactions and maximize your investment potential."
      );
      setmetatitle(
        "Adverb - Trading Platform " +
          fetchticker.data?.from_symbol +
          "/" +
          fetchticker.data?.to_symbol +
          " Pairs"
      );
      //Setloader(false);
    }
  };
  const getMarketTrades = async (pair) => {
    try {
      var data = {
        apiUrl: apiService.marketTrades,
        payload: {pair: pair},
      };
      var fetchTradeHisotory = await postMethod(data);
      if (fetchTradeHisotory) {
        if (fetchTradeHisotory.status) {
          setmarketTrade(fetchTradeHisotory.Message);
        } else {
          setmarketTrade([]);
        }
      } else {
      }
    } catch (error) {}
  };
  const buildchart = (theme, pair) => {
    console.log("build chart====", pair);
    const widgetOptions = {
      symbol: pair,
      // BEWARE: no trailing slash is expected in feed URL
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed(
        env.apiHost + "chartapi/chart"
      ),
      interval: pair == "USDT_INR" ? "240" : "30",
      container_id: "tv_chart_container",
      library_path: "/charting_library/",

      locale: getLanguageFromURL() || "en",
      disabled_features: ["use_localstorage_for_settings"],
      enabled_features: ["study_templates"],
      charts_storage_url: "",
      charts_storage_api_version: "1.1",
      client_id: "tradingview.com",
      user_id: "public_user_id",
      fullscreen: false,
      //autosize: true,
      width: "100%",
      height: "459",
      studies_overrides: {},
      loading_screen: {backgroundColor: "#000"},
      theme: theme,
      toolbar_bg: "#000",
      timezone: "Asia/Kolkata",
      overrides: {
        // "symbolWatermarkProperties.color": "#000657",
        "paneProperties.background": "#000",
        "paneProperties.vertGridProperties.color": "transparent",
        "paneProperties.horzGridProperties.color": "transparent",
      },
    };

    if (theme == "White") {
      delete widgetOptions.toolbar_bg;
      delete widgetOptions.overrides;
    }

    const tvWidget = new widget(widgetOptions);

    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        const button = tvWidget.createButton();
        button.setAttribute("title", "Click to show a notification popup");
        button.classList.add("apply-common-tooltip");
        button.addEventListener("click", () =>
          tvWidget.showNoticeDialog({
            title: "Notification",
            body: "TradingView Charting Library API works correctly",
            callback: () => {
              console.log("Noticed!");
            },
          })
        );

        // button.innerHTML = 'Check API';
      });
    });
  };
  const getLanguageFromURL = () => {
    const regex = new RegExp("[\\?&]lang=([^&#]*)");
    const results = regex.exec(window.location.search);
    return results === null
      ? null
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };
  // const selectPairbyCurrency = async (curr) => {
  //   var data = {
  //     apiUrl: apiService.pairbycurrency,
  //     payload: {currency: curr},
  //   };
  //   var pairdetail = await postMethod(data);
  //   if (pairdetail) {
  //     setTimeout(() => {
  //       socket.on("DashTickerPrice", async (response) => {
  //         var tickarr = await response.data;
  //         for (let index = 0; index < pairdetail.data.length; index++) {
  //           const element = pairdetail.data[index];
  //           var replace_string = element.pair.replace("_", "");
  //           var changelower = replace_string.toLowerCase();
  //           if (tickarr[changelower]) {
  //             var tickobj = JSON.parse(tickarr[changelower]);
  //             if (tickarr) {
  //               if (element.pair == tickobj.pair) {
  //                 pairdetail.data[index]["price_change"] =
  //                   (await tickobj.change_percent)
  //                     ? parseFloat(tickobj.change_percent).toFixed(4)
  //                     : 0.0;
  //                 pairdetail.data[index]["lastprice"] = (await tickobj.lastprice
  //                   .lastprice)
  //                   ? parseFloat(tickobj.lastprice.lastprice).toFixed(4)
  //                   : 0.0;
  //               }
  //             }
  //           }
  //         }
  //         // console.log(tickarr,'-=-=-ticker arr-=-=-',pairdetail)
  //       });
  //     }, 500);
  //     await setpairlist(pairdetail.data);
  //     await setsearchInputlist(pairdetail.data);
  //   } else {
  //   }
  // };
  const selectPairbyCurrency = async (curr) => {
    socket.off("DashTickerPrice");
    socket.emit("GetTickerPrice", "getall");
    var data = {
      apiUrl: apiService.pairbycurrency,
      payload: {currency: curr},
    };
    setPairLoader(true);
   
    
    //setSiteLoader(true);
    var pairdetail = await postMethod(data);

    if (pairdetail) {
      // setPairLoader(false);

      // setSiteLoader(false);
      // fetchTheme();
      socket.on("DashTickerPrice", async (response) => {

        var tickarr = await response.data;
        for (let index = 0; index < pairdetail.data.length; index++) {
          const element = pairdetail.data[index];
          var replace_string = element.pair.replace("_", "");
          var changelower = replace_string.toLowerCase();
          if (tickarr[changelower]) {
            var tickobj = JSON.parse(tickarr[changelower]);
            if (tickarr) {
              if (element.pair == tickobj.pair) {
                pairdetail.data[index][
                  "price_change"
                ] = (await tickobj.change_percent)
                  ? parseFloat(tickobj.change_percent).toFixed(2)
                  : 0.0;
                pairdetail.data[index]["lastprice"] = (await tickobj.lastprice
                  .lastprice)
                  ? parseFloat(tickobj.lastprice.lastprice).toFixed(4)
                  : 0.0;

                pairdetail.data[index]["volume"] = (await tickobj.volume)
                  ? parseFloat(tickobj.volume).toFixed(2)
                  : 0.0;
              }
            }
          }
        }

        // setpairlist(
        //   pairdetail.data.sort(function (a, b) {
        //     return b.price_change - a.price_change;
        //   })
        // );

        if (curr == "USDT") {
          //setusdtpairlist(pairdetail.data);
          setgainpairlist(pairdetail.data);
          setlosepairlist(pairdetail.data);
          sethotpairlist(pairdetail.data);

          // gainpairlistref.current.sort(function (a, b) {
          //     return b.price_change - a.price_change;
          //   })

          // losepairlistref.current.sort(function (a, b) {
          //     return a.price_change - b.price_change;
          //   })

          // hotpairlistref.current.sort(function (a, b) {
          //   return b.volume - a.volume;
          // })
          // console.log("setgainpairlist---",hotpairlistref.current);
        }
      });

      await setsearchInputlist(pairdetail.data);
      setPairLoader(false);
      if (searchpair != null) {
        setpairlist(
          searchInputlistref.current.filter(function (tag) {
            if (
              tag.liquidity_name
                .toLowerCase()
                .indexOf(searchpair.toLowerCase()) >= 0 ||
              tag.liquidity_name
                .toLowerCase()
                .indexOf(searchpair.toLowerCase()) >= 0
            ) {
              return tag;
            }
          })
        );
      } else {
        setpairlist(pairdetail.data);
        await setsearchInputlist(pairdetail.data);
        setPairLoader(false);
      }
    }
  };

  const PairsbyCurrency = async (curr) => {
    var data = {
      apiUrl: apiService.pairbycurrency,
      payload: {currency: curr},
    };
    setPairLoader(true);
    //setSiteLoader(true);
    var pairdetail = await postMethod(data);

    if (pairdetail) {
      
      socket.on("DashTickerPrice", async (response) => {
        var tickarr = await response.data;
        for (let index = 0; index < pairdetail.data.length; index++) {
          const element = pairdetail.data[index];
          var replace_string = element.pair.replace("_", "");
          var changelower = replace_string.toLowerCase();
          if (tickarr[changelower]) {
            var tickobj = JSON.parse(tickarr[changelower]);
            if (tickarr) {
              if (element.pair == tickobj.pair) {
                pairdetail.data[index][
                  "price_change"
                ] = (await tickobj.change_percent)
                  ? parseFloat(tickobj.change_percent).toFixed(2)
                  : 0.0;
                pairdetail.data[index]["lastprice"] = (await tickobj.lastprice
                  .lastprice)
                  ? parseFloat(tickobj.lastprice.lastprice).toFixed(4)
                  : 0.0;

                pairdetail.data[index]["volume"] = (await tickobj.volume)
                  ? parseFloat(tickobj.volume).toFixed(2)
                  : 0.0;
              }
            }
          }
        }
        //setusdtpairlist(pairdetail.data);
        setgainpairlist(pairdetail.data);
        setlosepairlist(pairdetail.data);
        sethotpairlist(pairdetail.data);
      });
    }
  };
  const fetchTickerPrice = async (pair) => {
    try {
      var data = {
        apiUrl: apiService.fetch_tickers,
        payload: {pair: pair},
      };
      var fetchticker = await postMethod(data);
      if (fetchticker) {
        var data = await fetchticker.data;
        console.log("fetchTickerPrice data====", data);
        setpairTickerDetails(data);
        setmarketPrice(
          data.lastprice.lastprice ? data.lastprice.lastprice : 0.0
        );
        if (curnt_Ordertype_tabref.current == "Stop") {
          if (formValue.price <= 0) {
            formValue.price = "";
            formValue.sellprice = "";
            formValue.stop_price = +marketPriceref.current
              ? +marketPriceref.current
              : 0.0;
          }
        } else if (
          curnt_Ordertype_tabref.current == "Market" ||
          curnt_Ordertype_tabref.current == "Limit"
        ) {
          formValue.price = +marketPriceref.current
            ? +marketPriceref.current
            : 0.0;
          formValue.sellprice = +marketPriceref.current
            ? +marketPriceref.current
            : 0.0;
        }
      }
    } catch (error) {}
  };
  const selectPair = async (pair) => {
    //navigate("/trade/" + pair);
    window.location.href = "/trade/" + pair;
    setchartPair(replace_string);
    var replace_string = pair.replace("_", "");
    var changelower = replace_string.toLowerCase();
    socket.disconnect();
    socket.connect();
    await socket.emit("GetOrderBook", {symbol: changelower});
    var indexPage = pairlist.findIndex((x) => x.pair == pair);
    if (indexPage != -1) {
      var getPair = pairlist[indexPage];
      setcurrencyImage(getPair.Currency_image);
      var fromcurr = getPair.pair.split("_")[0];
      var toCurr = getPair.pair.split("_")[1];
      setFromCurrenncy(fromcurr);
      setToCurrenncy(toCurr);
      if (checkAuth === true) {
        getUserbalance(getPair.pair);
        getCancelOrders(1);
        getActiveOrders(1, getPair.pair);
      }
      getCurrpairDatas(getPair.pair);
      fetchTickerPrice(getPair.pair);
      getMarketTrades(getPair.pair);
      setPair(getPair.pair);
      setPairLoader(false);
      var themevalue =
        localStorage.getItem("theme") == "light" ? "White" : "Dark";
      buildchart("White", getPair.pair);
    }
  };
  const selectPair_start = async (pair) => {
    navigate("/trade/" + pair);
    setchartPair(replace_string);
    var replace_string = pair.replace("_", "");
    var changelower = replace_string.toLowerCase();
    socket.disconnect();
    socket.connect();
    await socket.emit("GetOrderBook", {symbol: changelower});
    var indexPage = pairlist.findIndex((x) => x.pair == pair);
    if (indexPage != -1) {
      var getPair = pairlist[indexPage];
      setcurrencyImage(getPair.Currency_image);
      var fromcurr = getPair.pair.split("_")[0];
      var toCurr = getPair.pair.split("_")[1];
      setFromCurrenncy(fromcurr);
      setToCurrenncy(toCurr);
      if (checkAuth === true) {
        getUserbalance(getPair.pair);
        getCancelOrders(1);
        getActiveOrders(1, getPair.pair);
      }
      getCurrpairDatas(getPair.pair);
      fetchTickerPrice(getPair.pair);
      getMarketTrades(getPair.pair);
      setPair(getPair.pair);
      setPairLoader(false);
      var themevalue =
        localStorage.getItem("theme") == "light" ? "White" : "Dark";
      buildchart("White", getPair.pair);
    }
  };
  const buyselectorder = async (e) => {
    setSelectOrder(true);
    console.log(e?.[0]);
    setBuyPrice(e?.[0]);
    setBuyAmount(e?.[1]);
    setBuyTotal(e?.[2]);
    if (
      curnt_Ordertype_tabref.current == "Limit" ||
      curnt_Ordertype_tabref.current == "Stop"
    ) {
      formValue.price = +e?.[0];
      formValue.amount = +e?.[1];
      formValue.total = +e?.[2];
    }

    // console.log(buyPriceref.current)
  };
  const sellselectorder = async (e) => {
    setSelectOrder(true);
    console.log(e?.[0]);
    setSellPrice(e?.[0]);
    setSellAmount(e?.[1]);
    setSellTotal(e?.[2]);
    if (
      curnt_Ordertype_tabref.current == "Limit" ||
      curnt_Ordertype_tabref.current == "Stop"
    ) {
      formValue.sellprice = +e?.[0];
      formValue.sellamount = +e?.[1];
      formValue.selltotal = +e?.[2];
    }
  };
  const getUserbalance = async (pair) => {
    if (pair != "") {
      var obj = {
        pair: pair,
      };
      // var obj = {
      //   from_symbol_id : pairDetails.from_symbol_id,
      //   to_symbol_id : pairDetails.to_symbol_id,
      // }
      var data = {
        apiUrl: apiService.getparUserBalance,
        payload: obj,
      };
      var resp = await postMethod(data);
      console.log("trade pair balance===", resp);
      if (resp.status) {
        var getFromBalance = resp.data.fromCurrency;
        var getToBalance = resp.data.toCurrency;
        setFromBalance(getFromBalance.totalBalance);
        settobalance(getToBalance.totalBalance);
        // setbalanceDetails(resp.Message);
      } else {
      }
    }
  };
  const getActiveOrders = async (pages, getpair) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
        pair: getpair,
      };
      var data = {
        apiUrl: apiService.getActiveOrders,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        settotalactive(resp.count);
        setActiveOders(resp.result);
      } else {
      }
    } catch (error) {}
  };
  const tradeHistory = async (pages) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
        pair: pairDetails.pair,
      };
      var data = {
        apiUrl: apiService.tradeHistory,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        settradeHistory(resp.result);
        settotalHist(resp.count);
      } else {
      }
    } catch (error) {}
  };
  const getCancelOrders = async (pages) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
        pair: currentPair,
      };
      var data = {
        apiUrl: apiService.getCancelOrders,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        setcancelOrders(resp.result);
        settotalcan(resp.count);
      } else {
      }
    } catch (error) {}
  };
  async function handleInputChange(event) {
    if (event.target.value.indexOf("_") > 0) {
      var searchval = event.target.value.replace("_", "");
      // setcurrentpairs(event.target.value)
      setpairlist(
        allpairslist.filter(function (tag) {
          if (
            tag.liquidity_name.toLowerCase().indexOf(searchval.toLowerCase()) >=
              0 ||
            tag.liquidity_name.toLowerCase().indexOf(searchval.toLowerCase()) >=
              0
          ) {
            return tag;
          }
        })
      );
    } else {
      setsearchpair(event.target.value);
      setpairlist(
        searchInputlist.filter(function (tag) {
          if (
            tag.liquidity_name
              .toLowerCase()
              .indexOf(event.target.value.toLowerCase()) >= 0 ||
            tag.liquidity_name
              .toLowerCase()
              .indexOf(event.target.value.toLowerCase()) >= 0
          ) {
            return tag;
          }
        })
      );
    }
  }
  const type_Ordertab_change = async (orderType) => {
    try {
      await setcurnt_Ordertype_tab(orderType);
      await setcurrentType("buy");
      if (orderType == "Market") {
        formValue.price = +marketPriceref.current;
        formValue.amount = "";
        formValue.total = "";

        formValue.sellprice = +marketPriceref.current;
        formValue.sellamount = "";
        formValue.selltotal = "";
      }
    } catch (error) {
      toast.error("Please try later");
    }
  };
  const handleChange = (e) => {
    e.preventDefault();
    const {name, value} = e.target;
    let formData = {...formValue, ...{[name]: value}};
    if (curnt_Ordertype_tab == "Limit") {
      let totalPrice = formData.price * formData.amount;
      let selltotalPrice = formData.sellprice * formData.sellamount;
      formData = {
        ...formData,
        ...{["total"]: parseFloat(totalPrice).toFixed(8)},
      };
      formData = {
        ...formData,
        ...{["selltotal"]: parseFloat(selltotalPrice).toFixed(8)},
      };
    } else if (curnt_Ordertype_tab == "Market") {
      let totalPrice = +marketPrice * formData.amount;
      let selltotalPrice = +marketPrice * formData.sellamount;

      formData = {
        ...formData,
        ...{["total"]: parseFloat(totalPrice).toFixed(8)},
      };
      formData = {
        ...formData,
        ...{["selltotal"]: parseFloat(selltotalPrice).toFixed(8)},
      };
    } else {
      let totalPrice = formData.stop_price * formData.amount;
      formData = {
        ...formData,
        ...{["total"]: parseFloat(totalPrice).toFixed(8)},
      };

      let selltotalPrice = formData.sellstop_price * formData.sellamount;
      formData = {
        ...formData,
        ...{["selltotal"]: parseFloat(selltotalPrice).toFixed(8)},
      };
    }

    if (formData.price < 0) {
      toast.error("Enter valid price");
    }
    if (formData.amount < 0) {
      toast.error("Enter valid amount");
    }

    if (formData.sellprice < 0) {
      toast.error("Enter valid price");
    }
    if (formData.sellamount < 0) {
      toast.error("Enter valid amount");
    }
    setFormValue(formData);
  };
  const orderPlace = async (ordertype, ordertab) => {
    try {
      var checkPrice = 0;
      if (ordertype == "Limit") {
        checkPrice = formValue.price;
      } else if (ordertype == "Market") {
        checkPrice = marketPrice;
      } else {
        checkPrice = formValue.price;
      }
      if (ordertab == "buy") {
        setorderloader(true);
        if (
          Number(buyPrice)
            ? Number(buyPrice)
            : formValue.amount > 0 && checkPrice > 0 && Number(buyTotal)
            ? Number(buyTotal)
            : total
        ) {
          // if(frombalance >= total){
          if (ordertype == "Limit") {
            // if (selectorder == true) {
            //    formValue.price = Math.floor(buyPrice)
            //    formValue.amount =Math.floor(buyAmount)
            //  }

            console.log(
              formValue.price,
              " formValue.price formValue.price formValue.price formValue.price formValue.price formValue.price"
            );
            var orderData = {
              // amount: parseFloat(Math.floor(buyAmount) ?Math.floor(buyAmount):formValue.amount),
              // price: parseFloat(Math.floor(buyPriceref.current)?Math.floor(buyPriceref.current):formValue.price),
              amount: formValue.amount,
              price: formValue.price,
              total: parseFloat(
                Math.floor(buyTotal) ? Math.floor(buyTotal) : total
              ),
              type: ordertab,
              orderType: ordertype,
              fromCurrency: fromCurrency,
              toCurrency: toCurrency,
              pair: currentPair,
              stop_price: 0,
              pair_id: pairDetails._id,
              fromCurr_id: pairDetails.from_symbol_id,
              toCurr_id: pairDetails.to_symbol_id,
            };
            console.log(orderData, "orderdataorderdata");
          }

          if (ordertype == "Market") {
            // if (selectorder == true) {
            //   setmarketPrice(Math.floor(parseFloat(buyPrice))),
            //   console.log(Math.floor(parseFloat(buyPrice)))
            //   console.log(Math.floor(buyPrice))
            //   console.log(typeof(Math.floor(buyPrice)));
            //   formValue.amount =Math.floor(buyAmount)
            // }
            var orderData = {
              amount: parseFloat(formValue.amount),
              price: parseFloat(marketPrice),
              total: marketPrice * parseFloat(formValue.amount),
              type: ordertab,
              orderType: ordertype,
              fromCurrency: fromCurrency,
              toCurrency: toCurrency,
              pair: currentPair,
              stop_price: 0,
              pair_id: pairDetails._id,
              fromCurr_id: pairDetails.from_symbol_id,
              toCurr_id: pairDetails.to_symbol_id,
            };
          }

          if (ordertype == "Stop") {
            // if (selectorder == true) {
            //   setmarketPrice(Math.floor(parseFloat(buyPrice))),
            //   formValue.amount =Math.floor(buyAmount)
            // }
            var orderData = {
              amount: parseFloat(formValue.amount),
              price: parseFloat(marketPrice),
              total: marketPrice * parseFloat(formValue.amount),
              type: ordertab,
              orderType: ordertype,
              fromCurrency: fromCurrency,
              toCurrency: toCurrency,
              pair: currentPair,
              stop_price: parseFloat(formValue.stop_price),
              pair_id: pairDetails._id,
              fromCurr_id: pairDetails.from_symbol_id,
              toCurr_id: pairDetails.to_symbol_id,
            };
          }

          var data = {
            apiUrl: apiService.orderPlaceapi,
            payload: orderData,
          };
          var resp = await postMethod(data);
          if (resp.status) {
            setorderloader(false);
            formValue.amount = "";
            formValue.price = marketPrice;
            formValue.total = "";
            formValue.stop_price = "";
            getActiveOrders(1, currentPair);
            toast.success(resp.Message);
            getUserbalance(currentPair);
            fetchTickerPrice(currentPair);
          } else {
            setorderloader(false);
            toast.error(resp.Message);
          }
          // }else{
          //   toast.error("insufficient funds");
          // }
        } else {
          toast.error("Enter all fields");
          setorderloader(false);
        }
      } else {
        setsellorderloader(true);
        // if(frombalance >= amount ){
        if (
          Number(sellAmount)
            ? Number(sellAmount)
            : formValue.sellamount > 0 && checkPrice > 0 && Number(sellTotal)
            ? Number(sellTotal)
            : selltotal
        ) {
          if (ordertype == "Limit") {
            // if (selectorder == true) {
            //   formValue.sellprice = Math.floor(sellPrice)
            //   formValue.sellamount =Math.floor(sellAmount)
            // }
            var orderData = {
              amount: parseFloat(formValue.sellamount),
              price: parseFloat(formValue.sellprice),
              total: parseFloat(sellTotal ? sellTotal : selltotal),
              type: ordertab,
              orderType: ordertype,
              fromCurrency: fromCurrency,
              toCurrency: toCurrency,
              pair: currentPair,
              stop_price: 0,
              pair_id: pairDetails._id,
              fromCurr_id: pairDetails.from_symbol_id,
              toCurr_id: pairDetails.to_symbol_id,
            };
          }
          if (ordertype == "Market") {
            // if (selectorder == true) {
            //   setmarketPrice(Math.floor(parseFloat(sellPrice)))
            //   // formValue.sellprice = Math.floor(sellPrice)
            //   formValue.sellamount =Math.floor(sellAmount)
            // }
            var orderData = {
              amount: parseFloat(formValue.sellamount),
              price: parseFloat(marketPrice),
              total: marketPrice * parseFloat(formValue.sellamount),
              type: ordertab,
              orderType: ordertype,
              fromCurrency: fromCurrency,
              toCurrency: toCurrency,
              pair: currentPair,
              stop_price: 0,
              pair_id: pairDetails._id,
              fromCurr_id: pairDetails.from_symbol_id,
              toCurr_id: pairDetails.to_symbol_id,
            };
          }
          if (ordertype == "Stop") {
            // if (selectorder == true) {
            //   setmarketPrice(Math.floor(sellPrice))
            //   // formValue.sellprice = Math.floor(sellPrice)
            //   formValue.sellamount = Math.floor(sellAmount)
            // }
            var orderData = {
              amount: parseFloat(formValue.sellamount),
              price: parseFloat(marketPrice),
              total: marketPrice * parseFloat(formValue.sellamount),
              type: ordertab,
              orderType: ordertype,
              fromCurrency: fromCurrency,
              toCurrency: toCurrency,
              pair: currentPair,
              stop_price: parseFloat(formValue.sellstop_price),
              pair_id: pairDetails._id,
              fromCurr_id: pairDetails.from_symbol_id,
              toCurr_id: pairDetails.to_symbol_id,
            };
          }

          var data = {
            apiUrl: apiService.orderPlaceapi,
            payload: orderData,
          };

          var resp = await postMethod(data);
          if (resp.status) {
            setsellorderloader(false);
            formValue.sellamount = "";
            formValue.sellprice = marketPrice;
            formValue.selltotal = "";
            formValue.sellstop_price = "";
            toast.success(resp.Message);
            getActiveOrders(1, currentPair);
            getUserbalance(currentPair);
            fetchTickerPrice(currentPair);
          } else {
            toast.error(resp.Message);
            setsellorderloader(false);
          }
          // }else{
          //   toast.error("insufficient funds");
          // }
        } else {
          toast.error("Enter all fields");
          setsellorderloader(false);
        }
      }
    } catch (error) {
      console.log(error, "error");
      toast.error("Please try again later");
      setorderloader(false);
      setsellorderloader(false);
    }
  };
  const handleChangeTotal = (e) => {
    e.preventDefault();
    const {name, value} = e.target;

    let formData = {...formValue, ...{[name]: value}};
    if (curnt_Ordertype_tab == "Limit") {
      let totalAmount = formData.total / formData.price;
      formData = {
        ...formData,
        ...{["amount"]: parseFloat(totalAmount).toFixed(8)},
      };

      let selltotalAmount = formData.selltotal / formData.sellprice;
      formData = {
        ...formData,
        ...{["sellamount"]: parseFloat(selltotalAmount).toFixed(8)},
      };
    } else if (curnt_Ordertype_tab == "Market") {
      let totalPrice = formData.total / +marketPrice;
      formData = {
        ...formData,
        ...{["amount"]: parseFloat(totalPrice).toFixed(8)},
      };

      let selltotalPrice = formData.selltotal / +marketPrice;
      formData = {
        ...formData,
        ...{["sellamount"]: parseFloat(selltotalPrice).toFixed(8)},
      };
    } else {
      let totalPrice = formData.total / formData.stop_price;
      formData = {
        ...formData,
        ...{["amount"]: parseFloat(totalPrice).toFixed(8)},
      };

      let selltotalPrice = formData.selltotal / formData.sellstop_price;
      formData = {
        ...formData,
        ...{["sellamount"]: parseFloat(selltotalPrice).toFixed(8)},
      };
    }

    if (formData.price < 0) {
      toast.error("Enter valid price");
    }
    if (formData.amount < 0) {
      toast.error("Enter valid amount");
    }

    if (formData.sellprice < 0) {
      toast.error("Enter valid price");
    }
    if (formData.sellamount < 0) {
      toast.error("Enter valid amount");
    }
    setFormValue(formData);
  };
  const orderCancel = async (cancelDatas) => {
    try {
      var obj = {
        _id: cancelDatas._id,
      };
      var data = {
        apiUrl: apiService.cancelOrder,
        payload: obj,
      };
      var fetchTradeHisotory = await postMethod(data);
      if (fetchTradeHisotory) {
        toast.success(
          "Order cancelled successfully, your amount credit to your wallet"
        );
        getActiveOrders(1, currentPair);
        getUserbalance(currentPair);
      } else {
        toast.error("Please try again later");
      }
    } catch (error) {
      toast.error("Please try again later");
    }
  };
  const buy_sell_percentage = (percentage, type) => {
    if (checkAuth == true) {
      if (percentage == 25) {
        if (type == "buy") {
          setbtntrade1("btntrade active");
          setbtntrade3("btntrade");
          setbtntrade4("btntrade");
          setbtntrade2("btntrade");

          setsellbtntrade1("btntrade");
          setsellbtntrade2("btntrade");
          setsellbtntrade3("btntrade");
          setsellbtntrade4("btntrade");
        } else {
          setsellbtntrade1("btntrade active");
          setsellbtntrade2("btntrade");
          setsellbtntrade3("btntrade");
          setsellbtntrade4("btntrade");

          setbtntrade1("btntrade");
          setbtntrade3("btntrade");
          setbtntrade4("btntrade");
          setbtntrade2("btntrade");
        }
      }
      if (percentage == 50) {
        if (type == "buy") {
          setbtntrade1("btntrade");
          setbtntrade3("btntrade");
          setbtntrade4("btntrade");
          setbtntrade2("btntrade active");

          setsellbtntrade1("btntrade");
          setsellbtntrade3("btntrade");
          setsellbtntrade4("btntrade");
          setsellbtntrade2("btntrade");
        } else {
          setsellbtntrade1("btntrade");
          setsellbtntrade3("btntrade");
          setsellbtntrade4("btntrade");
          setsellbtntrade2("btntrade active");

          setbtntrade1("btntrade");
          setbtntrade3("btntrade");
          setbtntrade4("btntrade");
          setbtntrade2("btntrade");
        }
      }
      if (percentage == 75) {
        if (type == "buy") {
          setbtntrade3("btntrade active");
          setbtntrade1("btntrade");
          setbtntrade4("btntrade");
          setbtntrade2("btntrade");

          setsellbtntrade3("btntrade");
          setsellbtntrade1("btntrade");
          setsellbtntrade4("btntrade");
          setsellbtntrade2("btntrade");
        } else {
          setsellbtntrade3("btntrade active");
          setsellbtntrade1("btntrade");
          setsellbtntrade4("btntrade");
          setsellbtntrade2("btntrade");

          setbtntrade3("btntrade");
          setbtntrade1("btntrade");
          setbtntrade4("btntrade");
          setbtntrade2("btntrade");
        }
      }
      if (percentage == 100) {
        if (type == "buy") {
          setbtntrade4("btntrade active");
          setbtntrade1("btntrade");
          setbtntrade3("btntrade");
          setbtntrade2("btntrade");

          setsellbtntrade4("btntrade");
          setsellbtntrade1("btntrade");
          setsellbtntrade3("btntrade");
          setsellbtntrade2("btntrade");
        } else {
          setsellbtntrade4("btntrade active");
          setsellbtntrade1("btntrade");
          setsellbtntrade3("btntrade");
          setsellbtntrade2("btntrade");

          setbtntrade4("btntrade");
          setbtntrade1("btntrade");
          setbtntrade3("btntrade");
          setbtntrade2("btntrade");
        }
      }

      if (type == "buy") {
        var total = (+percentage * +tobalance) / 100;
        if (total <= 0) {
          toast.error("Quantity canot be less than 0.0000");
        }
        var amt = total / +marketPriceref.current;
        formValue.amount = amt.toFixed(pairDetails?.amount_decimal);
        formValue.total = +total.toFixed(pairDetails?.price_decimal);
        formValue.sellamount = "";
        formValue.selltotal = "";
      } else if (type == "sell") {
        var amount = (+percentage * +frombalance) / 100;
        if (amount <= 0) {
          toast.error("Quantity canot be less than 0.00000000");
        }
        var total = amount * +marketPriceref.current;
        formValue.sellamount = amount.toFixed(pairDetails?.amount_decimal);
        formValue.selltotal = +total.toFixed(pairDetails?.price_decimal);
        formValue.amount = "";
        formValue.total = "";
      }
    } else {
      toast.error("Login to continue !");
    }
  };
  return (
    <>
      <Helmet>
        <title>{metatitleref.current}</title>
        <meta name="title" content={metatitleref.current} />
        <meta name="description" content={metadescref.current} />
      </Helmet>
      <div className="header tradepage">
        <Header />
        <main className="trader_page_pading">
        {loaderref.current == true ? (
          <div class="spinner css-ezm1fk">
            <div class="css-1qoumk2"></div>
            <div class="css-1v19680"></div>
            <div class="css-1v90jie"></div>
            <div class="css-19p0rvp"></div>
          </div>
        ) : (
          <>
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-lg-9 p-0">
                <div className="row">
                  <div className="col-lg-12 p-0">
                    <div className="trader_header">
                      <div className="price_trade_flex">
                        <div className="pair_section">
                          <h3>
                            {pairDetails?.from_symbol}/{pairDetails?.to_symbol}
                          </h3>
                        </div>
                        <div className="pair_section_chind justify-content-center">
                          <p className="text-greene">
                            {" "}
                            {isNaN(marketPrice) ? "" : parseFloat(marketPrice).toFixed(pairDetails?.price_decimal)}{" "}
                          </p>
                          {/* <span>$28,293.85</span> */}
                        </div>
                      </div>
                      <div className="flex_price_collection">
                        <div className="pair_section_chind">
                          <p>24h Price Change</p>
                          <span>
                            {pairTickerDetails.price_change <= 0 ? (
                              <span>
                                {isNaN(pairTickerDetails.price_change)
                                  ? ""
                                  : parseFloat(pairTickerDetails.price_change).toFixed(pairDetails?.price_decimal)}
                              </span>
                            ) : (
                              <span>
                                {isNaN(pairTickerDetails.price_change)
                                  ? ""
                                  : parseFloat(pairTickerDetails.price_change).toFixed(pairDetails?.price_decimal)}
                              </span>
                            )}
                          </span>
                        </div>

                        <div className="pair_section_chind">
                          <p>24h Change%</p>
                          <span>
                            {pairTickerDetails.change_percent <= 0 ? (
                              <span>
                                {isNaN(pairTickerDetails.change_percent)
                                  ? ""
                                  : parseFloat(pairTickerDetails.change_percent).toFixed(2)}
                              </span>
                            ) : (
                              <span>
                                {isNaN(pairTickerDetails.change_percent)
                                  ? ""
                                  : parseFloat(pairTickerDetails.change_percent).toFixed(2)}
                              </span>
                            )}
                          </span>
                        </div>

                        <div className="pair_section_chind">
                          <p>24h High</p>
                          <span>
                            {" "}
                            {isNaN(pairTickerDetails?.highprice)
                              ? ""
                              : parseFloat(pairTickerDetails?.highprice).toFixed(pairDetails?.price_decimal)}
                          </span>
                        </div>
                        <div className="pair_section_chind">
                          <p>24h Low</p>
                          <span>
                            {" "}
                            {isNaN(pairTickerDetails.lowprice)
                              ? ""
                              : parseFloat(pairTickerDetails.lowprice).toFixed(pairDetails?.price_decimal)}
                          </span>
                        </div>
                        <div className="pair_section_chind">
                          <p>24h Volume</p>
                          <span>
                            {isNaN(pairTickerDetails.volume)
                              ? ""
                              : parseFloat(pairTickerDetails.volume).toFixed(pairDetails?.amount_decimal)}
                          </span>
                        </div>
                        {/* <div className="pair_section_chind">
                        <p>24h Volume(USDT)</p>
                        <span>725,920,243.35</span>
                      </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 p-0">
                    <div className="pair_selec_tab new_top_gain">
                      <ul class="nav nav-tabs pdint_bouut">
                        
                        <li>
                          <a data-toggle="tab" href="#Gainers" className="active">
                            Top Gainers
                          </a>
                        </li>

                        <li class="active">
                          <a data-toggle="tab" href="#Hot" >
                            Top Volume
                          </a>
                        </li>

                        <li>
                          <a data-toggle="tab" href="#Losers">
                            Top Losers
                          </a>
                        </li>
                      </ul>
                      <div class="tab-content ">
                      <div id="Gainers" class="tab-pane fade in active show">
                          <article className="ordre-book">
                            <section class="orderbook-header">
                              <table width="100%">
                                <tr>
                                  <th class="price">Name</th>
                                  <th>Price / Volume</th>
                                  <th>24h chg% </th>
                                </tr>
                              </table>
                            </section>
                            <section class="side" id="asks">
                              <table width="100%">

                                {gainpairlistref.current &&
                                  gainpairlistref.current
                                    .sort(function (a, b) {
                                      return b.price_change - a.price_change;
                                    })
                                    .map((obj, i) => {
                                      if (i < 9) {
                                        return (
                                          <tr
                                            onClick={() =>
                                              selectPair(obj?.pair)
                                            }
                                          >
                                            <td class="price  priceclick  ">
                                              <div className="coin_image">
                                                <img
                                                  src={obj?.Currency_image}
                                                />
                                                <span>{obj?.from_symbol}</span>
                                              </div>
                                            </td>
                                            <td className="pair_price_witdh">
                                              <div className="price_cls">
                                                <span>{obj?.lastprice}</span>
                                                <small>{obj?.volume}</small>
                                              </div>
                                            </td>
                                            <td className="pair_price_witdh">
                                              <div className="buttin_">
                                                {obj?.price_change >= 0 ? (
                                                  <button className="green_">
                                                    +{obj?.price_change}
                                                  </button>
                                                ) : (
                                                  <button className="red_">
                                                    {obj?.price_change}
                                                  </button>
                                                )}
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    })}
                              </table>
                            </section>
                          </article>
                        </div>

                        <div id="Hot" class="tab-pane fade in">
                          <article className="ordre-book">
                            <section class="orderbook-header">
                              <table width="100%">
                                <tr>
                                  <th class="price">Name</th>
                                  <th>Price / Volume</th>
                                  <th>24h chg% </th>
                                </tr>
                              </table>
                            </section>
                            <section class="side" id="asks">
                              <table width="100%">
                                {hotpairlistref.current &&
                                  hotpairlistref.current
                                    .sort(function (a, b) {
                                      return b.volume - a.volume;
                                    })
                                    .map((obj, i) => {
                                      if (i < 9) {
                                        return (
                                          <tr
                                            onClick={() =>
                                              selectPair(obj?.pair)
                                            }
                                          >
                                            <td class="price  priceclick  ">
                                              <div className="coin_image">
                                                <img
                                                  src={obj?.Currency_image}
                                                />
                                                <span>{obj?.from_symbol}</span>
                                              </div>
                                            </td>
                                            <td className="pair_price_witdh">
                                              <div className="price_cls">
                                                <span>{obj?.lastprice}</span>
                                                <small>{obj?.volume}</small>
                                              </div>
                                            </td>
                                            <td className="pair_price_witdh">
                                              <div className="buttin_">
                                                {obj?.price_change >= 0 ? (
                                                  <button className="green_">
                                                    +{obj?.price_change}
                                                  </button>
                                                ) : (
                                                  <button className="red_">
                                                    {obj?.price_change}
                                                  </button>
                                                )}
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    })}
                              </table>
                            </section>
                          </article>
                        </div>
                        

                        <div id="Losers" class="tab-pane fade in">
                          <article className="ordre-book">
                            <section class="orderbook-header">
                              <table width="100%">
                                <tr>
                                  <th class="price">Name</th>
                                  <th>Price / Volume</th>
                                  <th>24h chg% </th>
                                </tr>
                              </table>
                            </section>
                            <section class="side" id="asks">
                              <table width="100%">
                                {losepairlistref.current &&
                                  losepairlistref.current
                                    .sort(function (a, b) {
                                      return a.price_change - b.price_change;
                                    })
                                    .map((obj, i) => {
                                      if (i < 9) {
                                        return (
                                          <tr
                                            onClick={() =>
                                              selectPair(obj?.pair)
                                            }
                                          >
                                            <td class="price  priceclick  ">
                                              <div className="coin_image">
                                                <img
                                                  src={obj?.Currency_image}
                                                />
                                                <span>{obj?.from_symbol}</span>
                                              </div>
                                            </td>
                                            <td className="pair_price_witdh">
                                              <div className="price_cls">
                                                <span>{obj?.lastprice}</span>
                                                <small>{obj?.volume}</small>
                                              </div>
                                            </td>
                                            <td className="pair_price_witdh">
                                              <div className="buttin_">
                                                {obj?.price_change >= 0 ? (
                                                  <button className="green_">
                                                    +{obj?.price_change}
                                                  </button>
                                                ) : (
                                                  <button className="red_">
                                                    {obj?.price_change}
                                                  </button>
                                                )}
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    })}
                              </table>
                            </section>
                          </article>
                        </div>
                        
                      </div>
                    </div>

                    <article className="ordre-book datafeed_overlya">
                      <section class="orderbook-header">
                        <table width="100%">
                          <tr>
                            <th class="price">Price</th>
                            <th>Amount</th>
                            <th>Total </th>
                          </tr>
                        </table>
                      </section>
                      <section class="side" id="asks">
                        <table width="100%">
                          {orderbookask.length > 0 ? (
                            orderbookask.map((ask, i) => {
                              return (
                                <tr onClick={() => buyselectorder(ask)}>
                                  <td class="price sell priceclick pair_click">
                                    {ask?.[0]}
                                  </td>
                                  <td>{ask?.[1]}</td>
                                  <td>{ask?.[2]}</td>
                                  <div
                                    className="over_lay_red"
                                    style={{
                                      width:
                                        ask?.percent !== 0
                                          ? ask?.percent + "%"
                                          : 0 + "%",
                                    }}
                                  ></div>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              {" "}
                              <td colSpan="3"> Data not found!</td>{" "}
                            </tr>
                          )}
                        </table>
                      </section>
                      <section class="divider">
                        <div class="current-price">
                          {parseFloat(marketPriceref.current).toFixed(8)}
                        </div>
                      </section>
                      <section class="side bids">
                        <table width="100%">
                          {orderbookbid.length > 0 ? (
                            orderbookbid.map((bid, i) => {
                              return (
                                <tr onClick={() => sellselectorder(bid)}>
                                  <td class="price buy priceclick pair_click">
                                    {bid?.[0]}
                                  </td>
                                  <td>{bid?.[1]}</td>
                                  <td>{bid?.[2]}</td>
                                  <div
                                    className="over_lay_green"
                                    style={{
                                      width:
                                        bid?.percent !== 0
                                          ? bid?.percent + "%"
                                          : 0 + "%",
                                    }}
                                  ></div>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              {" "}
                              <td colSpan="3"> Data not found!</td>{" "}
                            </tr>
                          )}
                        </table>
                      </section>
                    </article>
                  </div>
                  <div className="col-lg-8 p-0">
                    <div className="chart">
                      {/* <img
                      src={require("../Image/chartss.png")}
                      className="img-fluid"
                    /> */}
                      <div id="tv_chart_container"></div>
                    </div>
                    <div className="order_tabs">
                      <ul class="nav nav-tabs">
                        <li class="active">
                          <a
                            data-toggle="tab"
                            href="#Limit_Order"
                            className="active"
                            onClick={() => type_Ordertab_change("Limit")}
                          >
                            Limit
                          </a>
                        </li>
                        <li>
                          <a
                            data-toggle="tab"
                            href="#market_order"
                            onClick={() => type_Ordertab_change("Market")}
                          >
                            Market
                          </a>
                        </li>
                        <li>
                          <a
                            data-toggle="tab"
                            href="#stop_limit"
                            onClick={() => type_Ordertab_change("Stop")}
                          >
                            Stop-limit
                          </a>
                        </li>
                      </ul>
                      <div class="tab-content trade">
                        <div
                          id="Limit_Order"
                          class="tab-pane fade in active show"
                        >
                          <div className="form_marker">
                            <div className="row">
                              <div className="col-lg-6">
                                {/* <form> */}
                                <p className="avail_balace">
                                  Avbl{" "}
                                  <span>
                                    {tobalance} {pairDetails?.to_symbol}{" "}
                                  </span>
                                </p>
                                <div class="form-group">
                                  <div className="form_flex_content">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">Price</div>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="Price"
                                      value={buyPrice ? buyPrice : price}
                                      onChange={handleChange}
                                      name="price"
                                    />
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">
                                        {" "}
                                        {pairDetails?.to_symbol}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div className="form_flex_content">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">Amount</div>
                                    </div>
                                    <input
                                      type="email"
                                      class="form-control"
                                      name="amount"
                                      value={amount}
                                      onChange={handleChange}
                                    />
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">
                                        {pairDetails?.from_symbol}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <ul class="nav nav-tabs nav_tabs_ne percent">
                                  <li class="active">
                                    <a
                                      data-toggle="tab"
                                      href="#home"
                                      onClick={() =>
                                        buy_sell_percentage("25", "buy")
                                      }
                                      className={btntrade1}
                                    >
                                      25%
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      data-toggle="tab"
                                      href="#menu1"
                                      onClick={() =>
                                        buy_sell_percentage("50", "buy")
                                      }
                                      className={btntrade2}
                                    >
                                      50%
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      data-toggle="tab"
                                      href="#menu2"
                                      onClick={() =>
                                        buy_sell_percentage("75", "buy")
                                      }
                                      className={btntrade3}
                                    >
                                      75%
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      data-toggle="tab"
                                      href="#menu3"
                                      onClick={() =>
                                        buy_sell_percentage("100", "buy")
                                      }
                                      className={btntrade4}
                                    >
                                      100%
                                    </a>
                                  </li>
                                </ul>
                                <div class="form-group">
                                  <div className="form_flex_content">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">Total</div>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      name="total"
                                      value={total}
                                      onChange={handleChangeTotal}
                                    />
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">
                                        {pairDetails?.to_symbol}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* <button
                                  type="submit"
                                  class="btn btn-default button_trade buy"
                                >
                                  Buy {pairDetails?.from_symbol}
                                </button> */}
                                {!checkAuth ? (
                                  <Link to="/login" className="login_now">
                                    Login to continue
                                  </Link>
                                ) : orderloaderref.current ? (
                                  <button className="btn btn-default button_trade buy">
                                    Loading
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-default button_trade buy"
                                    onClick={() => orderPlace("Limit", "buy")}
                                  >
                                    {/* {currentType} */}
                                    Buy {pairDetails?.from_symbol}
                                  </button>
                                )}
                                {/* </form> */}
                              </div>
                              <div className="col-lg-6">
                                {/* <form> */}
                                <p className="avail_balace">
                                  Avbl{" "}
                                  <span>
                                    {" "}
                                    {frombalance} {pairDetails?.from_symbol}
                                  </span>
                                </p>
                                <div class="form-group">
                                  <div className="form_flex_content">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">Price</div>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      value={sellPrice ? sellPrice : sellprice}
                                      name="sellprice"
                                      onChange={handleChange}
                                    />
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">
                                        {pairDetails?.to_symbol}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div className="form_flex_content">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">Amount</div>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      name="sellamount"
                                      value={sellamount}
                                      onChange={handleChange}
                                    />
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">
                                        {pairDetails?.from_symbol}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <ul class="nav nav-tabs nav_tabs_ne percent">
                                  <li class="active">
                                    <a
                                      data-toggle="tab"
                                      href="#home"
                                      className={sellbtntrade1}
                                      onClick={() =>
                                        buy_sell_percentage("25", "sell")
                                      }
                                    >
                                      25%
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      data-toggle="tab"
                                      href="#menu1"
                                      onClick={() =>
                                        buy_sell_percentage("50", "sell")
                                      }
                                      className={sellbtntrade2}
                                    >
                                      50%
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      data-toggle="tab"
                                      href="#menu2"
                                      className={sellbtntrade3}
                                      onClick={() =>
                                        buy_sell_percentage("75", "sell")
                                      }
                                    >
                                      75%
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      data-toggle="tab"
                                      href="#menu3"
                                      onClick={() =>
                                        buy_sell_percentage("100", "sell")
                                      }
                                      className={sellbtntrade4}
                                    >
                                      100%
                                    </a>
                                  </li>
                                </ul>
                                <div class="form-group">
                                  <div className="form_flex_content">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">Total</div>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      value={selltotal}
                                      name="total"
                                      onChange={handleChangeTotal}
                                    />
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">
                                        {pairDetails?.to_symbol}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* <button
                                  type="submit"
                                  class="btn btn-default button_trade sell"
                                >
                                  Sell
                                </button> */}
                                {!checkAuth ? (
                                  <Link to="/login" className="login_now">
                                    Login to continue
                                  </Link>
                                ) : sellorderloaderref.current ? (
                                  <button className="btn btn-default button_trade sell">
                                    Loading
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-default button_trade sell"
                                    onClick={() => orderPlace("Limit", "sell")}
                                  >
                                    {/* {currentType} */}
                                    Sell {pairDetails?.from_symbol}
                                  </button>
                                )}
                                {/* </form> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div id="market_order" class="tab-pane fade">
                          <div className="form_marker">
                            <div className="row">
                              <div className="col-lg-6">
                                {/* <form> */}
                                <p className="avail_balace">
                                  Avbl{" "}
                                  <span>
                                    {tobalance} {pairDetails?.to_symbol}{" "}
                                  </span>
                                </p>
                                <div class="form-group">
                                  <div className="form_flex_content">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">Price</div>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="Price"
                                      value={price}
                                      onChange={handleChange}
                                      name="price"
                                      disabled="true"
                                    />
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">
                                        {" "}
                                        {pairDetails?.to_symbol}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div className="form_flex_content">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">Amount</div>
                                    </div>
                                    <input
                                      type="email"
                                      class="form-control"
                                      name="amount"
                                      value={amount}
                                      onChange={handleChange}
                                    />
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">
                                        {pairDetails?.from_symbol}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <ul class="nav nav-tabs nav_tabs_ne percent">
                                  <li class="active">
                                    <a
                                      data-toggle="tab"
                                      href="#home"
                                      onClick={() =>
                                        buy_sell_percentage("25", "buy")
                                      }
                                      className={btntrade1}
                                    >
                                      25%
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      data-toggle="tab"
                                      href="#menu1"
                                      onClick={() =>
                                        buy_sell_percentage("50", "buy")
                                      }
                                      className={btntrade2}
                                    >
                                      50%
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      data-toggle="tab"
                                      href="#menu2"
                                      onClick={() =>
                                        buy_sell_percentage("75", "buy")
                                      }
                                      className={btntrade3}
                                    >
                                      75%
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      data-toggle="tab"
                                      href="#menu3"
                                      onClick={() =>
                                        buy_sell_percentage("100", "buy")
                                      }
                                      className={btntrade4}
                                    >
                                      100%
                                    </a>
                                  </li>
                                </ul>
                                <div class="form-group">
                                  <div className="form_flex_content">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">Total</div>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      name="total"
                                      value={total}
                                      onChange={handleChangeTotal}
                                    />
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">
                                        {pairDetails?.to_symbol}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* <button
                                  type="submit"
                                  class="btn btn-default button_trade buy"
                                >
                                  Buy {pairDetails?.from_symbol}
                                </button> */}
                                {!checkAuth ? (
                                  <Link to="/login" className="login_now">
                                    Login to continue
                                  </Link>
                                ) : orderloaderref.current ? (
                                  <button className="btn btn-default button_trade buy">
                                    Loading
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-default button_trade buy"
                                    onClick={() => orderPlace("Market", "buy")}
                                  >
                                    {/* {currentType} */}
                                    Buy {pairDetails?.from_symbol}
                                  </button>
                                )}
                                {/* </form> */}
                              </div>
                              <div className="col-lg-6">
                                {/* <form> */}
                                <p className="avail_balace">
                                  Avbl{" "}
                                  <span>
                                    {" "}
                                    {frombalance} {pairDetails?.from_symbol}
                                  </span>
                                </p>
                                <div class="form-group">
                                  <div className="form_flex_content">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">Price</div>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      value={sellprice}
                                      name="sellprice"
                                      onChange={handleChange}
                                      disabled="true"
                                    />
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">
                                        {pairDetails?.to_symbol}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div className="form_flex_content">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">Amount</div>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      name="sellamount"
                                      value={sellamount}
                                      onChange={handleChange}
                                    />
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">
                                        {pairDetails?.from_symbol}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <ul class="nav nav-tabs nav_tabs_ne percent">
                                  <li class="active">
                                    <a
                                      data-toggle="tab"
                                      href="#home"
                                      className={sellbtntrade1}
                                      onClick={() =>
                                        buy_sell_percentage("25", "sell")
                                      }
                                    >
                                      25%
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      data-toggle="tab"
                                      href="#menu1"
                                      onClick={() =>
                                        buy_sell_percentage("50", "sell")
                                      }
                                      className={sellbtntrade2}
                                    >
                                      50%
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      data-toggle="tab"
                                      href="#menu2"
                                      className={sellbtntrade3}
                                      onClick={() =>
                                        buy_sell_percentage("75", "sell")
                                      }
                                    >
                                      75%
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      data-toggle="tab"
                                      href="#menu3"
                                      onClick={() =>
                                        buy_sell_percentage("100", "sell")
                                      }
                                      className={sellbtntrade4}
                                    >
                                      100%
                                    </a>
                                  </li>
                                </ul>
                                <div class="form-group">
                                  <div className="form_flex_content">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">Total</div>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      value={selltotal}
                                      name="total"
                                      onChange={handleChangeTotal}
                                    />
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">
                                        {pairDetails?.to_symbol}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* <button
                                  type="submit"
                                  class="btn btn-default button_trade sell"
                                >
                                  Sell
                                </button> */}

                                {!checkAuth ? (
                                  <Link to="/login" className="login_now">
                                    Login to continue
                                  </Link>
                                ) : sellorderloaderref.current ? (
                                  <button className="btn btn-default button_trade sell">
                                    Loading
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-default button_trade sell"
                                    onClick={() => orderPlace("Market", "sell")}
                                  >
                                    {/* {currentType} */}
                                    Sell {pairDetails?.from_symbol}
                                  </button>
                                )}
                                {/* </form> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div id="stop_limit" class="tab-pane fade">
                          <div className="form_marker">
                            <div className="row">
                              <div className="col-lg-6">
                                {/* <form> */}
                                <p className="avail_balace">
                                  Avbl{" "}
                                  <span>
                                    {tobalance} {pairDetails?.to_symbol}{" "}
                                  </span>
                                </p>

                                <div class="form-group">
                                  <div className="form_flex_content">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">
                                        Stop Price
                                      </div>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      value={price}
                                      name="price"
                                      onChange={handleChange}
                                      //
                                    />
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">
                                        {" "}
                                        {pairDetails?.to_symbol}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div className="form_flex_content">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">
                                        Limit Price
                                      </div>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      value={buyPrice ? buyPrice : stop_price}
                                      onChange={handleChange}
                                      name="stop_price"
                                    />
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">
                                        {" "}
                                        {pairDetails?.to_symbol}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div className="form_flex_content">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">Amount</div>
                                    </div>
                                    <input
                                      type="email"
                                      class="form-control"
                                      name="amount"
                                      value={amount}
                                      onChange={handleChange}
                                    />
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">
                                        {pairDetails?.from_symbol}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <ul class="nav nav-tabs nav_tabs_ne percent">
                                  <li class="active">
                                    <a
                                      data-toggle="tab"
                                      href="#home"
                                      onClick={() =>
                                        buy_sell_percentage("25", "buy")
                                      }
                                      className={btntrade1}
                                    >
                                      25%
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      data-toggle="tab"
                                      href="#menu1"
                                      onClick={() =>
                                        buy_sell_percentage("50", "buy")
                                      }
                                      className={btntrade2}
                                    >
                                      50%
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      data-toggle="tab"
                                      href="#menu2"
                                      onClick={() =>
                                        buy_sell_percentage("75", "buy")
                                      }
                                      className={btntrade3}
                                    >
                                      75%
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      data-toggle="tab"
                                      href="#menu3"
                                      onClick={() =>
                                        buy_sell_percentage("100", "buy")
                                      }
                                      className={btntrade4}
                                    >
                                      100%
                                    </a>
                                  </li>
                                </ul>
                                <div class="form-group">
                                  <div className="form_flex_content">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">Total</div>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      name="total"
                                      value={total}
                                      // onChange={handleChangeTotal}
                                    />
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">
                                        {pairDetails?.to_symbol}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* <button
                                  type="submit"
                                  class="btn btn-default button_trade buy"
                                >
                                  Buy {pairDetails?.from_symbol}
                                </button> */}
                                {!checkAuth ? (
                                  <Link to="/login" className="login_now">
                                    Login to continue
                                  </Link>
                                ) : orderloaderref.current ? (
                                  <button className="btn btn-default button_trade buy">
                                    Loading
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-default button_trade buy"
                                    onClick={() => orderPlace("Stop", "buy")}
                                  >
                                    {/* {currentType} */}
                                    Buy {pairDetails?.from_symbol}
                                  </button>
                                )}
                                {/* </form> */}
                              </div>

                              <div className="col-lg-6">
                                {/* <form> */}
                                <p className="avail_balace">
                                  Avbl{" "}
                                  <span>
                                    {" "}
                                    {frombalance} {pairDetails?.from_symbol}
                                  </span>
                                </p>
                                <div class="form-group">
                                  <div className="form_flex_content">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">
                                        Stop Price
                                      </div>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      value={sellprice}
                                      name="sellprice"
                                      onChange={handleChange}
                                    />
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">
                                        {pairDetails?.to_symbol}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div className="form_flex_content">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">
                                        Limit Price
                                      </div>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      value={
                                        sellPrice ? sellPrice : sellstop_price
                                      }
                                      onChange={handleChange}
                                      name="sellstop_price"
                                    />
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">
                                        {pairDetails?.to_symbol}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div className="form_flex_content">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">Amount</div>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      name="sellamount"
                                      value={sellamount}
                                      onChange={handleChange}
                                    />
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">
                                        {pairDetails?.from_symbol}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <ul class="nav nav-tabs nav_tabs_ne percent">
                                  <li class="active">
                                    <a
                                      data-toggle="tab"
                                      href="#home"
                                      className={sellbtntrade1}
                                      onClick={() =>
                                        buy_sell_percentage("25", "sell")
                                      }
                                    >
                                      25%
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      data-toggle="tab"
                                      href="#menu1"
                                      onClick={() =>
                                        buy_sell_percentage("50", "sell")
                                      }
                                      className={sellbtntrade2}
                                    >
                                      50%
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      data-toggle="tab"
                                      href="#menu2"
                                      className={sellbtntrade3}
                                      onClick={() =>
                                        buy_sell_percentage("75", "sell")
                                      }
                                    >
                                      75%
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      data-toggle="tab"
                                      href="#menu3"
                                      onClick={() =>
                                        buy_sell_percentage("100", "sell")
                                      }
                                      className={sellbtntrade4}
                                    >
                                      100%
                                    </a>
                                  </li>
                                </ul>
                                <div class="form-group">
                                  <div className="form_flex_content">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">Total</div>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      value={selltotal}
                                      name="total"
                                      // onChange={handleChangeTotal}
                                    />
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">
                                        {pairDetails?.to_symbol}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* <button
                                  type="submit"
                                  class="btn btn-default button_trade sell"
                                >
                                  Sell
                                </button> */}

                                {!checkAuth ? (
                                  <Link to="/login" className="login_now">
                                    Login to continue
                                  </Link>
                                ) : sellorderloaderref.current ? (
                                  <button className="btn btn-default button_trade sell">
                                    Loading
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-default button_trade sell"
                                    onClick={() => orderPlace("Stop", "sell")}
                                  >
                                    {/* {currentType} */}
                                    Sell {pairDetails?.from_symbol}
                                  </button>
                                )}
                                {/* </form> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 p-0">
                <div className="pair_selec_tab">
                  <div className="search_pair">
                    <div className="input_search">
                      <i class="bi bi-search"></i>
                      <input
                        type="text"
                        placeholder="search"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <ul class="nav nav-tabs">
                    {/* <li className="active">
                      <a
                        data-toggle="tab"
                        href="#INR"
                        className="active"
                        onClick={() => selectPairbyCurrency("INR")}
                      >
                        INR
                      </a>
                    </li> */}

                    <li className="active">
                      <a
                        data-toggle="tab"
                        href="#USTD"
                        className="active"
                        onClick={() => selectPairbyCurrency("USDT")}
                      >
                        USDT
                      </a>
                    </li>

                    <li>
                      <a
                        data-toggle="tab"
                        href="#BTC"
                        onClick={() => selectPairbyCurrency("BTC")}
                      >
                        BTC
                      </a>
                    </li>

                    <li>
                      <a
                        data-toggle="tab"
                        href="#ETH"
                        onClick={() => selectPairbyCurrency("ETH")}
                      >
                        ETH
                      </a>
                    </li>

                   
                  </ul>
                  <div class="tab-content ">
                    {/* <div id="INR" class="tab-pane fade in active show">
                      <article className="ordre-book">
                        <section class="orderbook-header">
                          <table width="100%">
                            <tr>
                              <th class="price">Pair</th>
                              <th>Price</th>
                              <th>Change </th>
                            </tr>
                          </table>
                        </section>
                        <section class="side" id="asks">
                          <table width="100%">
                            {pairlist &&
                              //pairlist.sort(function (a, b) { return b.price_change - a.price_change;}).map((obj, i) => {
                              pairlist
                                //.sort((a,b)=>a.price_change < b.price_change ? 1 :-1)
                                .map((obj, i) => {
                                  return (
                                    <tr onClick={() => selectPair(obj?.pair)}>
                                      <td class="price  priceclick pair_click">
                                        <span>
                                          {obj?.from_symbol}/
                                          <small>{obj?.to_symbol}</small>
                                        </span>
                                      </td>
                                      <td className="pair_price_witdh">
                                        {" "}
                                        {obj?.lastprice <= 0 ? (
                                          <span className="text-red">
                                            {parseFloat(obj?.lastprice).toFixed(
                                              2
                                            )}
                                          </span>
                                        ) : (
                                          <span className="text-green">
                                            {parseFloat(obj?.lastprice).toFixed(
                                              2
                                            )}
                                          </span>
                                        )}
                                      </td>
                                      {obj?.price_change <= 0 ? (
                                        <td className="pair_price_witdh text-red">
                                          <i class="bi bi-caret-down-fill"></i>{" "}
                                          {obj?.price_change}%
                                        </td>
                                      ) : (
                                        <td className="pair_price_witdh text-green">
                                          <i class="bi bi-caret-up-fill"></i>{" "}
                                          {obj?.price_change}%
                                        </td>
                                      )}
                                    </tr>
                                  );
                                })}
                          </table>
                        </section>
                      </article>
                    </div> */}
                    <div id="BTC" class="tab-pane fade in">
                      <article className="ordre-book">
                        <section class="orderbook-header">
                          <table width="100%">
                            <tr>
                              <th class="price">Pair</th>
                              <th>Price</th>
                              <th>Change </th>
                            </tr>
                          </table>
                        </section>
                        <section class="side" id="asks">
                          <table width="100%">
                            {pairlist &&
                              //pairlist.sort(function (a, b) { return b.price_change - a.price_change;}).map((obj, i) => {
                              pairlist
                                //.sort((a,b)=>a.price_change<b.price_change ? 1 : -1)
                                .map((obj, i) => {
                                  return (
                                    <tr onClick={() => selectPair(obj?.pair)}>
                                      <td class="price  priceclick pair_click">
                                        <span>
                                          {obj?.from_symbol}/
                                          <small>{obj?.to_symbol}</small>
                                        </span>
                                      </td>
                                      <td className="pair_price_witdh">
                                        {" "}
                                        {obj?.lastprice <= 0 ? (
                                          <span className="text-red">
                                            {parseFloat(obj?.lastprice).toFixed(
                                              8
                                            )}
                                          </span>
                                        ) : (
                                          <span className="text-green">
                                            {parseFloat(obj?.lastprice).toFixed(
                                              8
                                            )}
                                          </span>
                                        )}
                                      </td>
                                      {obj?.price_change <= 0 ? (
                                        <td className="pair_price_witdh text-red">
                                          <i class="bi bi-caret-down-fill"></i>{" "}
                                          {obj?.price_change}%
                                        </td>
                                      ) : (
                                        <td className="pair_price_witdh text-green">
                                          <i class="bi bi-caret-up-fill"></i>{" "}
                                          {obj?.price_change}%
                                        </td>
                                      )}
                                      {/* <td className="pair_price_witdh text-red">{obj?.price_change}%</td> */}
                                    </tr>
                                  );
                                })}
                          </table>
                        </section>
                      </article>
                    </div>

                    <div id="ETH" class="tab-pane fade in">
                      <article className="ordre-book">
                        <section class="orderbook-header">
                          <table width="100%">
                            <tr>
                              <th class="price">Pair</th>
                              <th>Price</th>
                              <th>Change </th>
                            </tr>
                          </table>
                        </section>
                        <section class="side" id="asks">
                          <table width="100%">
                            {pairlist &&
                              //pairlist.sort(function (a, b) { return b.price_change - a.price_change;}).map((obj, i) => {
                              pairlist
                                //.sort((a,b)=>a.price_change<b.price_change ? 1 : -1)
                                .map((obj, i) => {
                                  return (
                                    <tr onClick={() => selectPair(obj?.pair)}>
                                      <td class="price  priceclick pair_click">
                                        <span>
                                          {obj?.from_symbol}/
                                          <small>{obj?.to_symbol}</small>
                                        </span>
                                      </td>
                                      <td className="pair_price_witdh">
                                        {" "}
                                        {obj?.lastprice <= 0 ? (
                                          <span className="text-red">
                                            {parseFloat(obj?.lastprice)}
                                          </span>
                                        ) : (
                                          <span className="text-green">
                                            {parseFloat(obj?.lastprice)}
                                          </span>
                                        )}
                                      </td>
                                      {obj?.price_change <= 0 ? (
                                        <td className="pair_price_witdh text-red">
                                          <i class="bi bi-caret-down-fill"></i>{" "}
                                          {obj?.price_change}%
                                        </td>
                                      ) : (
                                        <td className="pair_price_witdh text-green">
                                          <i class="bi bi-caret-up-fill"></i>{" "}
                                          {obj?.price_change}%
                                        </td>
                                      )}
                                    </tr>
                                  );
                                })}
                          </table>
                        </section>
                      </article>
                    </div>

                    <div id="USTD" class="tab-pane fade in active show">
                      <article className="ordre-book">
                        <section class="orderbook-header">
                          <table width="100%">
                            <tr>
                              <th class="price">Pair</th>
                              <th>Price</th>
                              <th>Change </th>
                            </tr>
                          </table>
                        </section>
                        <section class="side" id="asks">
                          <table width="100%">
                            {pairlist &&
                              //pairlist.sort(function (a, b) { return b.price_change - a.price_change;}).map((obj, i) => {
                              pairlist
                                .sort((a, b) =>
                                  a.from_symbol.localeCompare(b.from_symbol)
                                )
                                .map((obj, i) => {
                                  return (
                                    <tr onClick={() => selectPair(obj?.pair)}>
                                      <td class="price  priceclick pair_click">
                                        <span>
                                          {obj?.from_symbol}/
                                          <small>{obj?.to_symbol}</small>
                                        </span>
                                      </td>
                                      <td className="pair_price_witdh">
                                        {" "}
                                        {obj?.lastprice <= 0 ? (
                                          <span className="text-red">
                                            {parseFloat(obj?.lastprice).toFixed(
                                              4
                                            )}
                                          </span>
                                        ) : (
                                          <span className="text-green">
                                            {parseFloat(obj?.lastprice).toFixed(
                                              4
                                            )}
                                          </span>
                                        )}
                                      </td>
                                      {obj?.price_change <= 0 ? (
                                        <td className="pair_price_witdh text-red">
                                          <i class="bi bi-caret-down-fill"></i>{" "}
                                          {obj?.price_change}%
                                        </td>
                                      ) : (
                                        <td className="pair_price_witdh text-green">
                                          <i class="bi bi-caret-up-fill"></i>{" "}
                                          {obj?.price_change}%
                                        </td>
                                      )}
                                      {/* <td className="pair_price_witdh text-red">{obj?.price_change}%</td> */}
                                    </tr>
                                  );
                                })}
                          </table>
                        </section>
                      </article>
                    </div>
                  </div>
                </div>
                <div className="pair_selec_tab bordee_top">
                  <ul class="nav nav-tabs pdint_bouut">
                    <li class="active">
                      <a
                        data-toggle="tab"
                        href="#Market_Trade"
                        className="active"
                      >
                        Market Trade
                      </a>
                    </li>
                    {/* <li>
                    <a data-toggle="tab" href="#My_Trade">
                      My Trade
                    </a>
                  </li> */}
                  </ul>
                  <div class="tab-content ">
                    <div id="Market_Trade" class="tab-pane fade in active show">
                      <article className="ordre-book">
                        <section class="orderbook-header">
                          <table width="100%">
                            <tr>
                              <th class="price">Price</th>
                              <th>Amount</th>
                              <th>Time </th>
                            </tr>
                          </table>
                        </section>
                        <section class="side" id="asks">
                          <table width="100%">
                            {
                              // marketTrade.length > 0 ? (
                              marketTrade.map((item, i) => {
                                return (
                                  <tr className="position_rel_over">
                                    {item.type == "buy" ? (
                                      <td>
                                        <span className="text-red">
                                          {" "}
                                          {item?.price}{" "}
                                        </span>
                                      </td>
                                    ) : (
                                      <td>
                                        <span className="text-green">
                                          {" "}
                                          {item?.price}{" "}
                                        </span>
                                      </td>
                                    )}
                                    <td>{item?.amount} </td>
                                    <td>{item?.time} </td>
                                  </tr>
                                );
                              })
                              // ) : (
                              //   <tr>
                              //     {" "}
                              //     {checkAuth ? (
                              //       <td colSpan="3"> No found market trades!</td>
                              //     ) : (
                              //       <td colSpan="3">
                              //         <Button className="btn btn-primary-alta connectBtn mt-5">
                              //           <Link to="/login">Login to continue</Link>
                              //         </Button>{" "}
                              //       </td>
                              //     )}{" "}
                              //   </tr>
                              // )
                            }
                            {/* <tr>
                            <td class="price  priceclick  text-red">
                              22.00.00
                            </td>
                            <td className="pair_price_witdh">0.00098</td>
                            <td className="pair_price_witdh">00:00:12</td>
                          </tr> */}
                          </table>
                        </section>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 p-0">
                <div className="pair_selec_tab order_history">
                  <ul class="nav nav-tabs pdint_bouut">
                    <li class="active">
                      <a
                        data-toggle="tab"
                        href="#OpenOrders"
                        className="active"
                      >
                        Open Orders
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#OrderHistory">
                        Order History
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#CancelOrder">
                        Cancel Order
                      </a>
                    </li>
                  </ul>
                  <div class="tab-content">
                    <div id="OpenOrders" class="tab-pane fade in active show">
                      <div className="paire_secrit responcive_table">
                        <div className="pair-table pb-0">
                          <div class="fixTableHead pading_5">
                            <article className="ordre-book open-text-left">
                              <section className="orderbook-header">
                                <table width="100%">
                                  <thead>
                                    <tr>
                                      <th>Date</th>
                                      <th>Pair </th>
                                      <th>Price </th>
                                      <th>Side </th>
                                      <th>Order Type </th>
                                      <th> Amount </th>
                                      <th>Total </th>
                                      <th>Cancel </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {activeOrderDatas?.length > 0 ? (
                                      activeOrderDatas.map((item, i) => {
                                        var dates = Moment(
                                          item.createddate
                                        ).format("DD-MM-YYYY hh:mm:ss");
                                        return (
                                          <tr>
                                            <td>{dates} </td>
                                            <td>{item?.pairName} </td>
                                            <td>
                                              {item?.tradeType == "buy" ? (
                                                <span className="text-green">
                                                  {" "}
                                                  {item?.ordertype == "Stop"
                                                    ? parseFloat(
                                                        item?.stoporderprice
                                                      ).toFixed(
                                                        pairDetails?.price_decimal
                                                      )
                                                    : parseFloat(
                                                        item?.price
                                                      ).toFixed(
                                                        pairDetails?.price_decimal
                                                      )}{" "}
                                                </span>
                                              ) : (
                                                <span className="text-red">
                                                  {" "}
                                                  {item?.ordertype == "Stop"
                                                    ? parseFloat(
                                                        item?.stoporderprice
                                                      ).toFixed(
                                                        pairDetails?.price_decimal
                                                      )
                                                    : parseFloat(
                                                        item?.price
                                                      ).toFixed(
                                                        pairDetails?.price_decimal
                                                      )}{" "}
                                                </span>
                                              )}
                                            </td>
                                            <td> {item?.tradeType} </td>
                                            <td> {item?.ordertype} </td>
                                            <td>
                                              {" "}
                                              {parseFloat(
                                                item?.filledAmount
                                              ).toFixed(
                                                pairDetails?.amount_decimal
                                              )}{" "}
                                            </td>
                                            <td>
                                              {item?.ordertype == "Stop"
                                                ? parseFloat(
                                                    item?.filledAmount *
                                                      item?.stoporderprice
                                                  ).toFixed(
                                                    pairDetails?.amount_decimal
                                                  )
                                                : parseFloat(
                                                    item?.filledAmount *
                                                      item?.price
                                                  ).toFixed(
                                                    pairDetails?.amount_decimal
                                                  )}
                                            </td>
                                            <td>
                                              <Button
                                                className="btn btn-primary-alta connectBtn"
                                                onClick={() =>
                                                  orderCancel(item)
                                                }
                                              >
                                                Cancel
                                              </Button>
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        {" "}
                                        {!checkAuth ? (
                                          <td colSpan="8">
                                            <Link
                                              to="/login"
                                              className="login_now margijg_auton"
                                            >
                                              Login to continue
                                            </Link>
                                          </td>
                                        ) : (
                                          <td
                                            colSpan="8"
                                            className="text-center"
                                          >
                                            {" "}
                                            No Open Orders found!
                                          </td>
                                        )}
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                                {activeOrderDatas &&
                                activeOrderDatas?.length > 0 ? (
                                  <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={currentPage}
                                    itemsCountPerPage={recordPerPage}
                                    totalItemsCount={totalactive}
                                    pageRangeDisplayed={pageRange}
                                    onChange={activePageChange}
                                  />
                                ) : (
                                  ""
                                )}
                              </section>
                            </article>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="OrderHistory" class="tab-pane fade">
                      <div className="paire_secrit responcive_table">
                        <div className="pair-table pb-0">
                          <div class="fixTableHead">
                            <article className="ordre-book open-text-left">
                              <section className="orderbook-header">
                                <table width="100%">
                                  <thead>
                                    <tr>
                                      <th>Date</th>
                                      <th>Pair </th>
                                      <th>Type </th>
                                      <th>Price </th>
                                      <th>Amount </th>
                                      <th>Total </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {tradeHistoryData?.length > 0 ? (
                                      tradeHistoryData.map((item, i) => {
                                        var datesHis = Moment(
                                          item?.created_at
                                        ).format("DD-MM-YYYY hh:mm:ss");
                                        return (
                                          <tr>
                                            <td>{datesHis} </td>
                                            <td>{item?.pair} </td>
                                            <td>{item?.type} </td>
                                            <td>
                                              {item?.type == "buy" ? (
                                                <span className="text-green">
                                                  {" "}
                                                  {parseFloat(
                                                    item?.askPrice
                                                  ).toFixed(
                                                    pairDetails?.price_decimal
                                                  )}{" "}
                                                </span>
                                              ) : (
                                                <span className="text-red">
                                                  {" "}
                                                  {parseFloat(
                                                    item?.askPrice
                                                  ).toFixed(
                                                    pairDetails?.price_decimal
                                                  )}{" "}
                                                </span>
                                              )}
                                            </td>
                                            <td>
                                              {" "}
                                              {parseFloat(
                                                item?.askAmount
                                              ).toFixed(
                                                pairDetails?.amount_decimal
                                              )}{" "}
                                            </td>
                                            <td>
                                              {parseFloat(item?.total).toFixed(
                                                pairDetails?.amount_decimal
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        {" "}
                                        {!checkAuth ? (
                                          <td colSpan="6">
                                            {/* <Button className="btn btn-primary-alta connectBtn mt-5"> */}
                                            <Link
                                              to="/login"
                                              className="login_now margijg_auton"
                                            >
                                              Login to continue
                                            </Link>
                                            {/* </Button> */}{" "}
                                          </td>
                                        ) : (
                                          <td
                                            colSpan="6"
                                            className="text-center"
                                          >
                                            {" "}
                                            No Order history found!
                                          </td>
                                        )}{" "}
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                                {tradeHistoryData &&
                                tradeHistoryData?.length > 0 ? (
                                  <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={currentPageHis}
                                    itemsCountPerPage={recordPerPageHist}
                                    totalItemsCount={totalHist}
                                    pageRangeDisplayed={pageRangeHis}
                                    onChange={orderhistoryactive}
                                  />
                                ) : (
                                  ""
                                )}
                              </section>
                            </article>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="CancelOrder" class="tab-pane fade">
                      <div className="paire_secrit responcive_table">
                        <div className="pair-table pb-0">
                          <div class="fixTableHead">
                            <article className="ordre-book open-text-left">
                              <section className="orderbook-header">
                                <table width="100%">
                                  <thead>
                                    <tr>
                                      <th>Date</th>
                                      <th>Pair</th>
                                      <th>Side </th>
                                      <th>Type </th>
                                      <th>Price </th>
                                      <th>Amount </th>
                                      <th>Total </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {cancelOrders?.length > 0 ? (
                                      cancelOrders.map((item, i) => {
                                        var total =
                                          item?.ordertype == "Stop"
                                            ? +item?.filledAmount *
                                              +item?.stoporderprice
                                            : +item?.filledAmount *
                                              +item?.price;
                                        return (
                                          <tr>
                                            <td>{item?.createddate} </td>
                                            <td>{item?.pairName} </td>
                                            <td>
                                              {item?.tradeType == "buy" ? (
                                                <span className="text-green">
                                                  {" "}
                                                  Buy{" "}
                                                </span>
                                              ) : (
                                                <span className="text-red">
                                                  {" "}
                                                  Sell{" "}
                                                </span>
                                              )}
                                            </td>
                                            <td>{item?.ordertype} </td>
                                            <td>
                                              <span className="text-green">
                                                {item?.ordertype == "Stop"
                                                  ? parseFloat(
                                                      item?.stoporderprice
                                                    ).toFixed(
                                                      pairDetails?.price_decimal
                                                    )
                                                  : parseFloat(
                                                      item?.price
                                                    ).toFixed(
                                                      pairDetails?.price_decimal
                                                    )}{" "}
                                              </span>
                                            </td>
                                            <td>
                                              {" "}
                                              {parseFloat(
                                                item?.filledAmount
                                              ).toFixed(
                                                pairDetails?.amount_decimal
                                              )}{" "}
                                            </td>
                                            <td>
                                              {parseFloat(total).toFixed(
                                                pairDetails?.price_decimal
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        {" "}
                                        {!checkAuth ? (
                                          <td colSpan="6">
                                            {/* <Button className="btn btn-primary-alta connectBtn mt-5"> */}
                                            <Link
                                              to="/login"
                                              className="login_now margijg_auton"
                                            >
                                              Login to continue
                                            </Link>
                                            {/* </Button> */}{" "}
                                          </td>
                                        ) : (
                                          <td
                                            colSpan="6"
                                            className="text-center"
                                          >
                                            {" "}
                                            No Cancel order found!{" "}
                                          </td>
                                        )}
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                                {cancelOrders && cancelOrders.length > 0 ? (
                                  <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={currentPagecan}
                                    itemsCountPerPage={recordPerPagecan}
                                    totalItemsCount={totalcan}
                                    pageRangeDisplayed={pageRangecan}
                                    onChange={cancelPageChange}
                                  />
                                ) : (
                                  ""
                                )}
                              </section>
                            </article>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div class="tab-content min-hight">
                  <div id="Market_Trade" class="tab-pane fade in active show">
                    <p className="registyre_wes">
                      <a href="">Log In</a> or<a href=""> Register Now</a> to
                      trade
                    </p>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="mobile_responsive_footer">
            <div className="fixed_footer">
              <Button
                className="buymobile"
                onClick={() => toggleClass("myDiv")}
              >
                buy
              </Button>
              <Button
                className="sellmobile"
                onClick={() => toggleClass("myDiv")}
              >
                Sell
              </Button>
            </div>
            <div
              id="myDiv"
              className={
                isToggled ? "contaniner_buy_sect active" : "contaniner_buy_sect"
              }
            >
              <div className="active_clos">
                <div className="button_close">
                  <button
                    onClick={() => removeClass("myDiv")}
                    className="remove_clas"
                  >
                    <i class="ri-close-fill"></i>
                  </button>
                </div>
                <div className="buyseel_monile_form">
                  <ul class="nav nav-tabs">
                    <li class="active">
                      <a
                        data-toggle="tab"
                        href="#Buymobile"
                        className="Buymobile active"
                      >
                        Buy
                      </a>
                    </li>
                    <li>
                      <a
                        data-toggle="tab"
                        href="#Sellmobile"
                        className="Sellmobile"
                      >
                        Sell
                      </a>
                    </li>
                  </ul>
                  {/* <div class="tab-content">
                  <div id="Buymobile" class="tab-pane fade in active show">
                    <div className="form_buy">
                      <ul class="nav nav-tabs">
                        <li class="active">
                          <a
                            data-toggle="tab"
                            href="#Limitmobile"
                            className="active"
                          >
                            Limit
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#Marketmobile">
                            Market
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#Stopmarket">
                            Stop-limit
                          </a>
                        </li>
                      </ul>
                      <div class="tab-content">
                        <div
                          id="Limitmobile"
                          class="tab-pane fade in active show"
                        >
                          <form className="padig-fomr">
                            <p className="avail_balace">
                              Avbl -<span>0.009 USDT</span>
                            </p>
                            <div class="form-group">
                              <div className="form_flex_content">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">Price</div>
                                </div>
                                <input
                                  type="email"
                                  class="form-control"
                                  id="email"
                                />
                                <div class="input-group-prepend">
                                  <div class="input-group-text">USDT</div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <div className="form_flex_content">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">Amount</div>
                                </div>
                                <input
                                  type="email"
                                  class="form-control"
                                  id="email"
                                />
                                <div class="input-group-prepend">
                                  <div class="input-group-text">BTC</div>
                                </div>
                              </div>
                            </div>
                            <ul class="nav nav-tabs nav_tabs_ne percent mobile_onlit">
                              <li class="active">
                                <a data-toggle="tab" href="#home">
                                  25%
                                </a>
                              </li>
                              <li>
                                <a data-toggle="tab" href="#menu1">
                                  50%
                                </a>
                              </li>
                              <li>
                                <a data-toggle="tab" href="#menu2">
                                  75%
                                </a>
                              </li>
                              <li>
                                <a data-toggle="tab" href="#menu3">
                                  100%
                                </a>
                              </li>
                            </ul>
                            <button
                              type="submit"
                              class="btn btn-default button_trade buy"
                            >
                              Buy
                            </button>
                          </form>
                        </div>
                        <div id="Marketmobile" class="tab-pane fade">
                          <h3>Menu 1</h3>
                          <p>Some content in menu 1.</p>
                        </div>
                        <div id="Stopmarket" class="tab-pane fade">
                          <h3>Menu 2</h3>
                          <p>Some content in menu 2.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="Sellmobile" class="tab-pane fade">
                    <h3>Menu 1</h3>
                    <p>Some content in menu 1.</p>
                  </div>
                </div> */}

                  <div class="tab-content">
                    <div id="Buymobile" class="tab-pane fade in active show">
                      <div className="form_buy">
                        <ul class="nav nav-tabs">
                          <li class="active">
                            <a
                              data-toggle="tab"
                              href="#Limitmobile"
                              className="active"
                            >
                              Limit
                            </a>
                          </li>
                          <li>
                            <a data-toggle="tab" href="#Marketmobile">
                              Market
                            </a>
                          </li>
                          <li>
                            <a data-toggle="tab" href="#Stopmarket">
                              Stop-limit
                            </a>
                          </li>
                        </ul>
                        <div class="tab-content">
                          <div
                            id="Limitmobile"
                            class="tab-pane fade in active show"
                          >
                            {/* <!-- <form className="padig-fomr"> --> */}
                            <p className="avail_balace">
                              Avbl :
                              <span>
                                {tobalance} {pairDetails?.to_symbol}
                              </span>
                            </p>
                            <div class="form-group">
                              <div className="form_flex_content">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">Price</div>
                                </div>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Price"
                                  value={price}
                                  onChange={handleChange}
                                  name="price"
                                />
                                <div class="input-group-prepend">
                                  <div class="input-group-text">
                                    {pairDetails?.to_symbol}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <div className="form_flex_content">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">Amount</div>
                                </div>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="amount"
                                  value={amount}
                                  onChange={handleChange}
                                />
                                <div class="input-group-prepend">
                                  <div class="input-group-text">
                                    {pairDetails?.from_symbol}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <ul class="nav nav-tabs nav_tabs_ne percent mobile_onlit">
                              <li class="active">
                                <a
                                  data-toggle="tab"
                                  href="#home"
                                  onClick={() =>
                                    buy_sell_percentage("25", "buy")
                                  }
                                  className={btntrade1}
                                >
                                  25%
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tab"
                                  href="#menu1"
                                  onClick={() =>
                                    buy_sell_percentage("50", "buy")
                                  }
                                  className={btntrade2}
                                >
                                  50%
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tab"
                                  href="#menu2"
                                  onClick={() =>
                                    buy_sell_percentage("75", "buy")
                                  }
                                  className={btntrade3}
                                >
                                  75%
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tab"
                                  href="#menu3"
                                  onClick={() =>
                                    buy_sell_percentage("100", "buy")
                                  }
                                  className={btntrade4}
                                >
                                  100%
                                </a>
                              </li>
                            </ul>
                            <div class="form-group">
                              <div className="form_flex_content">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">Total</div>
                                </div>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="total"
                                  value={total}
                                  onChange={handleChangeTotal}
                                />
                                <div class="input-group-prepend">
                                  <div class="input-group-text">
                                    {pairDetails?.to_symbol}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {!checkAuth ? (
                              <Link to="/login" className="login_now">
                                Login to continue
                              </Link>
                            ) : orderloaderref.current ? (
                              <button className="btn btn-default button_trade buy">
                                Loading
                              </button>
                            ) : (
                              <button
                                className="btn btn-default button_trade buy"
                                onClick={() => orderPlace("Limit", "buy")}
                              >
                                Buy {pairDetails?.from_symbol}
                              </button>
                            )}
                            {/* <!-- </form> --> */}
                          </div>
                          <div id="Marketmobile" class="tab-pane fade">
                            <p className="avail_balace">
                              Avbl :
                              <span>
                                {tobalance} {pairDetails?.to_symbol}
                              </span>
                            </p>
                            <div class="form-group">
                              <div className="form_flex_content">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">Price</div>
                                </div>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Price"
                                  value={price}
                                  onChange={handleChange}
                                  name="price"
                                  disabled="true"
                                />
                                <div class="input-group-prepend">
                                  <div class="input-group-text">
                                    {pairDetails?.to_symbol}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <div className="form_flex_content">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">Amount</div>
                                </div>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="amount"
                                  value={amount}
                                  onChange={handleChange}
                                />
                                <div class="input-group-prepend">
                                  <div class="input-group-text">
                                    {pairDetails?.from_symbol}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <ul class="nav nav-tabs nav_tabs_ne percent mobile_onlit">
                              <li class="active">
                                <a
                                  data-toggle="tab"
                                  href="#home"
                                  onClick={() =>
                                    buy_sell_percentage("25", "buy")
                                  }
                                  className={btntrade1}
                                >
                                  25%
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tab"
                                  href="#menu1"
                                  onClick={() =>
                                    buy_sell_percentage("50", "buy")
                                  }
                                  className={btntrade2}
                                >
                                  50%
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tab"
                                  href="#menu2"
                                  onClick={() =>
                                    buy_sell_percentage("75", "buy")
                                  }
                                  className={btntrade3}
                                >
                                  75%
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tab"
                                  href="#menu3"
                                  onClick={() =>
                                    buy_sell_percentage("100", "buy")
                                  }
                                  className={btntrade4}
                                >
                                  100%
                                </a>
                              </li>
                            </ul>
                            <div class="form-group">
                              <div className="form_flex_content">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">Total</div>
                                </div>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="total"
                                  value={total}
                                  onChange={handleChangeTotal}
                                />
                                <div class="input-group-prepend">
                                  <div class="input-group-text">
                                    {pairDetails?.to_symbol}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {!checkAuth ? (
                              <Link to="/login" className="login_now">
                                Login to continue
                              </Link>
                            ) : orderloaderref.current ? (
                              <button className="btn btn-default button_trade buy">
                                Loading
                              </button>
                            ) : (
                              <button
                                className="btn btn-default button_trade buy"
                                onClick={() => orderPlace("Market", "buy")}
                              >
                                Buy {pairDetails?.from_symbol}
                              </button>
                            )}
                          </div>
                          <div id="Stopmarket" class="tab-pane fade">
                            <p className="avail_balace">
                              Avbl :
                              <span>
                                {tobalance} {pairDetails?.to_symbol}
                              </span>
                            </p>
                            <div class="form-group">
                              <div className="form_flex_content">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">
                                    Limit Price
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Price"
                                  value={stop_price}
                                  onChange={handleChange}
                                  name="stop_price"
                                />
                                <div class="input-group-prepend">
                                  <div class="input-group-text">
                                    {pairDetails?.to_symbol}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <div className="form_flex_content">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">Stop Price</div>
                                </div>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Price"
                                  value={price}
                                  onChange={handleChange}
                                  name="price"
                                  disabled="true"
                                />
                                <div class="input-group-prepend">
                                  <div class="input-group-text">
                                    {pairDetails?.to_symbol}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <div className="form_flex_content">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">Amount</div>
                                </div>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="amount"
                                  value={amount}
                                  onChange={handleChange}
                                />
                                <div class="input-group-prepend">
                                  <div class="input-group-text">
                                    {pairDetails?.from_symbol}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <ul class="nav nav-tabs nav_tabs_ne percent mobile_onlit">
                              <li class="active">
                                <a
                                  data-toggle="tab"
                                  href="#home"
                                  onClick={() =>
                                    buy_sell_percentage("25", "buy")
                                  }
                                  className={btntrade1}
                                >
                                  25%
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tab"
                                  href="#menu1"
                                  onClick={() =>
                                    buy_sell_percentage("50", "buy")
                                  }
                                  className={btntrade2}
                                >
                                  50%
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tab"
                                  href="#menu2"
                                  onClick={() =>
                                    buy_sell_percentage("75", "buy")
                                  }
                                  className={btntrade3}
                                >
                                  75%
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tab"
                                  href="#menu3"
                                  onClick={() =>
                                    buy_sell_percentage("100", "buy")
                                  }
                                  className={btntrade4}
                                >
                                  100%
                                </a>
                              </li>
                            </ul>
                            <div class="form-group">
                              <div className="form_flex_content">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">Total</div>
                                </div>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="total"
                                  value={total}
                                  onChange={handleChangeTotal}
                                />
                                <div class="input-group-prepend">
                                  <div class="input-group-text">
                                    {pairDetails?.to_symbol}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {!checkAuth ? (
                              <Link to="/login" className="login_now">
                                Login to continue
                              </Link>
                            ) : orderloaderref.current ? (
                              <button className="btn btn-default button_trade buy">
                                Loading
                              </button>
                            ) : (
                              <button
                                className="btn btn-default button_trade buy"
                                onClick={() => orderPlace("Stop", "buy")}
                              >
                                Buy {pairDetails?.from_symbol}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="Sellmobile" class="tab-pane fade">
                      <div className="form_buy">
                        <ul class="nav nav-tabs">
                          <li class="active">
                            <a
                              data-toggle="tab"
                              href="#Limitmobilesell"
                              className="active"
                            >
                              Limit
                            </a>
                          </li>
                          <li>
                            <a data-toggle="tab" href="#Marketmobilesell">
                              Market
                            </a>
                          </li>
                          <li>
                            <a data-toggle="tab" href="#Stopmarketsell">
                              Stop-limit
                            </a>
                          </li>
                        </ul>
                        <div class="tab-content">
                          <div
                            id="Limitmobilesell"
                            class="tab-pane fade in active show"
                          >
                            {/* <!-- <form className="padig-fomr"> --> */}
                            <p className="avail_balace">
                              Avbl :
                              <span>
                                {frombalance} {pairDetails?.from_symbol}
                              </span>
                            </p>
                            <div class="form-group">
                              <div className="form_flex_content">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">Price</div>
                                </div>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Price"
                                  value={sellPrice ? sellPrice : sellprice}
                                  name="sellprice"
                                  onChange={handleChange}
                                />
                                <div class="input-group-prepend">
                                  <div class="input-group-text">
                                    {pairDetails?.to_symbol}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <div className="form_flex_content">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">Amount</div>
                                </div>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="sellamount"
                                  value={sellAmount ? sellAmount : sellamount}
                                  onChange={handleChange}
                                />
                                <div class="input-group-prepend">
                                  <div class="input-group-text">
                                    {pairDetails?.from_symbol}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <ul class="nav nav-tabs nav_tabs_ne percent mobile_onlit">
                              <li class="active">
                                <a
                                  data-toggle="tab"
                                  href="#home"
                                  className={sellbtntrade1}
                                  onClick={() =>
                                    buy_sell_percentage("25", "sell")
                                  }
                                >
                                  25%
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tab"
                                  href="#menu1"
                                  onClick={() =>
                                    buy_sell_percentage("50", "sell")
                                  }
                                  className={sellbtntrade2}
                                >
                                  50%
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tab"
                                  href="#menu2"
                                  className={sellbtntrade3}
                                  onClick={() =>
                                    buy_sell_percentage("75", "sell")
                                  }
                                >
                                  75%
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tab"
                                  href="#menu3"
                                  onClick={() =>
                                    buy_sell_percentage("100", "sell")
                                  }
                                  className={sellbtntrade4}
                                >
                                  100%
                                </a>
                              </li>
                            </ul>
                            <div class="form-group">
                              <div className="form_flex_content">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">Total</div>
                                </div>
                                <input
                                  type="text"
                                  class="form-control"
                                  value={sellTotal ? sellTotal : selltotal}
                                  name="total"
                                  onChange={handleChangeTotal}
                                />
                                <div class="input-group-prepend">
                                  <div class="input-group-text">
                                    {pairDetails?.to_symbol}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {!checkAuth ? (
                              <Link to="/login" className="login_now">
                                Login to continue
                              </Link>
                            ) : sellorderloaderref.current ? (
                              <button className="btn btn-default button_trade sell">
                                Loading
                              </button>
                            ) : (
                              <button
                                className="btn btn-default button_trade sell"
                                onClick={() => orderPlace("Limit", "sell")}
                              >
                                Sell {pairDetails?.from_symbol}
                              </button>
                            )}
                            {/* <!-- </form> --> */}
                          </div>
                          <div id="Marketmobilesell" class="tab-pane fade">
                            <p className="avail_balace">
                              Avbl :
                              <span>
                                {frombalance} {pairDetails?.from_symbol}
                              </span>
                            </p>
                            <div class="form-group">
                              <div className="form_flex_content">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">Price</div>
                                </div>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Price"
                                  value={sellPrice ? sellPrice : sellprice}
                                  name="sellprice"
                                  onChange={handleChange}
                                  disabled="true"
                                />
                                <div class="input-group-prepend">
                                  <div class="input-group-text">
                                    {pairDetails?.to_symbol}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <div className="form_flex_content">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">Amount</div>
                                </div>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="sellamount"
                                  value={sellAmount ? sellAmount : sellamount}
                                  onChange={handleChange}
                                />
                                <div class="input-group-prepend">
                                  <div class="input-group-text">
                                    {pairDetails?.from_symbol}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <ul class="nav nav-tabs nav_tabs_ne percent mobile_onlit">
                              <li class="active">
                                <a
                                  data-toggle="tab"
                                  href="#home"
                                  className={sellbtntrade1}
                                  onClick={() =>
                                    buy_sell_percentage("25", "sell")
                                  }
                                >
                                  25%
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tab"
                                  href="#menu1"
                                  onClick={() =>
                                    buy_sell_percentage("50", "sell")
                                  }
                                  className={sellbtntrade2}
                                >
                                  50%
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tab"
                                  href="#menu2"
                                  className={sellbtntrade3}
                                  onClick={() =>
                                    buy_sell_percentage("75", "sell")
                                  }
                                >
                                  75%
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tab"
                                  href="#menu3"
                                  onClick={() =>
                                    buy_sell_percentage("100", "sell")
                                  }
                                  className={sellbtntrade4}
                                >
                                  100%
                                </a>
                              </li>
                            </ul>
                            <div class="form-group">
                              <div className="form_flex_content">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">Total</div>
                                </div>
                                <input
                                  type="text"
                                  class="form-control"
                                  value={sellTotal ? sellTotal : selltotal}
                                  name="total"
                                  onChange={handleChangeTotal}
                                />
                                <div class="input-group-prepend">
                                  <div class="input-group-text">
                                    {pairDetails?.to_symbol}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {!checkAuth ? (
                              <Link to="/login" className="login_now">
                                Login to continue
                              </Link>
                            ) : sellorderloaderref.current ? (
                              <button className="btn btn-default button_trade sell">
                                Loading
                              </button>
                            ) : (
                              <button
                                className="btn btn-default button_trade sell"
                                onClick={() => orderPlace("Market", "sell")}
                              >
                                Sell {pairDetails?.from_symbol}
                              </button>
                            )}
                          </div>
                          <div id="Stopmarketsell" class="tab-pane fade">
                            <p className="avail_balace">
                              Avbl :
                              <span>
                                {frombalance} {pairDetails?.from_symbol}
                              </span>
                            </p>
                            <div class="form-group">
                              <div className="form_flex_content">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">
                                    Limit Price
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Price"
                                  value={sellstop_price}
                                  onChange={handleChange}
                                  name="sellstop_price"
                                />
                                <div class="input-group-prepend">
                                  <div class="input-group-text">
                                    {pairDetails?.to_symbol}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <div className="form_flex_content">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">Stop Price</div>
                                </div>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Price"
                                  value={sellPrice ? sellPrice : sellprice}
                                  name="sellprice"
                                  onChange={handleChange}
                                />
                                <div class="input-group-prepend">
                                  <div class="input-group-text">
                                    {pairDetails?.to_symbol}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <div className="form_flex_content">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">Amount</div>
                                </div>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="sellamount"
                                  value={sellAmount ? sellAmount : sellamount}
                                  onChange={handleChange}
                                />
                                <div class="input-group-prepend">
                                  <div class="input-group-text">
                                    {pairDetails?.from_symbol}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <ul class="nav nav-tabs nav_tabs_ne percent mobile_onlit">
                              <li class="active">
                                <a
                                  data-toggle="tab"
                                  href="#home"
                                  className={sellbtntrade1}
                                  onClick={() =>
                                    buy_sell_percentage("25", "sell")
                                  }
                                >
                                  25%
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tab"
                                  href="#menu1"
                                  oonClick={() =>
                                    buy_sell_percentage("50", "sell")
                                  }
                                  className={sellbtntrade2}
                                >
                                  50%
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tab"
                                  href="#menu2"
                                  className={sellbtntrade3}
                                  onClick={() =>
                                    buy_sell_percentage("75", "sell")
                                  }
                                >
                                  75%
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tab"
                                  href="#menu3"
                                  onClick={() =>
                                    buy_sell_percentage("100", "sell")
                                  }
                                  className={sellbtntrade4}
                                >
                                  100%
                                </a>
                              </li>
                            </ul>
                            <div class="form-group">
                              <div className="form_flex_content">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">Total</div>
                                </div>
                                <input
                                  type="text"
                                  class="form-control"
                                  value={sellTotal ? sellTotal : selltotal}
                                  name="total"
                                  onChange={handleChangeTotal}
                                />
                                <div class="input-group-prepend">
                                  <div class="input-group-text">
                                    {pairDetails?.to_symbol}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {!checkAuth ? (
                              <Link to="/login" className="login_now">
                                Login to continue
                              </Link>
                            ) : sellorderloaderref.current ? (
                              <button className="btn btn-default button_trade sell">
                                Loading
                              </button>
                            ) : (
                              <button
                                className="btn btn-default button_trade sell"
                                onClick={() => orderPlace("Stop", "sell")}
                              >
                                Sell {pairDetails?.from_symbol}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </>
        )}
        </main>
      </div>
    </>
  );
}

export default Home;
