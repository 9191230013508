import React from "react";
import {Button} from "@mui/material";

function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                
                <div className="col-lg-3">
                  <div className="link_section">
                  <h4>About</h4>
                  
                    <ul>
                      <li>
                        <a href="/about-us" target="_blank">About</a>
                      </li>
                      <li>
                        <a href="">Press</a>
                      </li>

                      <li>
                        <a href="">Careers</a>
                      </li>
                      <li>
                        <a href="">Business Contacts</a>
                      </li>
                      <li>
                        <a href="">Community</a>
                      </li>
                      <li>
                        <a href="">Adverb Blog</a>
                      </li>
                      <li>
                        <a href="">Legal</a>
                      </li>
                      <li>
                        <a href="">Terms</a>
                      </li>
                      <li>
                        <a href="/privacy-policy" target="_blank">Privacy</a>
                      </li>
                      <li>
                        <a href="">News</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="link_section">
                  <h4>Products</h4>

                    <ul>
                      <li>
                        <a href="">Exchange</a>
                      </li>
                      <li>
                        <a href="">Academy</a>
                      </li>

                      <li>
                        <a href="">Binance Live</a>
                      </li>
                      <li>
                        <a href="">Charity</a>
                      </li>
                      <li>
                        <a href="">Card</a>
                      </li>
                      <li>
                        <a href="">Launchpad</a>
                      </li>
                      <li>
                        <a href="">Trust Wallet</a>
                      </li>
                      <li>
                        <a href="">Trust Wallet</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="link_section">
                    <h4>Service</h4>
                    <ul>
                      <li>
                        <a href="">Downloads</a>
                      </li>
                      <li>
                        <a href="">Desktop Application</a>
                      </li>

                      <li>
                        <a href="">Buy Crypto</a>
                      </li>
                      <li>
                        <a href="">Referral</a>
                      </li>
                      
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="link_section">
                    <h4>Support</h4>
                    <ul>
                      <li>
                        <a href="/about-us" target="_blank">About Us</a>
                      </li>
                      <li>
                        <a href="/faq" target="_blank">FAQ</a>
                      </li>
                      <li>
                        <a href="">Careers</a>
                      </li>

                      <li>
                        <a href="">Blog</a>
                      </li>
                      <li>
                        <a href="">Pricing</a>
                      </li>
                    </ul>
                  </div>
                </div>
                
              </div>
            </div>
            <div className="col-lg-4">
              <div className="link_section">
                <h4>Community</h4>
               
                <ul className="flex_sss_cloe">
                  <li>
                    <a href="https://twitter.com/adverbcoins" target="_blank"><i class="ri-twitter-fill"></i></a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/adverbcoin.io/" target="_blank"><i class="ri-instagram-fill"></i></a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/adverbcoin.io/" target="_blank"><i class="ri-facebook-fill"></i></a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/channel/UCmeX63AEdhRfoJ0c_0I7F8g" target="_blank"><i class="ri-youtube-fill"></i></a>
                  </li>
                  <li>
                    <a href="https://t.me/adverb_community" target="_blank"><i class="ri-telegram-fill"></i></a>
                  </li>
                  <li>
                    <a href="https://medium.com/@adverbcoin" target="_blank"><i class="ri-medium-fill"></i></a>
                  </li>
                  <li>
                    <a href="mailto:support@adverbcoin.io" target="_blank"><i class="ri-mail-fill"></i></a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="copywrigt">
                <p>© 2023 Adverb. All rights reserved</p>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div id="myModal" class="modal fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Buy Ticket</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div class="modal-body">
              <div className="ticket_section">
                <div className="input_form_tivket">
                  <p>
                    Buy<span>Ticket</span>
                  </p>
                  <input type="number" placeholder="" />
                  <div className="amount_bust">~ 0.001 BUSD</div>
                </div>
                <div className="amount_content">
                  <p>
                    Cost(BUSD)<span>0.001 BUSD</span>
                  </p>
                  <div className="youpay">
                    <p>
                      You Pay <span> ~ 0 BUSD</span>
                    </p>
                  </div>
                </div>
                <Button className="header_btn">Connect Wallet</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
