import React, {useEffect} from "react";
import Header from "./Header";
import {Button} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Footer from "./Footer";
// import Bgvedio from "../Image/globe-fiat-block.mp4";
import BannerUI from "./Banner";
import {toast} from "react-toastify";
import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";
import {getMethod} from "../core/service/common.api";
import moment from "moment";
import Pagination from "react-js-pagination";
import "semantic-ui-css/semantic.min.css";
import {Dropdown} from "semantic-ui-react";
import useState from "react-usestateref";
import Sidebar from "./Sidebarlink";

import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterIcon,
  TwitterShareButton,
  TelegramIcon,
  TelegramShareButton,
  InstapaperIcon,
  InstapaperShareButton,
} from "react-share";

function Home() {
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const friendOptions = [
    {
      key: "BTC",
      text: "BTC",
      value: "BTC",
      image: {
        avatar: true,
        src: "https://react.semantic-ui.com/images/avatar/small/jenny.jpg",
      },
    },
    {
      key: "ETH",
      text: "ETH",
      value: "ETH",
      image: {
        avatar: true,
        src: "https://react.semantic-ui.com/images/avatar/small/elliot.jpg",
      },
    },
  ];

  const [fromTab, setFromTab] = useState([]);
  const [toTab, setToTab] = useState([]);
  const [fromCurrency, setfromCurrency, fromref] = useState("ADVB");
  const [toCurrency, settoCurrency, toref] = useState("USDT");
  const [appendFromData, setappendFromData, appendFromDataref] = useState(0);
  const [appendToData, setappendFToData, appendToDataref] = useState("");
  const [fromcurrencyImage, setFromcurrencyImage] = useState("");
  const [tocurrencyImage, setTocurrencyImage] = useState("");
  const [fromAmount, setfromAmount, fromAmountref] = useState(0);
  const [toAmount, settoAmount, toAmountref] = useState(0);
  const [minMax, setMinMax] = useState(10);
  const [price, setPrice, priceref] = useState(0);
  const [estimateFee, setEstimationFee] = useState(0);
  const [totatlAmount, setTotalAmount] = useState(0);
  const [sessionHistory, setsessionHistory] = useState([]);
  const [totalPage, setTotalpages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ButtonLoader, setButtonLoader] = useState(false);
  const [valueFA, setvalueFA, valueFAref] = useState(0);
  const [maxValue, setmaxValue] = useState(false);
  const [swapHistory, setSwapHistory] = useState([]);
  const [loader, Setloader, loaderref] = useState(false);
  const [allCurrencyFiat, setfromCurrencyRef, fromCurrencyRef] = useState([]);
  const [toCurrencyRefs, setToCurrencyRef, toCurrencyRef] = useState([]);
  const [advbprice, setadvbPrice, advbpriceref] = useState(0);
  const [advbnewprice, setadvbnewPrice, advbnewpriceref] = useState(0);

  const recordPerPage = 5;
  const pageRange = 5;

  useEffect(() => {
    getUserbalance();
    swaphistory();
  }, [0]);
  const onsearch = async (e) => {};
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "transparent",
      borderColor: state.isFocused ? "transparent" : "transparent",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "transparent" : "transparent",
      },
    }),
  };
  const onSelect = async (option, type, o) => {
    if (type == "fromTab") {
      var findIndexing = fromTab.findIndex(
        (x) => x.currencySymbol == option.key
      );
      if (findIndexing != -1) {
        setappendFromData(fromTab[findIndexing]);
        setFromcurrencyImage(fromTab[findIndexing].image);
        setfromCurrency(fromTab[findIndexing].currencySymbol);
        swapPrice();
      }
    } else if (type == "toTab") {
      var findIndexingTo = fromTab.findIndex(
        (x) => x.currencySymbol == option.key
      );
      if (findIndexing != -1) {
        settoCurrency(fromTab[findIndexingTo].currencySymbol);
        setappendFToData(fromTab[findIndexingTo]);
        setTocurrencyImage(fromTab[findIndexingTo].image);
        swapPrice();
      }
    }
  };
  const defaulatCurrFiat = fromCurrencyRef.current[1];
  const defaulatCurrTo = toCurrencyRef.current[3];

  const setAmount = async (value, type) => {
    setvalueFA(value);
    if (value == "0") {
      setmaxValue(false);
    }
    try {
      type == "fromAmount" ? setfromAmount(value) : settoAmount(value);
      var obj = {
        from: appendFromDataref.current.currencySymbol,
        to: appendToDataref.current.currencySymbol,
        // from: fromref.current != undefined ? fromref.current : "ADVB",
        // to: toref.current != undefined ? toref.current : "USDT",
      };
      var data = {
        apiUrl: apiService.currencyConversion,
        payload: obj,
      };
      if (fromCurrency != "" && toCurrency != "") {
        var resp = await postMethod(data);
        console.log("swap response===", resp);
        if (resp.status) {
          setPrice(resp.Message.price);
          //setadvbPrice(resp.Message.advb_pair_price);
          setadvbnewPrice(resp.Message.advb_new_price);
          var fee = +value * (+appendFromDataref.current.swapFee / 100);
          console.log("swap fee===", fee);
          setEstimationFee(fee);
          var total = +value + fee;
          console.log("swap total===", total);
          setTotalAmount(parseFloat(total).toFixed(8));
          if (type == "fromAmount") {
            var amount = Number(priceref.current) * Number(value);
            console.log("swap call from amount ===", amount);
            setfromAmount(value);
            console.log("swap call from value ===", value);
            settoAmount(parseFloat(amount).toFixed(8));
          } else if (type == "toAmount") {
            var amount = Number(value) / Number(priceref.current);
            console.log("swap call to amount ===", amount);
            setfromAmount(value);
            console.log("swap call to value ===", value);
            settoAmount(parseFloat(value).toFixed(8));
          }
        }
      } else {
      }
      if (fromref.current == toref.current) {
        toast.error("don't Allowed same Currency");
      }
    } catch (error) {}
  };

  const swapAmount = async () => {
    try {
      if (
        appendFromDataref.current.currencySymbol != undefined &&
        appendToDataref.current.currencySymbol != undefined
      ) {
        // if((appendFromDataref.current.currencySymbol == "ADVB" && appendToDataref.current.currencySymbol == "ADVB(new)") ||  ((appendFromDataref.current.currencySymbol != "ADVB(new)" && appendFromDataref.current.currencySymbol != "ADVB") && (appendToDataref.current.currencySymbol != "ADVB"))  || ((appendFromDataref.current.currencySymbol == "ADVB(new)" && appendFromDataref.current.currencySymbol != "ADVB") && (appendToDataref.current.currencySymbol != "ADVB")))
        // {
        if (
          (appendFromDataref.current.currencySymbol == "ADVB" &&
            appendToDataref.current.currencySymbol == "ADVB(new)") ||
          (appendFromDataref.current.currencySymbol != "ADVB(new)" &&
            appendFromDataref.current.currencySymbol != "ADVB" &&
            appendToDataref.current.currencySymbol != "ADVB")
        ) {
          if (
            appendFromDataref.current.currencySymbol !=
            appendToDataref.current.currencySymbol
          ) {
            if (fromAmountref.current > 0 && toAmountref.current > 0) {
              var obj = {
                from: appendFromDataref.current.currencySymbol,
                to: appendToDataref.current.currencySymbol,
                from_id: appendFromDataref.current.currid,
                to_id: appendToDataref.current.currid,
                fromAmount: +fromAmountref.current,
                toAmount: +toAmountref.current,
                fee: +estimateFee,
                withFee: +totatlAmount,
                currentPrice: +priceref.current,
              };
              var data = {
                apiUrl: apiService.swapping,
                payload: obj,
              };

              setButtonLoader(true);
              var resp = await postMethod(data);
              setButtonLoader(false);
              if (resp.status) {
                swaphistory(1);
                setfromAmount(0);
                settoAmount(0);
                getUserbalance();
                toast.success(resp.Message);
              } else {
                swaphistory(1);
                toast.error(resp.Message);
              }
            } else {
              toast.error("Please enter amount");
            }
          } else {
            toast.error("Same currency should not allow the swapping");
          }
        } else {
          toast.error("Swapping is not allowed for these currencies");
        }
      } else {
        toast.error("Please choose the swapping currencies");
      }
    } catch (error) {}
  };

  const swaphistory = async (page) => {
    try {
      var payload = {
        perpage: 10,
        page: page,
      };
      var data = {
        apiUrl: apiService.swappingHistory,
        payload: payload,
      };

      var resp = await postMethod(data);
      if (resp.status) {
        setSwapHistory(resp.data.data);
        setTotalpages(resp.data.total);
      }
    } catch (error) {}
  };

  const handlePageChange = (pageNumber) => {
    swaphistory(pageNumber);
    setCurrentPage(pageNumber);
  };

  const maxBalance = async () => {
    try {
      setmaxValue(true);
      var fee =
        (+appendFromDataref.current.currencyBalance *
          +appendFromDataref.current.swapFee) /
        100;
      var amount = appendFromDataref.current.currencyBalance - fee;
      setfromAmount(amount.toFixed(8));
      setAmount(amount.toFixed(8), "fromAmount");
    } catch (error) {}
  };

  const swapPrice = async () => {
    try {
      var obj = {
        from: fromref.current != undefined ? fromref.current : "ADVB",
        to: toref.current != undefined ? toref.current : "USDT",
      };
      var data = {
        apiUrl: apiService.currencyConversion,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        // var balance = +resp.Message * valueFA;
        var balance =
          fromAmountref.current == 0
            ? 0
            : +resp.Message.price * fromAmountref.current;
        console.log("swap balance===", balance);
        setAmount(balance.toFixed(8));
        setPrice(resp.Message.price);
        //setadvbPrice(resp.Message.advb_pair_price);
        setadvbnewPrice(resp.Message.advb_new_price);
      }
    } catch (error) {}
  };

  const getUserbalance = async () => {
    Setloader(true);
    var data = {
      apiUrl: apiService.getUserBalanceSwap,
    };
    var resp = await getMethod(data);

    if (resp.status) {
      Setloader(false);
      setFromTab(resp.data);
      setToTab(resp.data);
      var currArrayFiat = [{value: "all", label: ""}];
      var data = resp.data;
      if(data.length > 0)
      {
        for (var i = 0; i < data.length; i++) {
            var obj = {
              value: data[i].currid,
              // label: data[i].currencySymbol,
              key: data[i].currencySymbol,
              text: data[i].currencySymbol,
              image: {
                avatar: true,
                src: data[i].image,
              },
            };
            currArrayFiat.push(obj);
        }
      setToCurrencyRef(currArrayFiat);
      setfromCurrencyRef(currArrayFiat);

      // onSelect(resp.data[0], "fromTab");
      // onSelect(resp.data[4], "toTab");

      setappendFromData(resp.data[0]);
      setFromcurrencyImage(resp.data[0].image);
      setfromCurrency(resp.data[0].currencySymbol);

      settoCurrency(resp.data[1].currencySymbol);
      setappendFToData(resp.data[1]);
      setTocurrencyImage(resp.data[1].image);
      swapPrice();
      }
     
    } else {
    }
  };

  const handleOnChange_from = (e, data) => {
    var findIndexing = fromTab.findIndex((x) => x.currid == data.value);
    if (findIndexing != -1) {
      setappendFromData(fromTab[findIndexing]);
      setFromcurrencyImage(fromTab[findIndexing].image);
      setfromCurrency(fromTab[findIndexing].currencySymbol);
      swapPrice();
    }
  };

  const handleOnChange_to = (e, data) => {
    var findIndexingTo = fromTab.findIndex((x) => x.currid == data.value);
    if (findIndexingTo != -1) {
      settoCurrency(fromTab[findIndexingTo].currencySymbol);
      setappendFToData(fromTab[findIndexingTo]);
      setTocurrencyImage(fromTab[findIndexingTo].image);
      swapPrice();
    }
  };

  return (
    <div className="header">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <Sidebar />
          </div>
          <div className="css-1h690ep">
      <main className="main_heinght">
        {loaderref.current == true ? (
          <div class="spinner css-ezm1fk">
            <div class="css-1qoumk2"></div>
            <div class="css-1v19680"></div>
            <div class="css-1v90jie"></div>
            <div class="css-19p0rvp"></div>
          </div>
        ) : (
          <>
            <section className="section_projec bg_trrrr w-100">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-10">
                    <div className="card_trde">
                      <div className="row">
                        <div className="col-lg-7">
                          <div className="swap_content">
                            <h2>Swap</h2>
                            <div className="input_rexe">
                              <p>
                                From{" "}
                                <span>
                                  available<b> {appendFromDataref.current == ""
                                          ? "0.0000"
                                          : parseFloat(
                                              appendFromDataref.current.currencyBalance
                                            ).toFixed(8)}{" "}</b>
                                          {appendFromDataref.current.currencySymbol}
                                </span>
                              </p>
                              <div className="input_coin_">
                                {maxValue == true ? (
                                          <input
                                            type="number"
                                            placeholder="0.0000"
                                            className="form-control"
                                            value={fromAmountref.current}
                                            onChange={(e) =>
                                              setAmount(
                                                e.target.value,
                                                "fromAmount"
                                              )
                                            }
                                          />
                                        ) : (
                                          <input
                                            type="number"
                                            placeholder="0.0000"
                                            className="form-control"
                                            onChange={(e) =>
                                              setAmount(
                                                e.target.value,
                                                "fromAmount"
                                              )
                                            }
                                          />
                                        )}
                                <small onClick={maxBalance} >Max</small>
                                <div className="coin_selecr">
                                 
                                   <Dropdown
                                          placeholder="Select Coin"
                                          fluid
                                          selection
                                          options={fromCurrencyRef.current}
                                          onChange={handleOnChange_from}
                                          search
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="swap_icon">
                                    <span>
                                      <i class="ri-arrow-up-down-line"></i>
                                    </span>
                                  </div>
                                  <div className="input_rexe">
                                    <p>To </p>
                                    <div className="input_coin_">
                                      <input
                                        type="number"
                                        placeholder="0.0000"
                                        className="form-control input_widyjad"
                                        value={toAmountref.current}
                                        onChange={(e) =>
                                          setAmount(e.target.value, "toAmount")
                                        }
                                      />
                                      <div className="coin_selecr">
                                        <Dropdown
                                          placeholder="Select Coin"
                                          fluid
                                          selection
                                          options={toCurrencyRef.current}
                                          onChange={handleOnChange_to}
                                          search
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <small className="note">
                                    The Ultimate price and output is determinded
                                    by the tokens in the pool at the time of the
                                    swap
                                  </small>
                                  {ButtonLoader == false ? (
                                    <Button
                                      className="swap_but"
                                      onClick={swapAmount}
                                    >
                                      {" "}
                                      Swap{" "}
                                    </Button>
                                  ) : (
                                    <Button className="swap_but">
                                      {" "}
                                      Loading{" "}
                                    </Button>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-5">
                                <div className="swap_content summer_swap">
                                  <h2>Summary</h2>

                                  <p>
                                    <span>Price</span>
                                    {/* <span>0.0000</span> */}
                                    {fromref.current != "" &&
                                    toref.current != "" ? (
                                      <span>
                                        {" "}
                                        1 ADVB ={" "}
                                        {Number(advbpriceref.current).toFixed(
                                          8
                                        ) == 0 ? (
                                          <span>0</span>
                                        ) : (
                                          Number(advbpriceref.current).toFixed(
                                            8
                                          )
                                        )}{" "}
                                        USDT{" "}
                                      </span>
                                    ) : (
                                      <span>0.00000000</span>
                                    )}
                                  </p>

                            {/* <p>
                              <span>Price</span>
                              {fromref.current != "" &&
                                  toref.current != "" ? (
                                    <span>
                                      {" "}
                                      1 ADVB ={" "}
                                      {Number(advbpriceref.current).toFixed(8) ==
                                      0 ? (
                                        <span>0</span>
                                      ) : (
                                        <span>0.00000000</span>
                                      )}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                            </p> */}

                                  {/* <p>
                              <span>Minimum Swap</span>
                              <span>
                                    {" "}
                                    {appendFromData == "" ? (
                                      <span>0.0000</span>
                                    ) : (
                                      appendFromDataref.current.minSwap
                                    )}{" "}
                                  </span>
                            </p>
                            <p>
                              <span>Maximum Swap</span>
                              <span>
                                    {" "}
                                    {appendFromData == "" ? (
                                      <span>0.0000</span>
                                    ) : (
                                      appendFromDataref.current.maxSwap
                                    )}{" "}
                                  </span>
                            </p> */}
                                  <p>
                                    {/* <span>Swap fee (0.0% )</span>
                              <span>0.0000</span> */}
                                    <span>
                                      Swap fee (
                                      {appendFromDataref.current == ""
                                        ? "0.0%"
                                        : appendFromDataref.current
                                            .swapFee}{" "}
                                      )
                                    </span>
                                    <span>
                                      {" "}
                                      {estimateFee == 0 ? (
                                        <span>0.0000</span>
                                      ) : (
                                        Number(estimateFee).toFixed(8)
                                      )}{" "}
                                    </span>
                                  </p>
                                  <p>
                                    <span>Total Amount</span>
                                    {/* <span>0.0000</span> */}
                                    <span>
                                      {" "}
                                      {totatlAmount == 0 ? (
                                        <span>0.0000</span>
                                      ) : (
                                        Number(totatlAmount).toFixed(8)
                                      )}{" "}
                                    </span>
                                  </p>

                                  <p>
                                    <span className="text_left">
                                      Note: If any one already swap the
                                      Advb to Other coin means kindly
                                      re-swap from other coins to ADVB
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="tow_refer">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-lg-12">
                          <h2 className="reer_title">
                            <i class="ri-gift-fill"></i> Swap History
                          </h2>
                          <div className="market_tabel w-100">
                            <div className="market_section">
                              <div class="table-responsive">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      {/* <th>S.No</th> */}
                                      <th>FromCurrency</th>
                                      <th>ToCurrency</th>
                                      <th>Amount</th>
                                      <th>Price</th>
                                      <th>Total Amount</th>
                                      <th>Fees</th>
                                      <th>Receive</th>
                                      <th>Date / time</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {swapHistory.length > 0 ? (
                                      swapHistory &&
                                      swapHistory.map((item, i) => {
                                        var recv_amt = item.amount * item.price;
                                        return (
                                          <tr>
                                            {/* <td>{i + 1}</td> */}
                                            <td>{item.fromCurrency}</td>
                                            <td>{item.toCurrency}</td>
                                            <td>
                                              {parseFloat(item.amount).toFixed(
                                                2
                                              )}{" "}
                                              {""} {item.fromCurrency}
                                            </td>
                                            <td>
                                              {parseFloat(item.price).toFixed(
                                                5
                                              )}{" "}
                                              {""} {item.toCurrency}
                                            </td>
                                            <td>
                                              {parseFloat(
                                                item.totalAmount
                                              ).toFixed(5)}{" "}
                                              {""} {item.fromCurrency}
                                            </td>
                                            <td>
                                              {parseFloat(item.fee).toFixed(5)}{" "}
                                              {""} {item.fromCurrency}
                                            </td>
                                            <td>
                                              {parseFloat(recv_amt).toFixed(5)}{" "}
                                              {""} {item.toCurrency}
                                            </td>
                                            <td>
                                              {moment(item.createdDate).format(
                                                "lll"
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <td colSpan="8">
                                        <span className="w-100 text-center d-block">
                                          No History Found!
                                        </span>
                                      </td>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>

                          {swapHistory.length > 0 ? (
                            <Pagination
                              itemClass="page-item" // add it for bootstrap 4
                              linkClass="page-link" // add it for bootstrap 4
                              activePage={currentPage}
                              itemsCountPerPage={recordPerPage}
                              totalItemsCount={totalPage}
                              pageRangeDisplayed={pageRange}
                              onChange={handlePageChange}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                  {/* <section className="tow_refer">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="reer_title">
                  <i class="ri-gift-fill"></i> My Rewards
                </h2>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Distribution Date</th>
                        <th> Reward Name</th>
                        <th className="text-right"> Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>ID243423424</td>

                        <td>01-01-2023</td>
                        <td>Invite</td>
                        <td className="text-right text-green">success</td>
                      </tr>
                      <tr>
                        <td>ID243423424</td>

                        <td>01-01-2023</td>
                        <td>Invite</td>
                        <td className="text-right text-green">success</td>
                      </tr>
                      <tr>
                        <td>ID243423424</td>

                        <td>01-01-2023</td>
                        <td>Invite</td>
                        <td className="text-right text-green">success</td>
                      </tr>
                      <tr>
                        <td>ID243423424</td>

                        <td>01-01-2023</td>
                        <td>Invite</td>
                        <td className="text-right text-green">success</td>
                      </tr>
                      <tr>
                        <td>ID243423424</td>

                        <td>01-01-2023</td>
                        <td>Invite</td>
                        <td className="text-right text-green">success</td>
                      </tr>
                      <tr>
                        <td>ID243423424</td>

                        <td>01-01-2023</td>
                        <td>Invite</td>
                        <td className="text-right text-green">success</td>
                      </tr>
                      <tr>
                        <td>ID243423424</td>

                        <td>01-01-2023</td>
                        <td>Invite</td>
                        <td className="text-right text-green">success</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section> */}
                </>
              )}
            </main>
          </div>
        </div>
      </div>
      {loaderref.current == true ? (
          <div class="spinner css-ezm1fk">
            <div class="css-1qoumk2"></div>
            <div class="css-1v19680"></div>
            <div class="css-1v90jie"></div>
            <div class="css-19p0rvp"></div>
          </div>
        ) : (
      <Footer />
      )}
    </div>
  );
}

export default Home;
