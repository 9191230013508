import React, {useEffect} from "react";
import useState from "react-usestateref";
import Header from "./Header";
import {Button} from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import {Link} from "react-router-dom";
import Sidebar from "./Sidebarlink";
import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";
import {getMethod} from "../core/service/common.api";
import {ToastContainer, toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import validator from "validator";

function Home() {
  useEffect(() => {
    getallCryptos();
  }, []);
  const navigate = useNavigate();

  const [allcryptoData, setallcryptoData] = useState([]);
  const [search,setsearch,searchref] = useState("");
  const [allcryptoSearch, setallcryptoSearch, allcryptoSearchref] = useState([]);
  const [highlightCrypto,sethighlightCrypto,highlightCryptoref] = useState([]);
  const [newCrypto,setnewCrypto,newCryptoref] = useState([]);
  const [highvolCrypto,sethighvolCrypto,highvolCryptoref] = useState([]);
  const [loader, Setloader] = useState(false);

  const getallCryptos = async () => {

    var data = {
      apiUrl: apiService.allmarkets
    };
    Setloader(true);
    var resp = await getMethod(data);
    if (resp.status) {
      Setloader(false);
      setallcryptoData(resp.Message);
      setallcryptoSearch(resp.Message);
      sethighlightCrypto(resp.Message.slice(0,3));
      setnewCrypto(resp.Message.slice((resp.Message.length - 3), resp.Message.length));
      console.log("sethighlightCrypto===",highlightCryptoref.current);
      console.log("setnewCrypto===",newCryptoref.current);
      var vol_crypto = resp.data.sort( function ( a, b ) { return b.coin_volume - a.coin_volume; } );
      vol_crypto =  vol_crypto.slice(0,3);
      sethighvolCrypto(vol_crypto);
      console.log("vol_crypto===",vol_crypto);
      
    } else {
    }
  };

  const filterMarkets = async (filter) => {
    setsearch(filter);
    if(searchref.current != "")
    {
      setallcryptoData(
        allcryptoSearchref.current.filter(function (tag) {
          if (
            tag.currencySymbol
              .toLowerCase()
              .indexOf(searchref.current.toLowerCase()) >= 0 ||
            tag.currencySymbol
              .toLowerCase()
              .indexOf(searchref.current.toLowerCase()) >= 0
          ) {
            return tag;
          }
        })
      );
    }
    else
    {
      setallcryptoData(allcryptoSearchref.current);
    }
  }

  const redirect_url = async (url) => {
    navigate(url);
};

  return (
    <div className="header ">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          {/* <div className="css-1t8cbiy">
            <Sidebar />
          </div> */}
          <div className="css-1h690ep">
          {loader == true ? (
              <div class="spinner css-ezm1fk">
                <div class="css-1qoumk2"></div>
                <div class="css-1v19680"></div>
                <div class="css-1v90jie"></div>
                <div class="css-19p0rvp"></div>
              </div>
            ) : (
            <div className="section_bg_ash">
              <div className="section_selec_ash">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <h2 className="heading_titlr">Market Overview</h2>
                    </div>
                    <div className="col-lg-4">
                      <div className="market">
                        <h2>Highlight</h2>
                        <ul>
                        {highlightCryptoref.current &&
                          highlightCryptoref.current.map((obj, i) => {
                          return (
                          <li className="cursor_pointer" onClick={() => redirect_url("/trade/"+obj.currencySymbol+"_USDT")}>
                            <span className="icon_flex">
                              {" "}
                              <img
                                src={obj.Currency_image}
                                className="blackshow"
                              />
                              <small>{obj.currencySymbol}</small>
                            </span>
                            <span className="green_text">${obj.estimatedValueInUSDT}</span>
                            {obj.coin_change < 0 ? (
                              <span className="text_red">{obj.coin_change}%</span>
                              ) : (
                              <span className="green_text">+{obj.coin_change}%</span>
                              )}
                          </li>
                          );
                        })}

                          {/* <li>
                            <span className="icon_flex">
                              {" "}
                              <img
                                src={require("../Image/ETH.png")}
                                className="blackshow"
                              />
                              <small>ETH</small>
                            </span>
                            <span className="green_text">91 K</span>
                            <span className="text_red">-0.98%</span>
                          </li>
                          <li>
                            <span className="icon_flex">
                              {" "}
                              <img
                                src={require("../Image/ETH.png")}
                                className="blackshow"
                              />
                              <small>ETH</small>
                            </span>
                            <span className="green_text">91 K</span>
                            <span className="text_red">-0.98%</span>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="market">
                        <h2>New Listing</h2>
                        <ul>
                        {newCryptoref.current &&
                          newCryptoref.current.map((obj, i) => {
                          return (
                          <li className="cursor_pointer" onClick={() => redirect_url("/trade/"+obj.currencySymbol+"_USDT")}>
                            <span className="icon_flex">
                              {" "}
                              <img
                                src={obj.Currency_image}
                                className="blackshow"
                              />
                              <small>{obj.currencySymbol}</small>
                            </span>
                            <span className="green_text">${obj.estimatedValueInUSDT}</span>
                            {obj.coin_change < 0 ? (
                              <span className="text_red">{obj.coin_change}%</span>
                              ) : (
                              <span className="green_text">+{obj.coin_change}%</span>
                              )}
                          </li>
                          );
                        })}
                          {/* <li>
                            <span className="icon_flex">
                              {" "}
                              <img
                                src={require("../Image/ETH.png")}
                                className="blackshow"
                              />
                              <small>ETH</small>
                            </span>
                            <span className="green_text">91 K</span>
                            <span className="text_red">-0.98%</span>
                          </li>
                          <li>
                            <span className="icon_flex">
                              {" "}
                              <img
                                src={require("../Image/ETH.png")}
                                className="blackshow"
                              />
                              <small>ETH</small>
                            </span>
                            <span className="green_text">91 K</span>
                            <span className="text_red">-0.98%</span>
                          </li>
                          <li>
                            <span className="icon_flex">
                              {" "}
                              <img
                                src={require("../Image/ETH.png")}
                                className="blackshow"
                              />
                              <small>ETH</small>
                            </span>
                            <span className="green_text">91 K</span>
                            <span className="text_red">-0.98%</span>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="market">
                        <h2>Top Volume Coin</h2>
                        <ul>
                        {highvolCryptoref.current &&
                          highvolCryptoref.current.map((obj, i) => {
                          return (
                          <li className="cursor_pointer" onClick={() => redirect_url("/trade/"+obj.currencySymbol+"_USDT")}>
                            <span className="icon_flex">
                              {" "}
                              <img
                                src={obj.Currency_image}
                                className="blackshow"
                              />
                              <small>{obj.currencySymbol}</small>
                            </span>
                            <span className="green_text">${obj.estimatedValueInUSDT}</span>
                            {obj.coin_change < 0 ? (
                              <span className="text_red">{obj.coin_change}%</span>
                              ) : (
                              <span className="green_text">+{obj.coin_change}%</span>
                              )}
                          </li>
                          );
                        })}
                          {/* <li>
                            <span className="icon_flex">
                              {" "}
                              <img
                                src={require("../Image/ETH.png")}
                                className="blackshow"
                              />
                              <small>ETH</small>
                            </span>
                            <span className="green_text">91 K</span>
                            <span className="text_red">-0.98%</span>
                          </li>
                          <li>
                            <span className="icon_flex">
                              {" "}
                              <img
                                src={require("../Image/ETH.png")}
                                className="blackshow"
                              />
                              <small>ETH</small>
                            </span>
                            <span className="green_text">91 K</span>
                            <span className="text_red">-0.98%</span>
                          </li>
                          <li>
                            <span className="icon_flex">
                              {" "}
                              <img
                                src={require("../Image/ETH.png")}
                                className="blackshow"
                              />
                              <small>ETH</small>
                            </span>
                            <span className="green_text">91 K</span>
                            <span className="text_red">-0.98%</span>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                    {/* <div className="col-lg-3">
                      <div className="market">
                        <h2>Top Volume Coin</h2>
                        <ul>
                          <li>
                            <span className="icon_flex">
                              {" "}
                              <img
                                src={require("../Image/ETH.png")}
                                className="blackshow"
                              />
                              <small>ETH</small>
                            </span>
                            <span className="green_text">91 K</span>
                            <span className="text_red">-0.98%</span>
                          </li>
                          <li>
                            <span className="icon_flex">
                              {" "}
                              <img
                                src={require("../Image/ETH.png")}
                                className="blackshow"
                              />
                              <small>ETH</small>
                            </span>
                            <span className="green_text">91 K</span>
                            <span className="text_red">-0.98%</span>
                          </li>
                          <li>
                            <span className="icon_flex">
                              {" "}
                              <img
                                src={require("../Image/ETH.png")}
                                className="blackshow"
                              />
                              <small>ETH</small>
                            </span>
                            <span className="green_text">91 K</span>
                            <span className="text_red">-0.98%</span>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="inner_conteten market_overview inner-banner">
                  <div className="order_tabs">
                    <div className="flex_tabes_s">
                      {" "}
                      <ul class="nav nav-tabs">
                        <li class="active">
                          <a data-toggle="tab" href="#home" className="active">
                            All Crypto
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#menu1">
                            Spot Market
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#menu1">
                            New Listing
                          </a>
                        </li>
                      </ul>
                      <div className="search">
                        <input type="text" onChange={(e) => filterMarkets(e.target.value)}/>
                        <span>
                          <i class="ri-search-line"></i>
                        </span>
                      </div>
                    </div>
                    <div class="tab-content ">
                      <div id="home" class="tab-pane fade in active show">
                        <div className="col-lg-12">
                          <div className="market_tabel w-100 mt-1">
                            <div className="market_section">
                              <div className="table-responsive mt-2">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th> Price</th>
                                      <th>24h Change</th>
                                      <th className="">24h Volume</th>
                                      {/* <th className="">Market Cap</th> */}
                                      <th className=""></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {allcryptoData &&
                                  allcryptoData.map((obj, i) => {
                                  return (
                                    <tr>
                                      <td>
                                        <div className="coin_section">
                                          <img
                                            src={obj.Currency_image}
                                            className="blackshow"
                                          />
                                          <span>{obj.currencyName}</span>{" "}
                                          <small>{obj.currencySymbol}</small>
                                        </div>
                                      </td>
                                      <td>
                                        <p className="green_text">
                                        ${obj.estimatedValueInUSDT}
                                        </p>
                                      </td>
                                      {obj.coin_change < 0 ? (
                                        <td>
                                        <p className="text-red">{obj.coin_change}%</p>
                                        </td>

                                        ) : (
                                        <td>
                                        <p className="green_text">+{obj.coin_change}%</p>
                                        </td>
                                        )}
                                      <td>
                                        <p className="">{obj.coin_volume} </p>
                                      </td>
                                      <td className="text-right">
                                        <a href={"/trade/"+obj.currencySymbol+"_USDT"} className="trend_roe" target="_blank">
                                          Trade
                                        </a>
                                      </td>
                                    </tr>
                                    );
                                  })}
                                    {/* <tr>
                                      <td>
                                        <div className="coin_section">
                                          <img
                                            src={require("../Image/AVE.png")}
                                            className="blackshow"
                                          />
                                          <span>Avalanche</span>{" "}
                                          <small>AVE</small>
                                        </div>
                                      </td>
                                      <td>
                                        <p className="green_text">
                                          120.00 USTD
                                        </p>
                                      </td>
                                      <td>
                                        <p className="green_text">+0.60%</p>
                                      </td>
                                      <td>
                                        <p className="">$1.23.000B </p>
                                      </td>
                                      <td>
                                        <p className="">$1.23.000B </p>
                                      </td>
                                      <td className="text-right">
                                        <a href="/trade" className="trend_roe">
                                          Trade
                                        </a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="coin_section">
                                          <img
                                            src={require("../Image/BTC.png")}
                                            className="blackshow"
                                          />
                                          <span>Bitcoin</span>{" "}
                                          <small>BTC</small>
                                        </div>
                                      </td>
                                      <td>
                                        <p className="green_text">
                                          120.00 USTD
                                        </p>
                                      </td>
                                      <td>
                                        <p className="green_text">+0.60%</p>
                                      </td>
                                      <td>
                                        <p className="">$1.23.000B </p>
                                      </td>
                                      <td>
                                        <p className="">$1.23.000B </p>
                                      </td>
                                      <td className="text-right">
                                        <a href="/trade" className="trend_roe">
                                          Trade
                                        </a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="coin_section">
                                          <img
                                            src={require("../Image/LITE.png")}
                                            className="blackshow"
                                          />
                                          <span>Litecoin</span>{" "}
                                          <small>LTC</small>
                                        </div>
                                      </td>
                                      <td>
                                        <p className="green_text">
                                          120.00 USTD
                                        </p>
                                      </td>
                                      <td>
                                        <p className="green_text">+0.60%</p>
                                      </td>
                                      <td>
                                        <p className="">$1.23.000B </p>
                                      </td>
                                      <td>
                                        <p className="">$1.23.000B </p>
                                      </td>
                                      <td className="text-right">
                                        <a href="/trade" className="trend_roe">
                                          Trade
                                        </a>
                                      </td>
                                    </tr> */}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="menu1" class="tab-pane fade">
                      <div className="col-lg-12">
                          <div className="market_tabel w-100 mt-1">
                            <div className="market_section">
                              <div className="table-responsive mt-2">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th> Price</th>
                                      <th>24h Change</th>
                                      <th className="">24h Volume</th>
                                      {/* <th className="">Market Cap</th> */}
                                      <th className=""></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {allcryptoData &&
                                  allcryptoData.map((obj, i) => {
                                  return (
                                    <tr>
                                      <td>
                                      <p className="">{obj.currencySymbol+"/USDT"} </p>
                                      </td>
                                      <td>
                                        <p className="green_text">
                                        ${obj.estimatedValueInUSDT}
                                        </p>
                                      </td>
                                      {obj.coin_change < 0 ? (
                                        <td>
                                        <p className="text-red">{obj.coin_change}%</p>
                                        </td>

                                        ) : (
                                        <td>
                                        <p className="green_text">+{obj.coin_change}%</p>
                                        </td>
                                        )}
                                      <td>
                                        <p className="">{obj.coin_volume} </p>
                                      </td>
                                      <td className="text-right">
                                        <a href={"/trade/"+obj.currencySymbol+"_USDT"} className="trend_roe" target="_blank">
                                          Trade
                                        </a>
                                      </td>
                                    </tr>
                                    );
                                  })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="contant_secion_balance mt-4">
                          <div className="row justify-content-center mt-3">
                            <div className="col-lg-4">
                              <div className="row ">
                                <div className="col-lg-12">
                                  <h3 className="input_labele">Old password</h3>
                                  <div className="with-eye">
                                    <input
                                      type={inputtype1}
                                      name="oldpassword"
                                      required=""
                                      className="pan_number"
                                      id="exampleInputPassword1"
                                      placeholder="Old password"
                                      value={oldpassword}
                                      onChange={handleChange}
                                    />
                                    <span>
                                      {passhide1 == false ? (
                                        <i
                                          className="bi bi-eye-slash-fill"
                                          onClick={() => oldPassword("hide")}
                                        ></i>
                                      ) : (
                                        <i
                                          className="bi bi-eye-fill"
                                          onClick={() => oldPassword("show")}
                                        ></i>
                                      )}
                                    </span>
                                  </div>
                                  <div>
                                    {oldpassvalidate == true ? (
                                      <p className="text-danger">
                                        {" "}
                                        {validationnErr.oldpassword}{" "}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <h3 className="input_labele">New password</h3>
                                  <div className="with-eye">
                                    <input
                                      type={inputtype2}
                                      name="password"
                                      required=""
                                      id="exampleInputPassword1"
                                      placeholder="New password"
                                      className="pan_number"
                                      value={password}
                                      onChange={handleChange}
                                    />
                                    <span>
                                      {passhide2 == false ? (
                                        <i
                                          className="bi bi-eye-slash-fill"
                                          onClick={() => newPassword("hide")}
                                        ></i>
                                      ) : (
                                        <i
                                          className="bi bi-eye-fill"
                                          onClick={() => newPassword("show")}
                                        ></i>
                                      )}
                                    </span>
                                  </div>
                                  <div>
                                    {passwordValidate == true ? (
                                      <p className="text-danger">
                                        {" "}
                                        {validationnErr.password}{" "}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <h3 className="input_labele">
                                    Confirm password
                                  </h3>
                                  <div className="with-eye">
                                    <input
                                      type={inputtype3}
                                      required=""
                                      id="exampleInputPassword1"
                                      placeholder="confirm password"
                                      className="pan_number"
                                      name="confirmPassword"
                                      value={confirmPassword}
                                      onChange={handleChange}
                                    />
                                    <span>
                                      {passhide3 == false ? (
                                        <i
                                          className="bi bi-eye-slash-fill"
                                          onClick={() => conPassword("hide")}
                                        ></i>
                                      ) : (
                                        <i
                                          className="bi bi-eye-fill"
                                          onClick={() => conPassword("show")}
                                        ></i>
                                      )}
                                    </span>
                                  </div>
                                  <div>
                                    {confirmPasswordValidate == true ? (
                                      <p className="text-danger">
                                        {" "}
                                        {validationnErr.confirmPassword}{" "}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  {buttonLoader == false ? (
                                    <Button
                                      className="changepassword"
                                      onClick={formSubmit}
                                    >
                                      Change Password
                                    </Button>
                                  ) : (
                                    <Button className="changepassword">
                                      Loading...
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
