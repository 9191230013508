import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Footer from "./Footer";
// import Bgvedio from "../Image/globe-fiat-block.mp4";
import BannerUI from "./Banner";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import Moment from "moment";
import Pagination from "react-js-pagination";
import { Dropdown } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterIcon,
  TwitterShareButton,
  TelegramIcon,
  TelegramShareButton,
  InstapaperIcon,
  InstapaperShareButton,
} from "react-share";
import { Link, useNavigate } from "react-router-dom";
import Select from 'react-select';

function Home() {

  const navigate = useNavigate();

  const back = () => {
    navigate('/p2p')
  }
  const [p2pOrders, setp2pOrders] = useState([]);
  const [p2pcurrentpage, setp2pcurrentpage] = useState(1)
  const [p2ptotalpage, setp2pTotalpages] = useState(0);

  const [notification, Setnotification] = useState([]);
  const [notifyCurrentPage, setnotifyCurrentPage] = useState()
  const [notifytotalpage, Setnotifytotalpage] = useState(0);
  const [loader, setloader] = useState(false);

  const getp2pOrders = async (page) => {
    var data = {
      apiUrl: apiService.p2pProcessOrders,
      payload: { FilPerpage: 5, FilPage: page },
    };
    var p2p_orders_list = await postMethod(data);
    console.log("p2p_orders_list===", p2p_orders_list.returnObj.Message);
    setloader(true);
    if (p2p_orders_list.status) {
      setloader(false);
      setp2pOrders(p2p_orders_list.returnObj.Message);
      setp2pTotalpages(p2p_orders_list.returnObj.total);
    }
  }

  const p2precordpage = 5;
  const p2ppagerange = 5;
  const handlepagep2p = (p2ppage) => {
    console.log(p2ppage, "==-=-=-p2ppage==-==-=")
    getp2pOrders(p2ppage);
    setp2pcurrentpage(p2ppage)
  }

  const notifyrecordPerPage = 5;

  const notifypageRange = 5;
  const notify = async (page) => {

    var payload = {
      perpage: 5,
      page: page,
    };

    var Notification = {
      apiUrl: apiService.getnotification,
      payload: payload,
    }
    var resp = await postMethod(Notification);
    if (resp.status) {
      Setnotification(resp.data.data);
      console.log(resp.data.data, "=-=-resp.data.data-==-=")

      Setnotifytotalpage(resp.data.total)
      console.log(resp.data.total, "=-=resp.data.total=-=-=")
    } else {

    }
  }

  const handlenotifyPageChange = (pageNumber) => {
    notify(pageNumber);
    setnotifyCurrentPage(pageNumber);
  }

  const navchatpage = async (link) => {
    navigate(link);
  };


  useEffect(() => {
    let token_socket = localStorage.getItem("user_token");
    if (!token_socket) {
      navigate("/login");
    }
    // getp2pOrders(1);
    notify();

  }, [0]);

  const navpage = async (link) => {
    navigate("/p2p/view/" + link);
  };

  return (
    <div className="header">
      <Header />
      <main className="main_heinght">
        {loader == true ? (
          <div class="spinner css-ezm1fk">
            <div class="css-1qoumk2"></div>
            <div class="css-1v19680"></div>
            <div class="css-1v90jie"></div>
            <div class="css-19p0rvp"></div>
          </div>
        ) : (
          <>
            <section className="deposut_section">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="headin_deposur_s">
                      <h3>
                        <Link to="/dashboard">
                          <i class="ri-arrow-left-fill"></i>
                        </Link>
                        Process Orders
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="tavle_transactio">
              <div className="padding_section">
                <div className="container">
                  <div className="buttonCls">
                    <h4 className="heading_class_recent p-3">Recent Process Orders</h4>
                    <Button
                      className="swap_but1" onClick={back}>Back</Button>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="market_tabel w-100 mt-0 p-3">
                        <div className="market_section">
                          <div className="table-responsive">
                            <table class="table table-content">
                              <thead>
                                <tr>
                                  <th>S.No</th>
                                  <th>Date & Time</th>
                                  <th>From</th>
                                  <th>Message</th>
                                </tr>
                              </thead>
                              <tbody>
                                {notification && notification.length > 0 ? (
                                  notification.map((item, i) => {
                                    return (
                                      <tr onClick={() => navchatpage(item.link)}>
                                        <td>
                                          <p>{i + 1}</p>
                                        </td>
                                        <td>
                                          <p>
                                            {Moment(item.createdAt).format("lll")}
                                          </p>
                                        </td>
                                        <td>
                                          <p>{item.from_user_name}</p>
                                        </td>

                                        <td>
                                          <p>{item.message}</p>
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan="4">
                                      <p className="text-center">No records found</p>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                            <Pagination
                              itemClass="page-item" // add it for bootstrap 4
                              linkClass="page-link" // add it for bootstrap 4
                              activePage={notifyCurrentPage}
                              itemsCountPerPage={notifyrecordPerPage}
                              totalItemsCount={notifytotalpage}
                              pageRangeDisplayed={notifypageRange}
                              onChange={handlenotifyPageChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </main>
      <Footer />
    </div>
  );
}

export default Home;
