import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import { Dropdown } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { Link, useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const initialFormValue = {
    quantity: "",
    price: "",
    limitFrom: "",
    limitTo: "",
    terms: "",
    auto_reply: "",
  };

  const [formValue, setFormValue] = useState(initialFormValue);
  const [allCurrency, setallCurrency] = useState([]);
  const [allCurrencyFiat, setallCurrencyFiat] = useState([]);
  const [cryptoCurrency, setcryptoCurrency] = useState("BTC");
  const [crytpoWithCash, setcrytpoWithCash] = useState("INR");
  const [coinPrice, setcoinPrice, coinPriceref] = useState("");
  const [coinBalance, setcoinBalance, coinBalanceref] = useState("");
  const [fiat, setfiat, fiatref] = useState("INR");
  const [cryptoPrices, setcryptoPrices, cryptoPricesref] = useState([]);

  const { quantity, price, limitFrom, limitTo, terms, auto_reply } = formValue;
  const [fromCurrency, setfromCurrency, fromCurrencyref] = useState("BTC");
  const [toCurrency, settoCurrency, toCurrencyref] = useState("INR");
  const [highprice, sethighprice, highpriceref] = useState(0);
  const [lowprice, setlowprice, lowpriceref] = useState(0);
  const [increment, setIncrement, incrementref] = useState(0);
  const [step1, setstep1, step1ref] = useState(true);
  const [step2, setstep2, step2ref] = useState(false);
  const [step3, setstep3, step3ref] = useState(false);
  const [allCrypto, setallCrypto, allCryptoref] = useState([]);
  const [firstCurrency, setfirstCurrency, firstCurrencyref] = useState(
    "619948553cda890ff0256d3c"
  );
  const [secondCurrency, setsecondCurrency, secondCurrencyref] = useState(
    "62f625b70a05cc3f9e4222bb"
  );

  const [kycstatus, setkycstatus] = useState(0);
  const [prferPay, setprferPay] = useState("All payments");
  const [Terms, setTerms] = useState(false);
  const [errquantity, seterrquantity, seterrquantityref] = useState("");
  const [priceError, serpriceError, serpriceErrorref] = useState("");
  const [errLimitFrom, seterrLimitFrom, seterrLimitFromref] = useState("");
  const [errlimitTo, seterrlimitTo, seterrlimitToref] = useState("");
  const [errcryptoCurrency, seterrcryptoCurrency] = useState("");
  const [errcrytpoWithCash, seterrcrytpoWithCash] = useState("");
  const [errprferPay, seterrprferPay] = useState("");
  const [termsValidation, setTermsValidation] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [payment_time, setpayment_time, payment_timeref] = useState("15");
  const [errpaymentTime, seterrpaymentTime] = useState("");
  const [validationnErr1, setvalidationnErr1, validationnErr1ref] = useState(
    {}
  );
  const [validationnErr2, setvalidationnErr2, validationnErr2ref] = useState(
    {}
  );
  const [validationnErr3, setvalidationnErr3, validationnErr3ref] = useState(
    {}
  );

  useEffect(() => {
    getAllcurrency();
    fiat_price();
    getKycData();
  }, [0]);

  const getAllcurrency = async () => {
    setLoader(true);
    var payload = {
      fiat: fiatref.current,
    };
    var data = {
      apiUrl: apiService.getP2Pbalance,
      payload: payload,
    };
    var resp = await postMethod(data);
    console.log(resp, "-=-=-resp=-=-");
    if (resp) {
      setLoader(false);
      var currArrayCrypto = [];
      var currArrayFiat = [];
      var currPrices = [];
      var data = resp.data;
      setallCrypto(data);
      console.log(data, "][][][][][][");
      for (var i = 0; i < data.length; i++) {
        if (data[i].coinType == "1") {
          var obj = {
            key: data[i].currencySymbol,
            text: data[i].currencySymbol,
            value: data[i]._id,
            price: data[i].estimatedValueInUSDT,
            image: {
              avatar: true,
              src: data[i].image,
            },
          };
          currArrayCrypto.push(obj);
        }
        if (data[i].coinType == "2") {
          var obj = {
            key: data[i].currencySymbol,
            text: data[i].currencySymbol,
            value: data[i]._id,
            price: data[i].estimatedValueInUSDT,
            image: {
              avatar: true,
              src: data[i].image,
            },
          };
          currArrayFiat.push(obj);
        }
        var obj1 = {
          symbol: data[i].currencySymbol,
          price: data[i].estimatedValueInUSDT,
          balance: data[i].currencyBalance,
        };
        currPrices.push(obj1);
      }
      setallCurrency(currArrayCrypto);
      setallCurrencyFiat(currArrayFiat);
      setcryptoPrices(currPrices);
      let index = currPrices.filter((x) => x.symbol == fromCurrencyref.current);
      formValue.price = index[0].price;
      setcoinPrice(index[0].price);
      setcoinBalance(index[0].balance);
    }
    // console.log(allCurrency,'allCurrencyallCurrencyallCurrency=--=-=-=-==-=')
  };

  const fiat_price = async () => {
    var payload = {
      fromCurrency: fromCurrencyref.current,
      toCurrency: toCurrencyref.current,
    };
    var data = {
      apiUrl: apiService.fetch_price,
      payload: payload,
    };

    var resp = await postMethod(data);
    console.log("fetch price response====", resp);
    if (resp) {
      let index = cryptoPricesref.current.filter(
        (x) => x.symbol == fromCurrencyref.current
      );
      var high =
        resp.data.highprice != 0 ? resp.data.highprice : index[0].price;
      var low = resp.data.lowprice != 0 ? resp.data.lowprice : index[0].price;
      sethighprice(high);
      setlowprice(low);
    }
  };

  const set_price = async (type, price) => {
    if (type == "add") {
      console.log("type===", type);
      console.log("price===", price);
      var inc = 1;
      incrementref.current = incrementref.current + inc;
      if (incrementref.current >= 0) {
        setIncrement(incrementref.current);
        var price_val = +price;
        price_val = price_val + inc;
        console.log("price_val===", price_val);
        formValue.price = price_val;
        setFormValue(formValue);
        console.log("formValue.price===", formValue.price);
      }
    } else {
      var inc = 1;
      incrementref.current = incrementref.current - inc;
      if (incrementref.current >= 0) {
        setIncrement(incrementref.current);
        var price_val = +price;
        price_val = price_val - inc;
        formValue.price = price_val;
        setFormValue(formValue);
        console.log("formValue.price===", formValue.price);
      }
    }
  };

  const chooseCrypto = async (e, data) => {
    //console.log(option, "-=-chooseCrypto");
    var findIndexing = allCryptoref.current.findIndex(
      (x) => x._id == data.value
    );
    if (findIndexing != -1) {
      var sel_currency = allCryptoref.current[findIndexing];
      setcryptoCurrency(sel_currency.currencySymbol);
      setfirstCurrency(sel_currency._id);
      // getAllcurrency();
      let index = cryptoPricesref.current.filter(
        (x) => x.symbol == sel_currency.currencySymbol
      );
      // console.log("currency index====",index);
      formValue.price = index[0].price;
      console.log("formValue.price====", formValue.price);
      setcoinPrice(index[0].price);
      setcoinBalance(index[0].balance);
      setfromCurrency(sel_currency.currencySymbol);
      fiat_price();
    }
  };
  const withCash = async (e, data) => {
    var findIndexing = allCryptoref.current.findIndex(
      (x) => x._id == data.value
    );
    if (findIndexing != -1) {
      var sel_currency = allCryptoref.current[findIndexing];
      setcrytpoWithCash(sel_currency.currencySymbol);
      setsecondCurrency(sel_currency._id);
      setfiat(sel_currency.currencySymbol);
      settoCurrency(sel_currency.currencySymbol);
    }
  };

  const handleChange = async (e) => {
    console.log(e, "-=-=-=-=-");
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    console.log(formData, "formData=-=-=-=");
    setFormValue(formData);
  };

  const prferPayments = async (e, data) => {
    setprferPay(data.value);
  };

  const preferPayment = [
    { key: "All payments", value: "All payments", text: "All payments" },
    {
      key: "Bank Transfer",
      value: "Bank Transfer",
      text: "Bank Transfer",
    },
    { key: "UPI ID", value: "UPI ID", text: "UPI ID" },
    { key: "Paytm", value: "Paytm", text: "Paytm" },
  ];

  const preferPaymentOption = preferPayment[0];

  const payTime = [
    { key: "15", value: "15", text: "15 Minutes" },
    {
      key: "30",
      value: "30",
      text: "30 Minutes",
    },
    { key: "45", value: "45", text: "45 Minutes" },
    { key: "60", value: "60", text: "1 Hour" },
    { key: "120", value: "120", text: "2 Hours" },
    { key: "180", value: "180", text: "3 Hours" },
    { key: "240", value: "240", text: "4 Hours" },
    { key: "300", value: "300", text: "5 Hours" },
    { key: "360", value: "360", text: "6 Hours" },
  ];

  const choosePaytime = async (e, data) => {
    setpayment_time(data.value);
  };

  const validate_form1 = async (values) => {
    const errors = {};

    if (values.price == "") {
      errors.price = "Price is required";
      serpriceError(true);
    } else if (values.price <= 0) {
      errors.price = "Enter valid Price";
      serpriceError(true);
    } else {
      serpriceError(false);
    }

    if (values.cryptoCurrency == "") {
      errors.cryptoCurrency = "Crypto Currency required field";
      seterrcryptoCurrency(true);
    }
    if (values.crytpoWithCash == "") {
      errors.crytpoWithCash = "WithCash is required";
      seterrcrytpoWithCash(true);
    }
    setvalidationnErr1(errors);
    return errors;
  };

  const validate_form2 = async (values) => {
    const errors = {};
    if (values.quantity == "") {
      errors.quantity = "Quantity is required";
      seterrquantity(true);
    } else if (values.quantity <= 0) {
      errors.quantity = "Enter Valid Amount";
      seterrquantity(true);
    } else {
      seterrquantity(false);
    }

    if (values.limitFrom == "") {
      errors.limitFrom = "Minimum Quantity is required";
      seterrLimitFrom(true);
    } else if (values.limitFrom <= 0) {
      errors.limitFrom = "Enter valid amount";
      seterrLimitFrom(true);
    } else {
      seterrLimitFrom(false);
    }
    if (values.limitTo == "") {
      errors.limitTo = "Maximum Quantity is required";
      seterrlimitTo(true);
    } else if (values.limitTo <= 0) {
      errors.limitTo = "Enter valid Amount";
      seterrlimitTo(true);
    } else {
      seterrlimitTo(false);
    }

    if(+values.limitFrom >0 && +values.limitTo > 0)
    {

      if (+values.limitFrom >= +values.limitTo) {
        errors.limitFrom = "Minimum Quantity must be less than Maximum Quantity";
        seterrLimitFrom(true);
      }
      else if (+values.limitTo <= +values.limitFrom) {
        errors.limitTo = "Maximum Quantity must be greater than Minimum Quantity";
        seterrlimitTo(true);
      }
      else
      {
        seterrLimitFrom(false);
        seterrlimitTo(false);
      }

    }
    

    if (values.prferPay == "") {
      errors.prferPay = "Prefer payment is required";
      seterrprferPay(true);
    }

    if (payment_timeref.current == "") {
      errors.payment_time = "Payment Time required field";
      seterrpaymentTime(true);
    }
    setvalidationnErr2(errors);
    return errors;
  };

  const step_go = async (step) => {
    if (step == "1") {
      console.log(formValue, "formvalue");
      setstep1(true);
      setstep2(false);
      setstep3(false);
    } else if (step == "2") {
      validate_form1(formValue);
      if (Object.keys(validationnErr1ref.current).length == 0) {
        setstep2(true);
        setstep1(false);
        setstep3(false);
      }
    } else if (step == "3") {
      validate_form2(formValue);
      if (Object.keys(validationnErr2ref.current).length == 0) {
        setstep3(true);
        setstep1(false);
        setstep2(false);
      }
    }
  };

  const getKycData = async () => {
    try {
      var data = {
        apiUrl: apiService.getKYCDetails,
      };
      var resp = await getMethod(data);
      if (resp.status) {
        var responseData = resp.data.kycstatus;
        setkycstatus(responseData);
      } else {
        var responseData = 0;
        setkycstatus(responseData);
      }
    } catch (error) {
      var responseData = 0;
      setkycstatus(responseData);
    }
  };

  const submitPost = async (type) => {
    if (kycstatus == 1) {
      if (
        seterrLimitFromref.current == false &&
        seterrquantityref.current == false &&
        serpriceErrorref.current == false &&
        seterrlimitToref.current == false
      ) {
        formValue["fromCurrency"] = cryptoCurrency;
        formValue["toCurrency"] = crytpoWithCash;
        formValue["preferpayment"] = prferPay;
        formValue["orderType"] = type;
        formValue["firstCurrency"] = firstCurrency;
        formValue["secondCurrency"] = secondCurrency;
        formValue["pay_time"] = payment_timeref.current;

        var data = {
          apiUrl: apiService.p2pOrder,
          payload: formValue,
        };
        setbuttonLoader(true);
        var resp = await postMethod(data);
        setbuttonLoader(false);
        if (resp.status) {
          toast.success(resp.Message);
          formValue["quantity"] = "";
          formValue["price"] = "";
          formValue["limitFrom"] = "";
          formValue["limitTo"] = "";
          formValue["terms"] = "";
          formValue["auto_reply"] = "";
          formValue["firstCurrency"] = "";
          formValue["secondCurrency"] = "";
          //navigate("/p2p/chat/"+resp.orderId);
          navigate("/p2p");
        } else {
          console.log("error response====", resp);
          if (resp.bank) {
            toast.error(resp.Message);
            navigate("/Bankdetails");
          } else {
            toast.error(resp.Message);
          }
        }
      } else {
        toast.error("Please give valid fields");
      }
      console.log(formValue, "ererwearewr");
    } else {
      if (kycstatus == 2) {
        toast.error(
          "Your kyc verification is pending, please wait for admin approval"
        );
        navigate("/kyc");
      } else {
        toast.error("Please verify your kyc");
        navigate("/kyc");
      }
    }
  };

  return (
    <div className="header">
      <Header />
      <main>
        {loader == true ? (
          <div class="spinner css-ezm1fk">
            <div class="css-1qoumk2"></div>
            <div class="css-1v19680"></div>
            <div class="css-1v90jie"></div>
            <div class="css-19p0rvp"></div>
          </div>
        ) : (
          <>
            <main className="chat_mainpadi">
              <div className="card add_padin_card ">
                <div className="crypto_p2p">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="processs_step">
                          <div
                            className={
                              step1ref.current ? "step2 active" : "step2"
                            }
                          >
                            <h2>1</h2>
                            <p>Set Type & Price</p>
                          </div>
                          <div
                            className={
                              step2ref.current ? "step2 active" : "step2"
                            }
                          >
                            <h2>2</h2>
                            <p>Set Total Amount & Payment Method</p>
                          </div>
                          <div
                            className={
                              step3ref.current ? "step2 active" : "step2"
                            }
                          >
                            <h2> 3</h2>
                            <p>Set Remark & Automatic Response</p>
                          </div>
                        </div>
                        <div className="tabs_section_post_ad history_tabs new_tabs mt-4">
                          <ul class="nav nav-tabs">
                            <li class="active">
                              <a
                                data-toggle="tab"
                                href="#home"
                                className="active"
                              >
                                I want to Buy
                              </a>
                            </li>
                            <li>
                              <a data-toggle="tab" href="#menu1">
                                I want to Sell
                              </a>
                            </li>
                          </ul>
                          <div class="tab-content pt-4">
                            <div id="home" class="tab-pane fade in active show">
                              {step1ref.current ? (
                                <div>
                                  <div className="submit_form_post">
                                    <div className="select_form">
                                      <div className="select_iocn">
                                        <label>Asset</label>
                                        <Dropdown
                                          placeholder="Select Currency"
                                          fluid
                                          selection
                                          className="data_dropdoen"
                                          options={allCurrency}
                                          onChange={chooseCrypto}
                                          value={firstCurrencyref.current}
                                        />
                                        <div className="text-danger">
                                          {errcryptoCurrency == true ? (
                                            <p className="text-danger">
                                              {" "}
                                              {
                                                validationnErr1ref.current
                                                  .cryptoCurrency
                                              }{" "}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                      <div className="select_iocn clmariduke">
                                        <i class="ri-arrow-right-line"></i>
                                      </div>
                                      <div className="select_iocn">
                                        <label>With Fiat</label>
                                        <Dropdown
                                          placeholder="Select Currency"
                                          fluid
                                          selection
                                          className="data_dropdoen"
                                          options={allCurrencyFiat}
                                          onChange={withCash}
                                          value={secondCurrencyref.current}
                                        />
                                        <div className="text-danger">
                                          {errcrytpoWithCash == true ? (
                                            <p className="text-danger">
                                              {" "}
                                              {
                                                validationnErr1ref.current
                                                  .crytpoWithCash
                                              }{" "}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="price_section_pluse">
                                      <div className="price_content">
                                        <p>Your Price</p>
                                        <h3>
                                          {toCurrencyref.current}{" "}
                                          {parseFloat(price).toFixed(2)}
                                        </h3>
                                      </div>
                                      <div className="price_content">
                                        <p>Lowest order Price</p>
                                        <h3>
                                          {toCurrencyref.current}{" "}
                                          {parseFloat(
                                            lowpriceref.current
                                          ).toFixed(2)}
                                        </h3>
                                      </div>
                                    </div>
                                    <div className="select_form new_form_selce">
                                      <div className="priceinput">
                                        <input
                                          type="number"
                                          name="price"
                                          value={price}
                                          onChange={handleChange}
                                          min="0"
                                        />
                                        <span>{toCurrencyref.current}</span>
                                      </div>
                                    </div>
                                    {/* <div className="input_increase">
                                  <span onClick={() => set_price('add',price)}>+</span>
                                  <p>{incrementref.current}</p>
                                  <span onClick={() => set_price('sub',price)}>-</span>
                                </div> */}
                                    {/* <div className="select_iocn">
                                    <label>Set your price</label>
                                    <div className="priceinput">
                                      <input type="number" name="price" value={price} onChange={handleChange} min = "0" />
                                      <span>{fromCurrencyref.current}</span>
                                    </div>
                                    <div className="text-danger">
                                      {serpriceErrorref.current==true ? (
                                        <p className="text-danger">
                                          {" "}
                                          {validationnErr1ref.current.price}{" "}
                                        </p>
                                      ) : (
                                        ""
                                      
                                    </div>
                                  </div> */}
                                  </div>
                                  <div className="p2ppost_footer">
                                    {/* <Button className="color_coe_btn">
                                  Previous
                                </Button> */}
                                    <Button onClick={() => step_go("2")}>
                                      Next
                                    </Button>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}

                              {/* step2 */}
                              {step2ref.current ? (
                                <div>
                                  <div className="submit_form_post">
                                    <div className="select_form new_form_selce">
                                      <div className="select_iocn">
                                        <label>Total Trading Amount</label>
                                        <div className="priceinput">
                                          <input
                                            type="number"
                                            name="quantity"
                                            value={quantity}
                                            onChange={handleChange}
                                            min="0"
                                          />
                                          <span>{fromCurrencyref.current}</span>
                                        </div>
                                        <div>
                                          {errquantity == true ? (
                                            <p className="text-danger">
                                              {" "}
                                              {
                                                validationnErr2ref.current
                                                  .quantity
                                              }{" "}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="select_form">
                                      <div className="select_iocn">
                                        <label>Minimum Quantity</label>
                                        <div className="priceinput">
                                          <input
                                            type="number"
                                            name="limitFrom"
                                            value={limitFrom}
                                            onChange={handleChange}
                                            min="0"
                                          />
                                          <span>{fromCurrencyref.current}</span>
                                        </div>
                                        <div>
                                          {errLimitFrom == true ? (
                                            <p className="text-danger">
                                              {" "}
                                              {
                                                validationnErr2ref.current
                                                  .limitFrom
                                              }{" "}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>

                                      <div className="select_iocn clmariduke">
                                        <i class="ri-separator"></i>
                                      </div>
                                      <div className="select_iocn">
                                        <label>Maximum Quantity</label>
                                        <div className="priceinput">
                                          <input
                                            type="number"
                                            name="limitTo"
                                            value={limitTo}
                                            onChange={handleChange}
                                            min="0"
                                          />
                                          <span>{fromCurrencyref.current}</span>
                                        </div>
                                        <div className="text-danger">
                                          {errlimitTo == true ? (
                                            <p className="text-danger">
                                              {" "}
                                              {
                                                validationnErr2ref.current
                                                  .limitTo
                                              }{" "}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="select_form mt-3 new_form_selce">
                                      <div className="select_iocn">
                                        <label>Payment Method</label>
                                        <Dropdown
                                          placeholder="Select Payment method"
                                          fluid
                                          selection
                                          className="data_dropdoen"
                                          options={preferPayment}
                                          onChange={prferPayments}
                                          value={prferPay}
                                        />
                                        {/* <p className="payment_time_">
                                      Payment Time Limit <span>15min</span>
                                    </p> */}
                                      </div>
                                      <div className="text-danger">
                                        {errprferPay == true ? (
                                          <p className="text-danger">
                                            {" "}
                                            {
                                              validationnErr2ref.current
                                                .prferPay
                                            }{" "}
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>

                                    <div className="select_form mt-3 new_form_selce">
                                      <div className="select_iocn">
                                        <label>Payment Time</label>
                                        <Dropdown
                                          placeholder="Select Payment Time"
                                          fluid
                                          selection
                                          className="data_dropdoen"
                                          options={payTime}
                                          onChange={choosePaytime}
                                          value={payment_timeref.current}
                                        />
                                      </div>
                                      <div className="text-danger">
                                        {errpaymentTime == true ? (
                                          <p className="text-danger">
                                            {" "}
                                            {
                                              validationnErr2ref.current
                                                .payment_time
                                            }{" "}
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="p2ppost_footer">
                                    <Button
                                      className="color_coe_btn"
                                      onClick={() => step_go("1")}
                                    >
                                      Previous
                                    </Button>
                                    <Button onClick={() => step_go("3")}>
                                      Next
                                    </Button>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}

                              {/* step3 */}
                              {step3ref.current ? (
                                <div>
                                  <div className="submit_form_post mb-3">
                                    <div className="select_form new_form_selce">
                                      <div className="select_iocn flex_text_are">
                                        <label>Terms(Optional)</label>
                                        <textarea
                                          name="terms"
                                          onChange={handleChange}
                                        >
                                          {terms}
                                        </textarea>
                                      </div>
                                    </div>
                                    <div className="select_form ">
                                      <div className="select_iocn flex_text_are">
                                        <label>Auto Replay(Optional)</label>
                                        <textarea
                                          name="auto_reply"
                                          onChange={handleChange}
                                        >
                                          {auto_reply}
                                        </textarea>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="p2ppost_footer">
                                    <Button
                                      className="color_coe_btn"
                                      onClick={() => step_go("2")}
                                    >
                                      Previous
                                    </Button>
                                    {buttonLoader == false ? (
                                      <Button onClick={() => submitPost("buy")}>
                                        Submit Post
                                      </Button>
                                    ) : (
                                      <Button>Loading...</Button>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div id="menu1" class="tab-pane fade">
                              {step1ref.current ? (
                                <div>
                                  <div className="submit_form_post">
                                    <div className="select_form">
                                      <div className="select_iocn">
                                        <label>Asset</label>
                                        <Dropdown
                                          placeholder="Select Currency"
                                          fluid
                                          selection
                                          className="data_dropdoen"
                                          options={allCurrency}
                                          onChange={chooseCrypto}
                                          value={firstCurrencyref.current}
                                        />
                                        <div className="text-danger">
                                          {errcryptoCurrency == true ? (
                                            <p className="text-danger">
                                              {" "}
                                              {
                                                validationnErr1ref.current
                                                  .cryptoCurrency
                                              }{" "}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                      <div className="select_iocn clmariduke">
                                        <i class="ri-arrow-right-line"></i>
                                      </div>
                                      <div className="select_iocn">
                                        <label>With Fiat</label>
                                        <Dropdown
                                          placeholder="Select Currency"
                                          fluid
                                          selection
                                          className="data_dropdoen"
                                          options={allCurrencyFiat}
                                          onChange={withCash}
                                          value={secondCurrencyref.current}
                                        />
                                        <div className="text-danger">
                                          {errcrytpoWithCash == true ? (
                                            <p className="text-danger">
                                              {" "}
                                              {
                                                validationnErr1ref.current
                                                  .crytpoWithCash
                                              }{" "}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="price_section_pluse">
                                      <div className="price_content">
                                        <p>Your Price</p>
                                        <h3>
                                          {toCurrencyref.current}{" "}
                                          {parseFloat(price).toFixed(2)}
                                        </h3>
                                      </div>
                                      <div className="price_content">
                                        <p>Highest order Price</p>
                                        <h3>
                                          {toCurrencyref.current}{" "}
                                          {parseFloat(
                                            highpriceref.current
                                          ).toFixed(2)}
                                        </h3>
                                      </div>
                                    </div>
                                    <div className="select_form new_form_selce">
                                      <div className="priceinput ">
                                        <input
                                          type="number"
                                          name="price"
                                          value={price}
                                          onChange={handleChange}
                                          min="0"
                                        />
                                        <span>{toCurrencyref.current}</span>
                                      </div>
                                    </div>
                                    {/* <div className="input_increase">
                                  <span onClick={() => set_price('add',price)}>+</span>
                                  <p>{incrementref.current}</p>
                                  <span onClick={() => set_price('sub',price)}>-</span>
                                </div> */}
                                  </div>
                                  <div className="p2ppost_footer">
                                    {/* <Button className="color_coe_btn">
                                  Previous
                                </Button> */}
                                    <Button onClick={() => step_go("2")}>
                                      Next
                                    </Button>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}

                              {/* step2 */}
                              {step2ref.current ? (
                                <div>
                                  <div className="submit_form_post">
                                    <div className="select_form new_form_selce">
                                      <div className="select_iocn">
                                        <label>Total Trading Amount</label>
                                        <div className="priceinput">
                                          <input
                                            type="number"
                                            name="quantity"
                                            value={quantity}
                                            onChange={handleChange}
                                            min="0"
                                          />
                                          <span>{fromCurrencyref.current}</span>
                                        </div>
                                        <div>
                                          {errquantity == true ? (
                                            <p className="text-danger">
                                              {" "}
                                              {
                                                validationnErr2ref.current
                                                  .quantity
                                              }{" "}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="select_form">
                                      <div className="select_iocn">
                                        <label>Minimum Quantity</label>
                                        <div className="priceinput">
                                          <input
                                            type="number"
                                            name="limitFrom"
                                            value={limitFrom}
                                            onChange={handleChange}
                                            min="0"
                                          />
                                          <span>{fromCurrencyref.current}</span>
                                        </div>
                                        <div>
                                          {errLimitFrom == true ? (
                                            <p className="text-danger">
                                              {" "}
                                              {
                                                validationnErr2ref.current
                                                  .limitFrom
                                              }{" "}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>

                                      <div className="select_iocn clmariduke">
                                        <i class="ri-separator"></i>
                                      </div>
                                      <div className="select_iocn">
                                        <label>Maximum Quantity</label>
                                        <div className="priceinput">
                                          <input
                                            type="number"
                                            name="limitTo"
                                            value={limitTo}
                                            onChange={handleChange}
                                            min="0"
                                          />
                                          <span>{fromCurrencyref.current}</span>
                                        </div>
                                        <div className="text-danger">
                                          {errlimitTo == true ? (
                                            <p className="text-danger">
                                              {" "}
                                              {
                                                validationnErr2ref.current
                                                  .limitTo
                                              }{" "}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="select_form mt-3 new_form_selce">
                                      <div className="select_iocn">
                                        <label>Payment Method</label>
                                        <Dropdown
                                          placeholder="Select Payment method"
                                          fluid
                                          selection
                                          className="data_dropdoen"
                                          options={preferPayment}
                                          onChange={prferPayments}
                                          value={prferPay}
                                        />
                                        {/* <p className="payment_time_">
                                      Payment Time Limit <span>15min</span>
                                    </p> */}
                                      </div>
                                      <div className="text-danger">
                                        {errprferPay == true ? (
                                          <p className="text-danger">
                                            {" "}
                                            {
                                              validationnErr2ref.current
                                                .prferPay
                                            }{" "}
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>

                                    <div className="select_form mt-3 new_form_selce">
                                      <div className="select_iocn">
                                        <label>Payment Time</label>
                                        <Dropdown
                                          placeholder="Select Payment Time"
                                          fluid
                                          selection
                                          className="data_dropdoen"
                                          options={payTime}
                                          onChange={choosePaytime}
                                          value={payment_timeref.current}
                                        />
                                      </div>
                                      <div className="text-danger">
                                        {errpaymentTime == true ? (
                                          <p className="text-danger">
                                            {" "}
                                            {
                                              validationnErr2ref.current
                                                .payment_time
                                            }{" "}
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="p2ppost_footer">
                                    <Button
                                      className="color_coe_btn"
                                      onClick={() => step_go("1")}
                                    >
                                      Previous
                                    </Button>
                                    <Button onClick={() => step_go("3")}>
                                      Next
                                    </Button>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}

                              {/* step3 */}
                              {step3ref.current ? (
                                <div>
                                  <div className="submit_form_post mb-3">
                                    <div className="select_form new_form_selce">
                                      <div className="select_iocn flex_text_are">
                                        <label>Terms(Optional)</label>
                                        <textarea
                                          name="terms"
                                          onChange={handleChange}
                                        >
                                          {terms}
                                        </textarea>
                                      </div>
                                    </div>
                                    <div className="select_form ">
                                      <div className="select_iocn flex_text_are">
                                        <label>Auto Replay(Optional)</label>
                                        <textarea
                                          name="auto_reply"
                                          onChange={handleChange}
                                        >
                                          {auto_reply}
                                        </textarea>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="p2ppost_footer">
                                    <Button
                                      className="color_coe_btn"
                                      onClick={() => step_go("2")}
                                    >
                                      Previous
                                    </Button>
                                    {buttonLoader == false ? (
                                      <Button
                                        onClick={() => submitPost("sell")}
                                      >
                                        Submit Post
                                      </Button>
                                    ) : (
                                      <Button>Loading...</Button>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </>
        )}
      </main>
      {loader == true ? (
        <div class="spinner css-ezm1fk">
          <div class="css-1qoumk2"></div>
          <div class="css-1v19680"></div>
          <div class="css-1v90jie"></div>
          <div class="css-19p0rvp"></div>
        </div>
      ) : (
        <Footer />
      )}
    </div>
  );
}

export default Home;
